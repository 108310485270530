import RightsTree from "../manage/RightsTree";
import React, {useEffect, useState} from "react";
import api from "../api";
import {useFilters} from "../helpers/useFilters";
import {Button, Checkbox, Divider, Space} from "antd";
import {ClockCircleTwoTone, FileTextOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default ({})=>{
    const {t} = useTranslation();
    const [rightsPackages, setRightsPackages] = useState([]);

    const [refreshes, setRefreshes] = useState(0)
    const refresh = () => setRefreshes(refreshes + 1)

    useEffect(()=>{
        api('/api/rights_packages?tree=true').then((res)=>{
            setRightsPackages(res.data)
        })
    },[refreshes])

    const {filters, setFilters} = useFilters()
    const onChangeLegal = type => {
        return e => {
            const applied = e.target.checked
            const attrs = {}

            attrs[type] = applied
            setFilters(!applied, attrs)
        }
    }

    const onChangeExpired = e => {
        const applied = e.target.checked
        setFilters(!applied, {expired: applied})
    }

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            <RightsTree
                search
                opts={rightsPackages}
                refresh={refresh}
            />
            <Divider/>
            <Button type={filters.contracts ? 'primary' : ''} ghost={filters.contracts}>
                <Checkbox onChange={onChangeLegal('contracts')} checked={filters.contracts}>
                    <FileTextOutlined/> {t('view-contracts-only','View Contracts Only')}?
                </Checkbox>
            </Button>

            <Button type={filters.releases ? 'primary' : ''} ghost={!!filters.releases}>
                <Checkbox onChange={onChangeLegal('releases')} checked={filters.releases}>
                    <FileTextOutlined/> {t('view-releases-only','View Releases Only')}?
                </Checkbox>
            </Button>

            <Button type={filters.expired ? 'danger' : ''} ghost={!!filters.expired}>
                <Checkbox onChange={onChangeExpired} checked={filters.expired}>
                    <ClockCircleTwoTone twoToneColor={'red'}/> {t('view-expired-assets-only','View Expired Assets Only')}?
                </Checkbox>
            </Button>
        </Space>
    )
}