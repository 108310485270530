import {createContext, useContext, useState} from "react";
import {HotKeys} from "react-hotkeys";
import {useCurrentAssetCursor} from "@/components/helpers/useSetCurrentAsset";
import {useCurrentAssetsState} from "@/contexts/CurrentAssetContext";

const BulkContext = createContext();

const AssetActionsProvider = ({children})=>{
    const useWrappedState = ()=>{
        const [object, setObject] = useState()

        const setObjectAndResetFocus = value =>{
            setObject(value)
        }

        return [object, setObjectAndResetFocus]
    }

    const [downloadModalOpen, setDownloadModalOpen] = useWrappedState()
    const [downloadAsset, setDownloadAsset] = useState()
    const [shareModalOpen, setShareModalOpen] = useWrappedState()

    const [editModalOpen, setEditModalOpen] = useWrappedState()
    const [rightsModalOpen, setRightsModalOpen] = useWrappedState()
    const [releasesModalOpen, setReleasesModalOpen] = useWrappedState()

    const [destroyModalOpen, setDestroyModalOpen] = useWrappedState()
    const [restoreModalOpen, setRestoreModalOpen] = useWrappedState()

    const [addToLightboxModalOpen, setAddToLightboxModalOpen] = useWrappedState()
    const [addToCollectionModalOpen, setAddToCollectionModalOpen] = useWrappedState()

    const [accessRequestModalOpen, setAccessRequestModalOpen] = useWrappedState()
    const [requestAssetIds, setRequestAssetIds] = useState()

    const keyMap = {
        CMD_E: 'command+e',
        CMD_SHIFT_S: 'command+shift+s',
        CMD_D: 'command+backspace',
        CMD_OPT_S: 'command+option+s',
    }

    const handlers = {
        CMD_E: ()=> { setEditModalOpen(true) },
        CMD_SHIFT_S: (e)=> {
            e.preventDefault()
            setDownloadModalOpen(true)
        },
        CMD_D: e=> {
            e.preventDefault()
            setDestroyModalOpen(true)
        },
        CMD_OPT_S: ()=> { setShareModalOpen(true) },
    }

    return (
        <BulkContext.Provider
            value={{
                downloadModalOpen, setDownloadModalOpen,
                downloadAsset, setDownloadAsset,

                accessRequestModalOpen, setAccessRequestModalOpen,
                requestAssetIds, setRequestAssetIds,

                editModalOpen, setEditModalOpen,
                destroyModalOpen, setDestroyModalOpen,
                shareModalOpen, setShareModalOpen,
                restoreModalOpen, setRestoreModalOpen,
                rightsModalOpen, setRightsModalOpen,
                releasesModalOpen, setReleasesModalOpen,
                addToLightboxModalOpen, setAddToLightboxModalOpen,
                addToCollectionModalOpen, setAddToCollectionModalOpen
            }}
        >
            <HotKeys keyMap={keyMap} handlers={handlers}>
                {children}
            </HotKeys>
        </BulkContext.Provider>
    )
}

export default AssetActionsProvider

const useAssetActions = ()=> {
    return useContext(BulkContext)
}

export {useAssetActions}