import React, {useState, useEffect} from "react";
import {Tooltip, Avatar, Popover, Descriptions, Typography, List, Button, Tag} from "antd";
import TimeAgo from "./TimeAgo";
import {UserGroupIcon} from "./icons";
import {UserSwitchOutlined} from "@ant-design/icons";
import useSwitchToUser from "./useSwitchToUser";
import {useTranslation} from "react-i18next";

const User = ({user, showEmail, iconOnly, children, defaultVisible, style, size, noPopover}) => {
    const {t} = useTranslation();

    const [visible, setVisible] = useState()
    const [switching, setSwitching] = useState()

    const [details, setDetails] = useState({})

    const switchToUser = useSwitchToUser()

    useEffect(()=> {
        if(user?.detailsLoaded || !visible) return

        api(`/api/memberships/find?user_id=${user.user_id}`).then(res => setDetails({...res.data, detailsLoaded: true}))
    }, [visible])

    const clickSwitch = ()=> {
        setSwitching(true)
        switchToUser(user)
    }

    if(!user) return <>{children}</>;

    const extraProps = {}
    if(noPopover) extraProps.visible = false

    const email = user.guest ? user.guest_email || user.email : user.email;

    user = {...user, ...details}

    return (
        <Popover
            {...extraProps}
            placement='right'
            title={
                <>
                    <strong><Avatar src={user.avatar_url} style={{marginRight:'0.5em'}}>{user.initials}</Avatar> {user.name}</strong>
                    {user.switchable && (
                        <div style={{float:'right', verticalAlign:'center'}}>
                            &nbsp;<Button size={'small'} icon={<UserSwitchOutlined/>} onClick={clickSwitch} className={`switch-to-${user.id}`} loading={switching}>{t('button-switch-to','Switch To')}</Button>
                        </div>
                    )}
                </>
            }
            overlayStyle={{zIndex:9002}}
            defaultVisible={defaultVisible}
            onOpenChange={setVisible}
            content={()=> {
                return (
                    <Descriptions bordered size={'small'} column={1}>
                        <Descriptions.Item label={'Email'}>
                            <a href={`mailto:${email}`}>{email}</a>
                        </Descriptions.Item>

                        {user.username && (
                            <Descriptions.Item label={t('username','Username')}>
                                <Typography.Text copyable={{text: `@${user.username}`}}>@{user.username}</Typography.Text>
                            </Descriptions.Item>
                        )}
                        {user.title && (
                            <Descriptions.Item label={t('title','Title')}>
                                {user.title}
                            </Descriptions.Item>
                        )}
                        
                        {user.role_level_name && (
                            <Descriptions.Item label={t('role','Role')}>
                                {user.guest && <>{t('guest','Guest')}</>}
                                {t(user.role_level_name,user.role_level_name)} {user.is_owner && <><br/><small>({t('account-owner','Account Owner')})</small></>}
                                {user.tester && <><br/><small>({t('test-user','Test User')})</small></>}
                            </Descriptions.Item>
                        )}

                        {!!user.user_groups?.length && (
                            <Descriptions.Item label={t('groups','Groups')}>
                                {/*Member of {user.user_group_ids?.length} group{user.user_group_ids?.length == 1 ? '' : 's'}.*/}
                                <List
                                    size={'small'}
                                >
                                    {user.user_groups?.map((group,i) => (
                                        <List.Item key={i}>
                                            <UserGroupIcon/> {group.name}
                                        </List.Item>
                                    ))}
                                </List>
                            </Descriptions.Item>
                        )}

                        <Descriptions.Item label={t('joined','Joined')}>
                            <TimeAgo date={user.created_at}/>
                        </Descriptions.Item>
                    </Descriptions>
                )
            }}
        >
            {children || (
                <span style={style || {}} className={user.avatar_url ? 'user-with-avatar' : 'user-without-avatar'}>
                    <Avatar src={user.avatar_url} size={size || (!iconOnly && 'small')}>{user.initials}</Avatar> <span style={{textDecorationStyle:'dotted'}}>{!iconOnly && user.name}</span> {showEmail ? <small>{email}</small> : ''}
                    {user.guest && <Tag>Guest</Tag>}
                </span>
            )}
        </Popover>
    )
}

const UserMention = ({username}) => {
    const [notFound, setNotFound] = useState()
    const [user, setUser] = useState()

    const onMouseOver = ()=>{
        api(`/api/memberships/find`, {params:{username}}).then(res => {
            setUser(res.data.user)
        }).catch(()=>{
            setNotFound(true)
        })
    }

    const inner = <a onMouseOver={onMouseOver}>@{username}</a>

    if(user) return <User user={user} defaultVisible>{inner}</User>
    if(notFound) return <Tooltip title={'User not found.'} defaultVisible>{inner}</Tooltip>

    return inner
}

export {UserMention};

export default User;
