import React, {useState, useEffect, useRef} from "react";
import pluralize from 'pluralize'
import {
    Popconfirm,
    Modal,
    Button,
    message,
    Space,
    Tooltip,
} from "antd";
import {Formik} from "formik";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import api from "../api";
import {Checkbox, Form, Rate, FormItem, Select, Input, Radio} from "formik-antd";

import {
    CheckOutlined,
    FormOutlined,
    MacCommandFilled
} from "@ant-design/icons";

import {
    isBrowser,
    isMobile
} from "device-detect";
import useCurrentOrg from "../helpers/useCurrentOrg";
import FloatLabel from "../helpers/FloatLabel";
import {useNavigate} from "react-router-dom-v5-compat";
import {useOrgPath} from "../helpers/OrgNavLink";
import {useAssetGroupDispatch} from "../../contexts/AssetGroupContext";
import useCurrentUser from "../helpers/useCurrentUser";
import useGuestLogin from "../helpers/useGuestLogin";
import {useTranslation} from "react-i18next";
import {useAssetActions} from "@/contexts/AssetActionsContext";

export default ({block, asset, size='small'})=> {
    const {t} = useTranslation();

    const {
        setRequestModalOpen: setVisible,
        setRequestAssetIds
    } = useAssetActions()

    const onClick = e => {
        e.stopPropagation()
        if(asset) setRequestAssetIds([asset.id])
        setVisible(true)
    }

    const currentOrg = useCurrentOrg()

    if(
        !currentOrg?.enable_access_requests ||
        ['admin', 'global_content', 'global_library'].includes(currentOrg?.role_level)
        || asset?.original_downloadable
    )
        return <></>

    return (
        <Tooltip
            title={
                asset ?
                    t('tooltip-request-access-button-single','This file is restricted download. Use this request to ask for download access.') :
                    t('tooltip-request-access-button','One or more files you have selected have a restricted download. Use this request to ask for download access.')
            }
        >
            <Button
                size={size}
                icon={<FormOutlined />}
                onClick={onClick}
                type={block ? 'text' : 'primary'}
                block={block}
                id={'access-request-btn'}
                style={{textAlign:'left'}}
            >
                {isBrowser() && t('button-request-access','Request Access')}
            </Button>
        </Tooltip>
    )
}

const AccessRequestModal = ()=> {
    const {t} = useTranslation();
    const {
        accessRequestModalOpen: visible,
        setAccessRequestModalOpen: setVisible,
        setDownloadModalOpen,
        downloadAsset: asset,
        requestAssetIds: ids
    } = useAssetActions()

    const {selectedAssetIds} = useSelectedAssetsState();
    const assetIds = asset ? [asset.id] : ids || selectedAssetIds

    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const autoFocusInput = useRef(null);

    const afterOpenChange = (open)=> {
        autoFocusInput.current.focus();
    }

    const navigate = useNavigate();
    const getPath = useOrgPath()

    const currentUser = useCurrentUser()
    const guestLogin = useGuestLogin()

    return (
        (<Formik
            initialValues={{}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                values.asset_ids = assetIds;

                const createAccessRequest = ()=>{
                    api.post( '/api/access_requests', {access_request: values}).then(res => {

                        actions.resetForm();
                        actions.setSubmitting(false);

                        // TODO: background job while Request is building
                        // TODO: redirect to newly creating AccessRequest
                        setVisible(false)
                        setDownloadModalOpen(false)
                        selectedAssetDispatch({type: 'selectNone'})

                        navigate(getPath(`/access-requests/${res.data.slug}`))

                    }).catch((err)=>{
                        console.log(err)
                        actions.setSubmitting(false);
                    })
                }

                if(!currentUser) {
                    guestLogin(()=>  createAccessRequest())
                } else {
                    createAccessRequest()
                }

            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                const titleText = 
                    assetIds.length === 1 ?
                        t('request-access-for-asset', 'Request Access For 1 Asset') : 
                        t('request-access-for-assets', 'Request Access For {{count}} Assets', {count: assetIds.length})
                
                return (
                    (<Modal
                        zIndex={2001}
                        onCancel={()=> setVisible(false)}
                        open={visible}
                        title={<><FormOutlined/> {titleText}</>}
                        afterOpenChange={afterOpenChange}
                        footer={
                            <Space>
                                <Button
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                    icon={<CheckOutlined/>}
                                >
                                    {t('button-start-request','Start Request')}...
                                </Button>
                            </Space>
                        }
                    >
                        <Form onSubmit={handleSubmit} layout="vertical">
                            {!currentUser && (
                                <>
                                    <FormItem required showValidateSuccess name='requester_name'>
                                        <FloatLabel label={t('your-name','Your Name')} name={'requester_name'} value={values.requester_name} description={''}>
                                            <Input size={'large'} required name='requester_name' ref={autoFocusInput} autoComplete='off'/>
                                        </FloatLabel>
                                    </FormItem>

                                    <FormItem required showValidateSuccess name='requester_email'>
                                        <FloatLabel label={t('your-email','Your Email')} name={'requester_email'} value={values.requester_email} description={''}>
                                            <Input type='email' size={'large'} required name='requester_email' ref={autoFocusInput} autoComplete='off'/>
                                        </FloatLabel>
                                    </FormItem>
                                </>
                            )}

                            <FormItem required showValidateSuccess name='name'>
                                <FloatLabel label={t('name-of-the-request','Name of The Request')} name={'name'} value={values.name} description={t('placeholder-name-of-the-request','e.g. Marketing Brochure Imagery')}>
                                    <Input size={'large'} required name='name' ref={autoFocusInput} autoComplete='off'/>
                                </FloatLabel>
                            </FormItem>
                        </Form>
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

export {AccessRequestModal}