import React, {useState, useEffect, cloneElement, useRef, useContext} from "react";
import pluralize from 'pluralize'
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import {
    Popconfirm,
    Modal,
    Button,
    message,
    Space,
    Tooltip,
    Divider,
    Progress,
    Typography,
    Descriptions, Menu, Dropdown, Badge, Flex, Tag
} from "antd";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import {FieldArray, Formik, useFormikContext} from "formik";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import api from "../api";
import {Checkbox, Form, Rate, FormItem, Select, Input, DatePicker} from "formik-antd";
import TagSelect from "../widgets/TagSelect";
import {useAssetGroupState} from "../../contexts/AssetGroupContext";
import {
    BlockDownload, BlockView,
    CollectionIcon,
    CustomMetaIcon,
    FrameIcon, FrameStatusIcon, LightboxIcon,
    RefreshIcon,
    RightsIcon,
    ShareIcon,
    ThumbsDownIcon,
    ThumbsUpIcon
} from "../helpers/icons";
import {useBulkJobsDispatch, useBulkJobsState} from "../../contexts/BulkJobsContext";
import {useLoadedAssetsDispatch} from "../../contexts/LoadedAssetsContext";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import BulkJobProgress from "./BulkJobProgress";
import {
    AlertOutlined, ArrowLeftOutlined, ArrowRightOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    CloudDownloadOutlined,
    CloudServerOutlined,
    EyeInvisibleOutlined, EyeOutlined, FileAddOutlined,
    FileProtectOutlined,
    FileTextOutlined, FlagOutlined,
    MacCommandFilled,
    MacCommandOutlined,
    MinusOutlined,
    PlusOutlined,
    QuestionOutlined,
    ReconciliationOutlined, RobotOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    SyncOutlined,
    UserOutlined,
} from "@ant-design/icons";
import HelpPopover from "../HelpPopover";
import {AbilityContext, Can} from "../helpers/Can";
import RightsPackage, {RightsPackageSelect} from "../widgets/RightsPackage";
import {useAbility} from "@casl/react";
import {Checkbox as FormikCheckbox} from "formik-antd/lib/checkbox";
import EventTagSelect from "../widgets/EventTagSelect";
import {EditGpsButton} from "../widgets/AssetModal";
import {isMobile} from "../../plugins/device-detect";
import VerticalSpace from "../helpers/VerticalSpace";
import useCurrentOrg from "../helpers/useCurrentOrg";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";
import {useAssetsDispatch, useAssetsState} from "@/contexts/AssetsContext";
import {useAssetActions} from "~/contexts/AssetActionsContext";
import AddToAssetGroupModal from "~/components/widgets/AddToAssetGroupModal";
import {useAggsDispatch} from "~/contexts/AggsContext";
import rights from "@/components/manage/Rights";
import {useViewMode} from "@/components/explore/ViewMenu";

const BulkEditMetaButton = ()=>{
    // TODO: add to Menu Item logic:
    const {t} = useTranslation();
    const ability = useAbility(AbilityContext);
    const {currentUpload, currentAssetGroup} = useAssetGroupState()
    const currentUser = useCurrentUser()

    const editableAssetGroup = currentAssetGroup?.type === 'StorageFolder' && currentUser?.user_group_ids?.indexOf(currentAssetGroup?.user_group_id) !== -1;

    const {selectedAssetIds} = useSelectedAssetsState();
    const {assets} = useAssetsState()
    let anyEditable = false

    for(const id of selectedAssetIds) {
        const asset = assets[id]
        if(asset?.editable) {
            anyEditable = true
            break
        }
    }

    if(!editableAssetGroup && !ability.can('view_bulk_edit', 'Organization') && !currentUpload && !anyEditable) return <></>;

    return (
        <BulkEditButton
            tooltip={<><MacCommandFilled/> E</>}
            ModalComponent={BulkEditMetaModal}
        >
            <EditOutlined/> {t('button-edit-metadata','Edit Metadata')}...
        </BulkEditButton>
    )
}

const BulkEditMetaModal = ()=> {
    const {t} = useTranslation();

    const {editModalOpen: visible, setEditModalOpen: setVisible} = useAssetActions();

    const BulkEditMetaForm = ()=> {
        const currentOrg = useCurrentOrg()
        const {currentUpload} = useAssetGroupState()
        const { values, setFieldValue } = useFormikContext();

        const [creatorTagsLoading, setCreatorTagsLoading] = useState(false)
        const [creatorTags, setCreatorTags] = useState([])
        const [newCreatorTag, setNewCreatorTag] = useState()

        const creatorTagOptions = creatorTags.map(c => <Select.Option key={c.name}>{c.name}</Select.Option>);

        if(newCreatorTag && !_.find(creatorTags, {name: newCreatorTag})) {
            creatorTagOptions.unshift(
                <Select.Option key={newCreatorTag}>{newCreatorTag}</Select.Option>
            )
        }

        const onCreatorSearch = (val)=> {
            setCreatorTagsLoading(true)
            setNewCreatorTag(val)

            api(`/api/creator_tags?q=${val}`).then(res => {
                setCreatorTagsLoading(false)
                setCreatorTags(res.data)
            })
        }

        const [fieldsLoading, setFieldsLoading] = useState(true)
        const [customMetaFields, setCustomMetaFields] = useState([])

        useEffect(()=>{
            if(!currentOrg?.has_custom_meta_fields) return;

            setFieldsLoading(true)
            api(`/api/custom_meta_fields`).then(res => {
                setFieldsLoading(false)
                setCustomMetaFields(res.data)
            })
        }, [currentOrg?.id])

        return (
            <>
                <FormItem name={'tag_names'} label={<>{t('add-or-remove-keywords','Add or Remove Keywords')} <HelpPopover code={'bulk-edit-keywords'}/></>}>
                    <TagSelect bulk bordered upload={currentUpload} placeholder={t('bulk-keywords-placeholder','Type keywords here...')}/>
                </FormItem>

                <FormItem name={'tag_mode'}>
                    <Select name={'tag_mode'} style={{width:200}}>
                        <Select.Option value={'add'}><PlusOutlined/> {t('add','Add')}</Select.Option>
                        <Select.Option value={'remove'}><MinusOutlined/> {t('remove','Remove')}</Select.Option>
                        {/*<Select.Option value={'replace'}><RefreshIcon/> Replace</Select.Option>*/}
                    </Select>
                </FormItem>

                <Divider/>

                <FieldWithMode f={'description'}/>

                <Divider/>

                <FormItem name={'link_urls'} label={<>{t('add-or-remove-links','Add or Remove Links')} <HelpPopover code={'bulk-edit-links'}/></>}>
                    <Select name={'link_urls'} mode={'tags'} placeholder={t('add-links-placeholder','Type URLs here...')}/>
                </FormItem>

                <FormItem name={'link_mode'}>
                    <Select name={'link_mode'} style={{width:200}}>
                        <Select.Option value={'add'}><PlusOutlined/> {t('add','Add')}</Select.Option>
                        <Select.Option value={'remove'}><MinusOutlined/> {t('remove','Remove')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <FormItem name={'creator_tag_name'} label={<>{t('add-or-remove-creator','Add or Remove Creator')} <HelpPopover code={'bulk-edit-creator'}/></>}>
                    {/*<FormikCheckbox name={'set_creator_as_me'}>Me</FormikCheckbox>*/}
                    <Select
                        style={{marginTop:'.5em'}}
                        // disabled={values.set_creator_as_me}
                        name={'creator_tag_name'}
                        showSearch
                        loading={creatorTagsLoading}
                        defaultActiveFirstOption={false}
                        placeholder={t('creator-tag-placeholder','Search or add new...')}
                        filterOption={false}
                        onSearch={onCreatorSearch}
                        notFoundContent={null}
                        allowClear
                    >
                        {creatorTagOptions}
                    </Select>
                </FormItem>

                <FormItem name={'creator_tag_name_mode'}>
                    <Select name={'creator_tag_name_mode'} style={{width:200}}>
                        <Select.Option value={'add'}><PlusOutlined/> {t('add','Add')}</Select.Option>
                        <Select.Option value={'blank'}><QuestionOutlined/> {t('only-add-if-blank','Only add if blank')}</Select.Option>
                        <Select.Option value={'remove'}><MinusOutlined/> {t('remove','Remove')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <FieldWithMode f={'credit_line'}/>

                <Divider/>

                <FieldWithMode f={'iptc_rights'}/>

                <Divider/>

                <FormItem name={'rating'} label={<>{t('set-rating','Set Rating')} <HelpPopover code={'bulk-edit-rating'}/></>}>
                    <Rate style={{fontSize:20}} name={'rating'} allowClear/>
                </FormItem>

                <Divider/>

                <FormItem name={'gps'} label={<>{t('add-modify-gps','Add, Modify or Remove GPS')} <HelpPopover code={'bulk-edit-gps'}/></>}>
                    <EditGpsButton
                        text={t('edit-gps-placeholder','Choose on Map')}
                        onChange={center => setFieldValue('latitude', center?.lat) && setFieldValue('longitude', center?.lng)}
                        style={{marginBottom:'1em'}}
                    />

                    <FormItem name={'latitude'}>
                        <Input name={'latitude'} placeholder={t('latitude','Latitude')}/>
                    </FormItem>

                    <FormItem name={'longitude'}>
                        <Input name={'longitude'} placeholder={t('longitude','Longitude')}/>
                    </FormItem>

                    {/*<FormikCheckbox name={'remove_gps'}>*/}
                    {/*    <Typography.Text type={'danger'}>Remove GPS</Typography.Text>*/}
                    {/*</FormikCheckbox>*/}
                </FormItem>

                <FormItem name={'gps_mode'}>
                    <Select name={'gps_mode'} style={{width:200}}>
                        <Select.Option value={'blank'}>{t('only-add-if-blank','Only add if blank')}</Select.Option>
                        <Select.Option value={'overwrite'}>{t('overwrite','Overwrite')}</Select.Option>
                        <Select.Option value={'remove'}>{t('remove','Remove')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <FieldWithMode f={'country'} textField/>
                <FieldWithMode f={'country_code'} textField/>
                <FieldWithMode f={'state'} textField/>
                <FieldWithMode f={'city'} textField/>
                <FieldWithMode f={'sublocation'} textField/>

                <Divider/>

                <FormItem name={'event_tag_name'} label={<>{t('add-or-remove-event','Add or Remove Event')} <HelpPopover code={'bulk-edit-event'}/></>}>
                    <EventTagSelect/>
                </FormItem>

                <FormItem name={'event_tag_name_mode'}>
                    <Select name={'event_tag_name_mode'} style={{width:200}}>
                        <Select.Option value={'overwrite'}>{t('overwrite','Overwrite')}</Select.Option>
                        <Select.Option value={'blank'}>{t('only-add-if-blank','Only add if blank')}</Select.Option>
                        <Select.Option value={'remove'}>{t('remove','Remove')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <FormItem name={'has_people'} label={<>{t('edit-has-people-tag','Edit "Has People" tag')} <HelpPopover code={'bulk-edit-has-people'}/></>}>
                    <Select name={'has_people'} placeholder allowClear>
                        <Select.Option value={'yes'}><CheckOutlined/> {t('has','Has')}</Select.Option>
                        <Select.Option value={'no'}><CloseOutlined/> {t('does-not','Does Not')}</Select.Option>
                        <Select.Option value={'untagged'}><QuestionOutlined/> {t('untagged','Untagged')}</Select.Option>
                    </Select>
                </FormItem>

                <FormItem name={'has_people_mode'}>
                    <Select name={'has_people_mode'} style={{width:200}}>
                        <Select.Option value={'overwrite'}>{t('overwrite','Overwrite')}</Select.Option>
                        <Select.Option value={'blank'}>{t('only-add-if-blank','Only add if blank')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <VerticalSpace>
                    <FormikCheckbox name={'remove_iptc_captured_at'}>
                        <Typography.Text type={'danger'}>{t('remove-iptc-date-created', 'Remove IPTC Date Created')}</Typography.Text>
                        <HelpPopover code={'remove-iptc-captured-at'}/>
                    </FormikCheckbox>

                    <FormikCheckbox name={'remove_captured_at'}>
                        <Typography.Text type={'danger'}>{t('set-missing-create-date-quoted', 'Set as "Missing Create Date"')}</Typography.Text>
                        <HelpPopover code={'remove-captured-at'}/>
                    </FormikCheckbox>

                    <FormItem name={'captured_at'} label={t('date-created','Date Created')} style={{marginTop:'1em'}}>
                        <Space>
                            <Select name={'captured_at_truncation'} style={{width:200}} placeholder={t('truncation-level', 'Truncation Level')} disabled={values.remove_captured_at || values.remove_iptc_captured_at}>
                                <Select.Option value={null}></Select.Option>
                                <Select.Option value={'none'}>{t('none','None')}</Select.Option>
                                <Select.Option value={'day'}>{t('day','Day')}</Select.Option>
                                <Select.Option value={'month'}>{t('month','Month')}</Select.Option>
                                <Select.Option value={'year'}>{t('year','Year')}</Select.Option>
                            </Select>

                            <DatePicker
                                showTime={!values.captured_at_truncation}
                                name={'captured_at'}
                                picker={values.captured_at_truncation}
                                disabled={values.remove_captured_at || values.remove_iptc_captured_at}
                            />
                        </Space>
                    </FormItem>
                </VerticalSpace>

                {currentOrg?.has_custom_meta_fields && (
                    <>
                        <Divider><CustomMetaIcon/> {t('custom-meta','Custom Meta')}:</Divider>

                        <FieldArray
                            name={'remove_custom_meta_field_ids'}
                            render={arrayHelpers => (
                                <Descriptions bordered size='small' column={1}>
                                    {customMetaFields.map(cmf => {
                                        const toRemove = values.remove_custom_meta_field_ids?.includes(cmf.id)
                                        return (
                                            <Descriptions.Item
                                                label={<>{cmf.name}<br/>{cmf.description && <small><em>{cmf.description}</em></small>}</>}
                                                key={cmf.name}
                                                style={{width: '33%'}}
                                            >
                                                {cmf.free ? (
                                                    <>
                                                        {(cmf.value_type === 'text' || cmf.value_type === 'number' || cmf.value_type === 'price') && (
                                                            <Input.TextArea name={`custom_meta[${cmf.name}]`} disabled={toRemove}></Input.TextArea>
                                                        )}

                                                        {(cmf.value_type === 'date' || cmf.value_type === 'datetime') && (
                                                            <DatePicker name={`custom_meta[${cmf.name}]`} showTime={cmf.value_type === 'datetime'} disabled={toRemove}/>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Select
                                                        name={`custom_meta[${cmf.name}]`}
                                                        mode={cmf.multi && 'multiple'}
                                                        placeholder={t(`choose-${cmf.multi ? 'multiple' : 'one'}`, `Choose ${cmf.multi ? 'Multiple...' : 'One...'}`)}
                                                        style={{width: '100%'}}
                                                        allowClear
                                                        disabled={toRemove}
                                                    >
                                                        {cmf.custom_meta_values_attributes.map(cmv => (
                                                            <Select.Option key={cmv.id} value={cmv.text}>
                                                                <Flex gap={6}>
                                                                    {cmv.color_label && <Badge color={cmv.color_label}/>}
                                                                    {cmv.text}
                                                                </Flex>
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}

                                                <Checkbox
                                                    style={{marginTop:'.5em', float:'right'}}
                                                    name={`remove_custom_meta_field_ids_${cmf.id}`}
                                                    value={cmf.id}
                                                    checked={toRemove}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            arrayHelpers.push(cmf.id);
                                                        } else {
                                                            const idx = values.remove_custom_meta_field_ids.indexOf(cmf.if);
                                                            arrayHelpers.remove(idx);
                                                        }
                                                    }}
                                                >
                                                    Remove?
                                                </Checkbox>

                                            </Descriptions.Item>
                                        );
                                    })}
                                </Descriptions>
                            )}
                        />
                    </>
                )}
            </>
        )
    }

    const initialValues = {
        tag_mode: 'add',
        link_mode: 'add',
        description_mode: 'append',
        gps_mode: 'blank',
        has_people_mode: 'overwrite',
        event_tag_name_mode: 'overwrite',
        creator_tag_name_mode: 'add',
        remove_custom_meta_field_ids: []
    }

    window.BulkEditFields.map(f => {
        initialValues[`${f}_mode`] = 'overwrite'
    })

    return (
        <BulkEditModal
            visible={visible}
            setVisible={setVisible}
            initialValues={initialValues}
        >
            <BulkEditMetaForm/>
        </BulkEditModal>
    )
}

export {BulkEditMetaModal}

const BulkEditRightsModal = ()=>{
    const {t} = useTranslation();

    const {rightsModalOpen: visible, setRightsModalOpen: setVisible} = useAssetActions();

    const BulkEditRightsForm = ()=>{
        const {currentUpload} = useAssetGroupState()
        const { values, setFieldValue } = useFormikContext();

        const [rightsPackages, setRightsPackages] = useState([]);

        useEffect(()=>{
            if(!visible) return;

            if(currentUpload) {
                setRightsPackages(currentUpload.contribution.rights_packages)
            } else {
                api('/api/rights_packages?enabled[]=true&per_page=1000').then(res => setRightsPackages(res.data))
            }

        }, [visible, currentUpload?.id])

        // Load contract assets:
        const [contractAssets, setContractAssets] = useState()

        // TODO: group by Upload, search by name?
        const contractAssetOptions = contractAssets?.map(asset =>
            <Select.Option key={asset.id} value={asset.id}><Tooltip title={asset.description}><FileTextOutlined/> {asset.filename}</Tooltip></Select.Option>
        )

        useEffect(()=>{
            if(!visible) return;

            api(`/api/assets/contracts?upload_id=${currentUpload?.id || ''}`).then(res => {
                setContractAssets(res.data)
            })
        }, [visible])

        const onSearchContracts = (q) => {
            api(`/api/assets/contracts`, {params: {q}}).then(res => {
                setContractAssets(res.data)
            })
        }

        return (
            <>
                <FormItem name={'rights_package_id'} label={<>{t('rights-package','Rights Package')}: <HelpPopover code={'bulk-edit-rights-package'}/></>}>
                    {values.remove_rights_package ? (
                        <Select name={''} disabled />
                    ) : (
                        <RightsPackageSelect
                            bulk
                            options={rightsPackages}
                            onChange={value => setFieldValue('rights_package_id', value)}
                            popoverPlacement={'bottom'}
                        />
                    )}
                </FormItem>

                <Checkbox name={'remove_rights_package'}>
                    <Typography.Text type={'danger'}>{t('remove-existing-rights-package','Remove existing Rights Package')}</Typography.Text>
                </Checkbox>

                <Divider/>

                <FormItem label={<>{t('rights-status','Rights Status')} <HelpPopover code={'rights-form-status'}/></>} name='rights_class'
                          style={{marginTop: '.5em'}}>
                    <Select
                        allowClear
                        name={'rights_status'}
                        placeholder={t('select-one','Select One...')}
                        disabled={values.rights_package_id}
                    >
                        {Object.keys(RightsStatusOptions).map((key) => {
                            const opt = RightsStatusOptions[key]
                            return (
                                <Select.Option value={key} key={key}>
                                    <Tooltip title={opt.description} placement={'left'}>
                                        {opt.name}
                                        <br/>
                                        <small>{opt.description}</small>
                                    </Tooltip>
                                </Select.Option>
                            )
                        })}
                    </Select>
                </FormItem>

                <Divider/>

                <Can I={'manage_rights'} a={'Asset'}>
                    <FormItem label={<>{t('attach-or-remove-contract','Attach or Remove Contract')} <HelpPopover code={'set-contract'}/></>}
                              name='contract_asset_id' style={{marginTop: '.5em'}}>
                        <Select
                            allowClear
                            name={'contract_asset_id'}
                            placeholder={t('select-one','Select One...')}
                            showSearch
                            onSearch={onSearchContracts}
                            loading={!contractAssetOptions}
                            filterOption={false}
                            disabled={values.rights_package_id}
                        >
                            {contractAssetOptions}
                        </Select>
                    </FormItem>

                    <FormItem name={'contract_asset_mode'}>
                        <Select name={'contract_asset_mode'} style={{width:200}} disabled={values.rights_package_id}>
                            <Select.Option value={'add'}>{t('add','Add')}</Select.Option>
                            <Select.Option value={'remove'}>{t('remove','Remove')}</Select.Option>
                        </Select>
                    </FormItem>
                </Can>
            </>
        )
    }
    return (
        <BulkEditModal
            visible={visible}
            setVisible={setVisible}
            title={<><RightsIcon/> {t('bulk-edit-rights','Bulk Edit Rights')}</>}
            initialValues={{
                contract_asset_mode: 'add'
            }}
        >
            <BulkEditRightsForm/>
        </BulkEditModal>
    )
}

export {BulkEditRightsModal}

const BulkEditReleasesModal = ()=> {
    const {t} = useTranslation();

    const {releasesModalOpen: visible, setReleasesModalOpen: setVisible} = useAssetActions();

    const BulkEditReleasesForm = ()=>{
        const {currentUpload} = useAssetGroupState()
        const [releaseAssets, setReleaseAssets] = useState()

        const releaseAssetOptions = releaseAssets?.map(asset =>
            <Select.Option key={asset.id} value={asset.id}><Tooltip title={asset.description}><FileTextOutlined/> {asset.filename}</Tooltip></Select.Option>
        )

        useEffect(()=>{
            if(!visible) return;
            api(`/api/assets/releases?upload_id=${currentUpload?.id || ''}`).then(res => {
                setReleaseAssets(res.data)
            })
        }, [visible])

        const onSearchReleases = (q) => {
            api(`/api/assets/releases`, {params: {q}}).then(res => {
                setReleaseAssets(res.data)
            })
        }

        return (
            <>
                <FormItem label={<>{t('attatch-or-remove-releases','Attach or Remove Releases')} <HelpPopover code={'set-releases'}/></>} name='release_asset_ids' style={{marginTop:'.5em'}}>
                    <Select
                        showSearch
                        onSearch={onSearchReleases}
                        name={'release_asset_ids'}
                        placeholder={t('select-model-property-releases','Select Model / Property Releases (Choose Multiple)')}
                        mode={'multiple'}
                        loading={!releaseAssetOptions}
                        filterOption={false}
                    >
                        {releaseAssetOptions}
                    </Select>
                </FormItem>

                <FormItem name={'release_assets_mode'}>
                    <Select name={'release_assets_mode'} style={{width:200}}>
                        <Select.Option value={'add'}>{t('add','Add')}</Select.Option>
                        <Select.Option value={'remove'}>{t('remove','Remove')}</Select.Option>
                    </Select>
                </FormItem>

                <Divider/>

                <FormItem name={'model_release_status'} label={<>{t('set-model-release-status','Set Model Release Status')} <HelpPopover code={'asset-released'}/></>}>
                    <Select name={'model_release_status'}>
                        {MetaFields.model_release_status.options.map((value, i) => (
                            <Select.Option value={value} key={i}>{t(value, value)}</Select.Option>
                        ))}
                    </Select>
                </FormItem>

                <FormItem name={'property_release_status'} label={<>{t('set-property-release-status','Set Property Release Status')} <HelpPopover code={'asset-property-release'}/></>}>
                    <Select name={'property_release_status'}>
                        {MetaFields.property_release_status.options.map((value, i) => (
                            <Select.Option value={value} key={i}>{t(value,value)}</Select.Option>
                        ))}
                    </Select>
                </FormItem>

                <Divider>{t('model-release-details','Model Release Details')}</Divider>

                <FormItem name={'addl_model_info'} label={<>{t('additional-model-info','Additional Model Info')}</>}>
                    <Input.TextArea name='addl_model_info' autoComplete='off' placeholder={t('add-info','Add Info')}/>
                </FormItem>

                <FormItem name={'addl_model_info_mode'}>
                    <Select name={'addl_model_info_mode'} style={{width:200}}>
                        <Select.Option value={'append'}>{t('append','Append')}</Select.Option>
                        <Select.Option value={'replace'}>{t('replace','Replace')}</Select.Option>
                        <Select.Option value={'blank'}>{t('only-add-if-blank','Only add if blank')}</Select.Option>
                    </Select>
                </FormItem>

                <FormItem name={'iptc_model_release_id'} label={t('model-release-id','Model Release ID')}>
                    <Input name={'iptc_model_release_id'} placeholder={t('placeholder-model-release-id','Add Release ID')}/>
                </FormItem>

                <FormItem name={'model_age'} label={t('model-age','Model Age')}>
                    <Input name={'model_age'} placeholder={t('model-age-placeholder','Add Model Age')} type={'number'}/>
                </FormItem>

                <FormItem name={'minor_model_age_disclosure'} label={t('minor-model-age','Minor Model Age')}>
                    <Select name={'minor_model_age_disclosure'}>
                        {MetaFields.minor_model_age_disclosure.options.map((value, i) => (
                            <Select.Option value={value} key={i}>{t(value,value)}</Select.Option>
                        ))}
                    </Select>
                </FormItem>

                <FormItem name={'iptc_property_release_id'} label={t('property-release-id','Property Release ID')}>
                    <Input name={'iptc_property_release_id'} placeholder={t('placeholder-property-release-id','Property Release ID')}/>
                </FormItem>
            </>
        )
    }
    return (
        <BulkEditModal
            visible={visible}
            setVisible={setVisible}
            title={<><FileProtectOutlined/> {t('bulk-edit-releases','Bulk Edit Releases on')}</>}
            initialValues={{
                addl_model_info_mode: 'append',
                release_assets_mode: 'add'
            }}
        >
            <BulkEditReleasesForm/>
        </BulkEditModal>
    )
}


const BulkDestroyModal = ()=> {
    const {t} = useTranslation();

    const {viewAllType, currentAssetGroup} = useAssetGroupState()
    const permanent = viewAllType === 'Trash';

    const {destroyModalOpen: visible, setDestroyModalOpen: setVisible} = useAssetActions()

    const BulkDestroyForm = ()=>{
        return (
            <FormItem name={'destroy_all'} style={{marginBottom:0, height:'2em'}}>
                <Checkbox name={'destroy_all'}>
                    <Typography.Text type={'danger'}>
                        <DeleteOutlined/> {permanent ? t('confirm-permanently-delete-selected','Permanently Delete Selected?') : t('move-selected-to-trash','Move Selected To Trash?')}
                    </Typography.Text>
                </Checkbox>
            </FormItem>
        )
    }

    return (
        <BulkEditModal
            visible={visible}
            setVisible={setVisible}
            title={permanent ? <><DeleteOutlined/> {t('bulk-permanently-delete','Bulk Permanently Delete')}</> : <><DeleteOutlined/> {t('bulk-trash','Bulk Trash')}</>}
            actionText={permanent ? 'Delete' : 'Trash'}
        >
            <BulkDestroyForm/>
        </BulkEditModal>
    )
}

export {BulkDestroyModal}


const BulkRestoreModal = ({canRestore})=>{
    const {t} = useTranslation();

    const {restoreModalOpen: visible, setRestoreModalOpen: setVisible} = useAssetActions()

    const BulkRestoreForm = ()=>{
        return (
            <FormItem name={'restore_all'} style={{marginBottom:0}}>
                <Checkbox name={'restore_all'}>
                    <RefreshIcon/> {t('confirm-restore-all','Restore All?')}
                </Checkbox>
            </FormItem>
        )
    }

    return (
        <BulkEditModal
            visible={visible}
            setVisible={setVisible}
            title={<><RefreshIcon/> {t('bulk-restore','Bulk Restore')}</>}
            actionText={'Restored'}
        >
            <BulkRestoreForm/>
        </BulkEditModal>
    )
}

const useRefreshBulkJobAssets = () => {
    const assetsDispatch = useAssetsDispatch();

    return bulkJob => {
        console.log('refreshBulkJobAssets', bulkJob)
        for(let page = 1; page <= Math.ceil(bulkJob.total_assets / 25); page++){
            api(`/api/bulk_jobs/${bulkJob.guid}/assets?page=${page}`).then(res => {
                assetsDispatch({type: 'assetsLoaded', assets: res.data})
            })
        }

        document.querySelector(`.asset-current-cursor`)?.focus();
    }
}

const useBulkAction = ()=>{
    const {t} = useTranslation();
    const {createBulkJob, bulkJobId} = useCreateBulkJob()

    const {bulkJobs, finishedBulkJobIds} = useBulkJobsState()
    const bulkJob = bulkJobs[bulkJobId]

    const reloadAssets = useRef(true);

    const {selectedAssetIds} = useSelectedAssetsState();

    const onFinish = useRef()

    const refreshBulkJobAssets = useRefreshBulkJobAssets()

    useEffect(()=>{
        const key = `progress-${bulkJobId}`

        if(bulkJobId) {
            if(bulkJob?.progress === 100 || finishedBulkJobIds.indexOf(bulkJobId) !== -1) {
                message.open({
                    key,
                    type: 'success',
                    content: 'Done!',
                    duration: 1
                })

                console.log('done')
                onFinish.current && onFinish.current()

                if(reloadAssets.current) refreshBulkJobAssets(bulkJob)
            } else {
                message.open({
                    key,
                    type: 'loading',
                    content: <>{t('processing','Processing')}...<Progress percent={bulkJob?.progress || 0} status={bulkJob?.error ? 'error' : 'active'}/></>,
                    duration: 0
                })
            }
        }
        return ()=> message.destroy(key)
    }, [bulkJob?.progress])

    return (values, opts={})=> {
        const {noRefresh, cb, noConfirm, noAssetIds} = opts;

        onFinish.current = cb;

        const onOk = ()=> {
            reloadAssets.current = !noRefresh;
            createBulkJob(values, null, {noAssetIds})
        }

        if(!noConfirm && selectedAssetIds.length > 1) {
            Modal.confirm({
                onOk,
                onCancel: ()=>{ },
                content: t('confirm-bulk-action-with-count', 'Proceed with {{count}} Assets?', {count: selectedAssetIds.length})
            });
        } else onOk()
    }
}

export {useBulkAction}

const useCreateBulkJob = () => {
    const {selectedAssetIds} = useSelectedAssetsState();
    const [bulkJobId, setBulkJobId] = useState()
    const bulkJobDispatch = useBulkJobsDispatch()
    const {currentAssetGroup} = useAssetGroupState()

    const createBulkJob = (values, actions, opts={}) => {
        const {noAssetIds} = opts;
        if(!noAssetIds) values.asset_ids = selectedAssetIds;
        values.current_asset_group_id = currentAssetGroup?.asset_group_id

        api.post(`/api/bulk_jobs`, {bulk_job: values}).then(res => {
            actions?.resetForm();
            actions?.setSubmitting(false);

            setBulkJobId(res.data.guid)
            bulkJobDispatch({type:'add', bulkJob: res.data})

        }).catch((err)=>{
            console.log(err)
        })
    }

    return {createBulkJob, bulkJobId, setBulkJobId}
}

const BulkEditModal = ({visible, setVisible, title, actionText='Edit', initialValues={}, children})=> {
    const {t} = useTranslation();
    const {selectedAssetIds} = useSelectedAssetsState();
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const {currentUpload} = useAssetGroupState()

    const loadedAssetsDispatch = useLoadedAssetsDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const {createBulkJob, bulkJobId, setBulkJobId} = useCreateBulkJob()
    const refreshBulkJobAssets = useRefreshBulkJobAssets()

    return (
        (<Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, actions)  => {
                createBulkJob(values, actions)
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {

                const onFinish = (bj)=> {
                    console.log(bj)

                    message.success(t('bulk-action-finished',`Bulk {{action}} finished!`, {action: actionText}))

                    if(!bj.warnings?.length && !bj.error) {
                        setVisible(false)
                        setBulkJobId(null)

                        refreshBulkJobAssets(bj)
                    }

                    currentUpload && editAssetDispatch({type:'increment'})

                    if(bj.destroy_all) {
                        selectedAssetDispatch({type:'selectNone'})
                    } else {
                        selectedAssetDispatch({type:'reload'})
                    }
                }

                return (
                    (<Modal
                        zIndex={1031}
                        width={isMobile() ? '100%' : 800}
                        onCancel={()=> setVisible(false)}
                        open={visible}
                        title={
                            <>
                                {title || <><EditOutlined/> {t('bulk-edit','Bulk Edit')}</>} {selectedAssetIds.length} {t(pluralize('Asset', selectedAssetIds.length))}
                            </>
                        }
                        footer={!bulkJobId && (
                            <Popconfirm title={t('confirm-are-you-sure','Are you sure?')} onConfirm={submitForm} zIndex={1032}>
                                <Button icon={<SaveOutlined/>} loading={isSubmitting}>{t('button-submit','Submit')}</Button>
                            </Popconfirm>
                        )}
                    >
                        {bulkJobId && (
                            <BulkJobProgress id={bulkJobId} onFinish={onFinish}/>
                        ) || (
                            <Form onSubmit={handleSubmit} layout="vertical">
                                {children}
                            </Form>
                        )}
                    </Modal>)
                );
            }}
        </Formik>)
    );
}

const FieldWithMode = ({f, textField}) => {
    const {t} = useTranslation();
    const name = t(f.replace('_','-'), f.replace('_',' ').toProperCase())

    const InputComponent = textField ? Input : Input.TextArea

    return (
        <>
            <FormItem name={f} label={<>{t('add','Add')} {name} <HelpPopover code={`bulk-edit-${f}`}/></>}>
                <InputComponent name={f} autoComplete='off' placeholder={t('type-input-placeholder',`Type {{name}} here...`, {name})}/>
            </FormItem>

            <FormItem name={`${f}_mode`}>
                <Select name={`${f}_mode`} style={{width:200}}>
                    <Select.Option value={'append'}>{t('append','Append')}</Select.Option>
                    <Select.Option value={'overwrite'}>{t('overwrite','Overwrite')}</Select.Option>
                    <Select.Option value={'blank'}>{t('only-add-if-blank','Only add if blank')}</Select.Option>
                </Select>
            </FormItem>
        </>
    )
}

export {BulkEditModal}

const AssetActions = ()=>{
    return (
        <>
            <BulkEditMetaModal/>
            <BulkDestroyModal/>
            <BulkRestoreModal/>
            <BulkEditRightsModal/>
            <BulkEditReleasesModal/>
            <AddToAssetGroupModal type={'lightboxes'}/>
            <AddToAssetGroupModal type={'collections'}/>
        </>
    )
}

export {AssetActions}

const BulkEditAssetMenu = ({asset, trigger, children, id})=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const {colorLabel} = useViewMode()

    const {viewAllType, currentAssetGroup} = useAssetGroupState()
    const inTrash = viewAllType == 'Trash';

    const selectedAssetDispatch = useSelectedAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch();

    const aggsDispatch = useAggsDispatch();
    const {selectedAssetIds} = useSelectedAssetsState();
    const selected = asset && selectedAssetIds?.indexOf(asset.id) !== -1

    const onContextMenu = visible => {
        if(asset && visible && !selected) selectedAssetDispatch({type: 'toggleAsset', id: asset.id})

        if(visible) loadCustomMetaFields()
        return true
    }

    const [customMetaFields, setCustomMetaFields] = useState()
    const [loadingCustomMetaFields, setLoadingCustomMetaFields] = useState()
    const loadCustomMetaFields = () => {
        setLoadingCustomMetaFields(true)
        api('/api/custom_meta_fields?action_menu=true').then(res => {
            setLoadingCustomMetaFields(false)
            setCustomMetaFields(res.data)
        })
    }

    const getPopupContainer = node => asset && id ? document.getElementById(id) : node.parentElement

    const onRemoveAssetGroup = () => {
        // Use ID of current Bin (folder) when viewing:
        const asset_group_id = currentAssetGroup.folder?.id || currentAssetGroup.asset_group_id;

        api.post('/api/assets/remove_group', {ids: selectedAssetIds, asset_group_id}).then(res => {
            loadedAssetsDispatch({type:'removeSelectedAssets', selectedAssetIds});
            selectedAssetDispatch({type:'selectNone'})
            aggsDispatch({type:'remove', count: selectedAssetIds.length})

            const n = selectedAssetIds.length
            const s = n > 1 ? 's' : '';
            message.success(`${n} ${t(`message-asset${s}-removed`, `Asset${s} removed.`)}`)
        })
    }

    const onMenuClick = ({domEvent}) => {
        console.log('onMenuClick', domEvent)
        domEvent.stopPropagation()
    }

    const editableAssetGroup = currentAssetGroup?.type === 'StorageFolder' && currentUser?.user_group_ids?.indexOf(currentAssetGroup?.user_group_id) !== -1;

    const ability = useAbility(AbilityContext);

    const roleLevel = currentOrg?.role_level
    let canAdmin, canManage, canTag;
    switch(roleLevel) {
        case 'admin':
        case 'global_content':
            canAdmin = canManage = canTag = true;
        case 'global_library':
            canManage = canTag = true;
        case 'global_tagger':
            canTag = true;
        default:
            canManage = canManage || editableAssetGroup
            canTag = canTag || editableAssetGroup || ability.can('view_bulk_edit', 'Organization')
    }

    const {assets} = useAssetsState()
    let anyEditable = false

    for(const id of selectedAssetIds) {
        const asset = assets[id]
        if(asset?.editable) {
            anyEditable = true
            break
        }
    }

    const {
        setEditModalOpen,
        setDownloadModalOpen,
        setDestroyModalOpen,
        setShareModalOpen,
        setRestoreModalOpen,
        setRightsModalOpen,
        setReleasesModalOpen,
        setAddToLightboxModalOpen,
        setAddToCollectionModalOpen,
    } = useAssetActions()

    const items = []
    const divider = {type: 'divider'}
    const bulkAction = useBulkAction()

    if(asset) {
        items.push(
            asset.downloadable || canManage ?
                {
                    key: 'download',
                    icon: <CloudDownloadOutlined/>,
                    label: <>{t('download', 'Download')}...</>,
                    onClick: setDownloadModalOpen
                } : {
                    key: 'download',
                    label: <em>{t('asset-not-downloadable', 'Asset not downloadable')}</em>,
                    disabled: true
                }
            , divider
        )
    }

    if(currentUser) {
        items.push({
            key: 'share',
            icon: <ShareIcon/>,
            label: <>{t('share','Share')}...</>,
            onClick: setShareModalOpen,
            disabled: selectedAssetIds.length > Config.maxShareAssets
        }, divider)
    }

    if(canTag || anyEditable || asset?.editable) {
        customMetaFields?.map(cmf => {
            items.push({
                key: `custom-meta-${cmf.id}`,
                icon: <CustomMetaIcon/>,
                label: <Space>{cmf.name} {colorLabel === cmf.id && <Tag color={'green'} icon={<EyeOutlined/>}>{t('viewing','Viewing')}</Tag>}</Space>,
                children: [
                    // Existing options
                    ...cmf.custom_meta_values_attributes.map(cmv => {
                        const values = {custom_meta:{}}
                        values.custom_meta[cmf.name] = cmv.text

                        return {
                            key: `custom-meta-value-${cmv.id}`,
                            icon: <Badge color={cmv.color_label}/>,
                            label: cmv.text,
                            onClick: () => bulkAction(values)
                        }
                    }),
                    // Add "None" option at the end of each sub-menu
                    {
                        key: `custom-meta-value-${cmf.id}-none`,
                        icon: <MinusOutlined />,
                        label: t('none', 'None'),
                        onClick: () => {
                            const values = { custom_meta: {} };
                            values.custom_meta[cmf.name] = null; // Set to null to unset the value
                            bulkAction(values);
                        }
                    }
                ]
            })
        })

        if(customMetaFields?.length) {
            items.push(divider)
        }

        items.push({
            key: 'edit-meta',
            icon: <EditOutlined/>,
            label: <>{t('button-edit-metadata','Edit Metadata')}...</>,
            onClick: setEditModalOpen
        })
    }

    if(canManage) {
        items.push({
            key: 'edit-rights',
            icon: <RightsIcon style={{marginRight: 8}}/>,
            label: <>{t('button-edit-rights','Edit Rights')}...</> ,
            onClick: setRightsModalOpen
        }, {
            key: 'edit-releases',
            icon: <FileProtectOutlined/>,
            label: <>{t('button-edit-releases','Edit Releases')}...</> ,
            onClick: setReleasesModalOpen
        }, divider)
    }

    if(canTag) {
        items.push({
            key:'rotate',
            icon: <SyncOutlined/>,
            label: t('rotate','Rotate'),
            children: [
                {
                    key: 'rotate-left',
                    icon: <RotateLeftOutlined/>,
                    label: t('rotate-left-90','Left 90°'),
                    onClick: ()=> bulkAction({rotate: 'left'}),
                },
                {
                    key: 'rotate-right',
                    icon: <RotateRightOutlined/>,
                    label: t('rotate-right-90','Right 90°'),
                    onClick: ()=> bulkAction({rotate: 'right'}),
                },
                {
                    key: 'rotate-flip',
                    icon: <SyncOutlined/>,
                    label: t('flip-180','Flip 180°'),
                    onClick: ()=> bulkAction({rotate: 'flip'}),
                },
                {
                    key: 'rotate-mirror',
                    icon: <SwapOutlined/>,
                    label: t('mirror','Mirror°'),
                    onClick: ()=> bulkAction({rotate: 'mirror'}),
                },
            ]
        })
    }

    if(canManage) {
        items.push({
            key: 'request-optimization',
            icon: <ReconciliationOutlined/>,
            label: t('request-optimization','Request Optimization'),
            children: [
                {
                    key: 'request',
                    icon: <AlertOutlined/>,
                    label: t('request','Request'),
                    onClick: ()=> bulkAction({optimization_request: 'request'}),
                },
                {
                    key: 'complete',
                    icon: <CheckOutlined/>,
                    label: t('complete','Complete'),
                    onClick: ()=> bulkAction({optimization_request: 'complete'}),
                },
                {
                    key: 'remove',
                    icon: <CloseOutlined/>,
                    label: t('remove','Remove'),
                    onClick: ()=> bulkAction({optimization_request: 'remove'}),
                },
            ]
        }, {
            key: 'remove-requirements',
            icon: <CheckOutlined/>,
            label: t('button-remove-requirements-flag','Remove Requirements Flag'),
            disabled: selectedAssetIds.length === 1 && asset?.submitted,
            onClick: ()=> bulkAction({submit: true})
        })
    }

    if(canTag && currentOrg?.enable_ai) {
        const aiItems = []

        aiItems.push({
            key: 'auto-tag',
            icon: <CloudServerOutlined/>,
            label: t('button-run-auto-tagging','Run Auto Tagging'),
            disabled: selectedAssetIds.length === 1 && asset?.rekognition_labels_retrieved_at,
            onClick: () => bulkAction({auto_tag: true})
        })

        if(currentOrg?.alt_text_generation_mode !== 'off') {
            aiItems.push({
                key: 'generate-alt-text',
                icon: <CloudServerOutlined/>,
                label: t('button-generate-alt-text','Generate Alt Text'),
                disabled: selectedAssetIds.length === 1 && asset?.alt_text?.length,
                onClick: () => bulkAction({generate_alt_text: true})
            })
        }

        if(currentOrg?.enable_face_tagging) {
            aiItems.push({
                key: 'search-faces',
                icon: <UserOutlined/>,
                label: t('button-run-face-search','Run Face Search'),
                onClick: ()=> bulkAction({search_faces: true})
            }, {
                key: 'ignore-faces',
                icon: <CloseCircleOutlined/>,
                label: t('button-run-ignore-unidentified-faces','Ignore Unidentified Faces'),
                onClick: ()=> bulkAction({ignore_unidentified_faces: true})
            })

            if(canAdmin && currentOrg?.enable_nsfw_detection) {
                aiItems.push({
                    key: 'remove-nsfw',
                    icon: <EyeInvisibleOutlined/>,
                    label: t('button-remove-nsfw-tag','Remove NSFW Tags'),
                    onClick: () => bulkAction({nsfw_detected: 'no'}),
                    disabled: selectedAssetIds.length === 1 && !asset?.nsfw_detected,
                })
            }
        }

        items.push({
            key: 'ai',
            icon: <RobotOutlined/>,
            label: t('ai-actions', 'AI Actions'),
            children: aiItems
        })
    }

    if(canTag && currentOrg?.frame_account) {
        items.push({
            key: 'frame',
            icon: <FrameIcon/>,
            label: t('frame-io-integration','Frame.io Integration'),
            children: [
                {
                    key: 'get-status',
                    icon: <ArrowLeftOutlined/>,
                    label: t('bulk-get-frame-status','Get current status from Frame.io'),
                    onClick: ()=> bulkAction({get_frame_status: true}),
                },
                {
                    key: 'set-status',
                    icon: <EditOutlined/>,
                    label: t('set-status','Set Status'),
                    children: [
                        {
                            key: 'status-review',
                            icon: <FrameStatusIcon status={'needs_review'}/>,
                            label: t('frame-status-needs_review','Needs Review'),
                            onClick: ()=> bulkAction({set_frame_status: 'needs_review'}),
                        },
                        {
                            key: 'in-progress',
                            icon: <FrameStatusIcon status={'in_progress'}/>,
                            label: t('frame-status-in_progress','In Progress'),
                            onClick: ()=> bulkAction({set_frame_status: 'in_progress'}),
                        },
                        {
                            key: 'approved',
                            icon: <FrameStatusIcon status={'approved'}/>,
                            label: t('frame-status-approved','Approved'),
                            onClick: ()=> bulkAction({set_frame_status: 'approved'}),
                        },
                        {
                            key: 'none',
                            icon: <FrameStatusIcon status={'none'}/>,
                            label: t('frame-status-none','None'),
                            onClick: ()=> bulkAction({set_frame_status: 'none'}),
                        },
                    ]
                },
                {
                    key: 'send-description',
                    icon: <ArrowRightOutlined/>,
                    label: t('send-description','Send Description'),
                    onClick: ()=> bulkAction({set_frame_description: true}),
                },
                {
                    key: 'send-version',
                    icon: <FileAddOutlined/>,
                    label: t('send-new-version','Send New Version'),
                    onClick: ()=> bulkAction({send_frame_version: true}),
                }
            ]
        }, divider)
    }

    if(canAdmin) {
        const rightsItems = []

        rightsItems.push({
            key: 'contract',
            icon: <FileTextOutlined />,
            label: t('button-set-as-contract','Set as Contract'),
            onClick: ()=> bulkAction({contract: true}),
            disabled: selectedAssetIds.length === 1 && asset?.contract
        }, {
            key: 'release',
            icon: <FileProtectOutlined />,
            label: t('button-set-as-release','Set as Release'),
            onClick: ()=> bulkAction({release: true}),
            disabled: selectedAssetIds.length === 1 && asset?.release
        })

        items.push({
            key: 'rights-set',
            icon: <RightsIcon/>,
            label: t('legal','Legal'),
            children: rightsItems
        }, divider)

    }

    if(currentUser) {
        items.push({
            key:'like',
            icon: <ThumbsUpIcon/>,
            label: t('button-set-as-like','Set as Like'),
            onClick: ()=> bulkAction({vote: 1})
        }, {
            key:'dislike',
            icon: <ThumbsDownIcon/>,
            label: t('button-set-as-dislike','Set as Dislike'),
            onClick: ()=> bulkAction({vote: -1})
        }, divider, {
            key:'lightboxes',
            icon: <LightboxIcon/>,
            label: t('button-add-to-lightbox','Add to Lightbox'),
            onClick: setAddToLightboxModalOpen
        },{
            key:'collections',
            icon: <CollectionIcon/>,
            label: t('button-add-to-collection','Add to Collection'),
            onClick: setAddToCollectionModalOpen
        })
    }

    if(canManage) {
        items.push({
            key: 'block-level',
            icon: <FlagOutlined/>,
            label: t('restricted-access','Restricted Access'),
            children: [
                {
                    key: 'block-none',
                    icon: <MinusOutlined/>,
                    label: t('remove-restriction','Remove Restriction'),
                    onClick: ()=> bulkAction({block_level: 0})
                },
                {
                    key: 'block-download',
                    icon: <BlockDownload/>,
                    label: t('block-download','Block Download'),
                    onClick: ()=> bulkAction({block_level: 1})
                },
                {
                    key: 'block-view',
                    icon: <BlockView/>,
                    label: t('block-view-and-download','Block View and Download'),
                    onClick: ()=> bulkAction({block_level: 2})
                },
            ]
        }, divider)
    }

    if((currentAssetGroup?.editable || currentAssetGroup?.can_update_assets) && currentAssetGroup.type !== 'StorageFolder') {
        items.push({
            key: 'remove-group',
            danger: true,
            icon: <CloseCircleOutlined/>,
            label: (
                <Popconfirm
                    title={t('confirm-remove','Remove?')}
                    onConfirm={onRemoveAssetGroup}
                    trigger={'click'}
                >
                    <Tooltip title={t('confirm-remove-from-container-and-sub',`Remove Selected from {{name}} and sub-containers.`, {name: currentAssetGroup.folder?.name || currentAssetGroup.name})} placement={'right'}>
                        {t('remove-from-container','Remove from {{type}}', {type: currentAssetGroup.folder?.id ? 'Bin' : currentAssetGroup.type})}...
                    </Tooltip>
                </Popconfirm>
            )
        })
    }

    if(inTrash) {
        items.push({
            key: 'restore',
            icon: <RefreshIcon style={{marginRight:'.5em'}}/>,
            label: <>{t('restore-from-trash','Restore from Trash')}...</>,
            onClick: setRestoreModalOpen,
        })
    }

    if(asset?.editable || canManage) {
        items.push({
            key: 'trash',
            danger: true,
            icon: <DeleteOutlined/>,
            label: <>{inTrash ? t('permanently-delete','Permanently Delete') : t('move-to-trash','Move To Trash')}...</>,
            onClick: setDestroyModalOpen
        })
    }

    const onClick = e => {
        e.domEvent.stopPropagation()
    }

    return (
        <Dropdown
            arrow
            trigger={trigger}
            onOpenChange={onContextMenu}
            getPopupContainer={getPopupContainer}
            menu={{items, onClick}}
            overlayStyle={{minWidth:250}}
        >
            {children}
        </Dropdown>
    )
}

export {BulkEditAssetMenu}