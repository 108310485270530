import {
    Dropdown,
    Alert,
    Tooltip,
    Button,
    Card,
    List,
    Modal,
    message,
    Popconfirm,
    Space,
    Row,
    Col,
    Descriptions,
    Typography,
    Skeleton,
    Menu,
    Rate,
    Select,
    Tabs,
    Badge,
    Checkbox,
    Tag,
    Popover,
    Cascader,
    Collapse,
    Input as AntInput,
    Select as AntSelect,
    Divider,
    notification,
    Progress,
    TimePicker,
    DatePicker,
    Radio,
    Switch,
    Upload, Avatar, ConfigProvider, Flex, Statistic
} from "antd";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import React, {createContext, createElement, useCallback, useContext, useEffect, useRef, useState} from "react";
import {AbilityContext, Can} from "../helpers/Can";
import {
    TagOutlined,
    DeleteOutlined,
    FolderOutlined,
    AppstoreAddOutlined,
    LinkOutlined,
    ClockCircleOutlined,
    InfoOutlined,
    LineChartOutlined,
    ZoomInOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    StarOutlined,
    StarFilled,
    FileTextOutlined,
    EyeFilled,
    ClockCircleTwoTone,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EyeOutlined,
    CheckOutlined,
    CloseOutlined,
    QuestionOutlined,
    OrderedListOutlined,
    CommentOutlined,
    PushpinOutlined,
    CloudDownloadOutlined,
    LoadingOutlined,
    ScissorOutlined,
    ArrowRightOutlined,
    PlayCircleFilled,
    PlayCircleOutlined,
    PlusOutlined,
    StepBackwardOutlined,
    StepForwardOutlined,
    FileOutlined,
    PicCenterOutlined,
    PicLeftOutlined,
    ReconciliationOutlined,
    ProfileOutlined,
    UserOutlined,
    CalendarOutlined,
    EnterOutlined,
    CloudServerOutlined,
    InfoCircleOutlined,
    CameraOutlined,
    ShoppingCartOutlined,
    FlagOutlined,
    EyeInvisibleOutlined,
    UploadOutlined,
    FileImageOutlined,
    PictureOutlined,
    SettingOutlined,
    LogoutOutlined,
    LockOutlined,
    RobotOutlined,
    ThunderboltOutlined,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import TimeAgo from "../helpers/TimeAgo";
import User from "../helpers/User";
import filesize from "filesize";
import {AppContext} from "../../contexts/AppContext";
import TagSelect, {EditableTag} from "./TagSelect";
import Links from "./Links";
import AssetGroupWorkflows, {useWorkflowStep} from "./AssetGroupWorkflows";
import {NavLink, useLocation, useNavigate} from "react-router-dom-v5-compat";
import AssetGroupAssetInfo from "./AssetGroupAssetInfo";
import { HotKeys } from "react-hotkeys";
import {
    ArtworkIcon, BlockDownload, BlockView,
    CollectionIcon, ShareIcon, GraphIcon, GreyBadge,
    ProjectIcon,
    RightsIcon,
    StorageFolderIcon,
    ThumbsDownIcon,
    ThumbsUpIcon, LeftIcon, RightIcon, CustomMetaIcon
} from "../helpers/icons";
import {useAssetsDispatch, useAssetsState} from "../../contexts/AssetsContext";
import {useAssetGroupDispatch, useAssetGroupState} from "../../contexts/AssetGroupContext";
import {useEditAssetsDispatch} from "../../contexts/EditAssetsContext";
import WarningTwoTone from "@ant-design/icons/lib/icons/WarningTwoTone";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import {defaultStyles, FileIcon} from "react-file-icon";
import {useLoadedAssetsDispatch, useLoadedAssetsState} from "../../contexts/LoadedAssetsContext";

import moment from "moment-timezone";
import day from "dayjs";

import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";

day.extend(utc)
day.extend(timezone)

import AssetHistory from "./AssetHistory";
import useAssetViewer from "../helpers/useAssetViewer";
import api from "../api";
import OrgNavLink, {OrgLink, useOrgPath} from "../helpers/OrgNavLink";
import {CreatorTag, CreatorTagSelect} from "../explore/CreatorTagList";
import HelpPopover from "../HelpPopover";
import RightsPackage, {RightsPackageSelect} from "./RightsPackage";
import AssetLink, {useLoadAssetFromGuid} from "./AssetLink";
import {useFilters} from "../helpers/useFilters";
import {useAbility} from "@casl/react";
import AssetLog from "./AssetLog";

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "device-detect";
import {useSwipeable} from "react-swipeable";
import {RequiredTag} from "./EditAssetTable";
import DeleteAssetButton, {useDeleteAsset} from "./DeleteAssetButton";
import {EditOutlined} from "@ant-design/icons";
import PinturaButton from "./PinturaButton";
import {useStorageState} from "react-storage-hooks";
import {useCurrentAssetsDispatch, useCurrentAssetsState} from "../../contexts/CurrentAssetContext";
import useSetCurrentAsset, {useCurrentAssetCursor} from "../helpers/useSetCurrentAsset";
import useCurrentUser, {useTimezone} from "../helpers/useCurrentUser";
import {useSelectedAssetsDispatch, useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import {useViewMode} from "../explore/ViewMenu";
import UploadProgress from "../uploads/UploadProgress";
import {Formik} from "formik";
import SaveOutlined from "@ant-design/icons/lib/icons/SaveOutlined";
import {Form, FormItem, Input, Select as FormikSelect} from "formik-antd";
import {markdown} from 'markdown';
import {Parser} from 'html-to-react';
import useCurrentOrg, {useAfterOrgLoad} from "../helpers/useCurrentOrg";
import axios from "axios";

const loadedPermalinkIds = []

const AssetModalContext = createContext(null);
export {AssetModalContext};

export default ()=>{
    const {t} = useTranslation();
    const {assets} = useAssetsState()
    const {assetIds} = useLoadedAssetsState()
    const {currentAssetId} = useCurrentAssetsState()
    const asset = assets[currentAssetId]

    const currentOrg = useCurrentOrg()

    useEffect(()=>{
        // Preload adjacent images:
        const i = assetIds.indexOf(currentAssetId);
        [1, -1].map(n => {
            const adjAsset = assets[assetIds[i + n]]

            if(!adjAsset || loadedPermalinkIds.indexOf(adjAsset.id) !== -1) return
            loadedPermalinkIds.push(adjAsset.id)

            const url = adjAsset?.permalink_url || adjAsset?.small_url
            if(url) new Image().src = url
        })
    }, [currentAssetId])

    const assetsDispatch = useAssetsDispatch();

    const {currentWorkflowStep, currentAssetGroup} = useAssetGroupState()

    const [visible, setVisible] = useState(!!asset);

    const setCurrentAsset = useSetCurrentAsset()

    const handleCancel = () => {
        setVisible(false)
    };

    const afterClose = ()=> {
        setAssetLoading(false);
        setAssetDetailsLoaded(false)
        setCurrentAsset(null)
        playerRef.current = null
    }

    // use a local state asset here to preserve Modal hiding animation:
    const [assetLoading, setAssetLoading] = useState(!asset)

    const ref = useRef();

    const hashRef = useRef()

    useEffect(()=>{
        const currentUrl = window.location.href;
        const baseUrl = currentUrl.split('#')[0];

        if(currentAssetId && asset?.id === currentAssetId) {
            const hash = `/assets/${asset.guid}`
            window.history.replaceState(null,null, `${baseUrl}#${hash}`)
            hashRef.current = hash

        } else if(hashRef.current) {
            hashRef.current = null
            window.history.replaceState(null, null, baseUrl)
        }
    }, [currentAssetId]);

    useEffect(()=>{
        if(!asset?.id) {
            setPlayerLoaded(false)
        }
    }, [asset?.id])

    //--------------------------------------------
    const editAssetDispatch = useEditAssetsDispatch()

    const [assetDetailsLoaded, setAssetDetailsLoaded] = useState(false)
    const getAssetDetails = () => {
        setAssetLoading(true)
        api(`/api/assets/${asset.id}`).then(res => {
            // check to see if current asset has changed:
            if(asset.id !== res.data.id) return;

            setAssetDetailsLoaded(true)
            setAssetLoading(false)
            assetsDispatch({type:'updateAsset', asset: res.data});

            // Don't rewrite this signed URLs that are already cached:
            delete res.data.permalink_url
            delete res.data.small_url
            delete res.data.grid_url

            setAttrs({...attrs, ...res.data})

            ref.current?.focus();
        })
    }

    useEffect(()=> {
        if(!asset?.id) return;

        setAttrs(asset)
        setVisible(true);
        getAssetDetails()
    }, [currentAssetId, asset?.new_data_version])

    const [attrs, setAttrs] = useState({})

    const onChange = (field, title)=>{
        return (val, cb)=> {
            const data = {}
            data[field] = val
            setAttrs({...attrs, ...data})

            let url = `/api/assets/${asset.id}`
            if(field == 'description') url += '/update_description'

            api.put(url, {asset: data}).then(res => {
                assetsDispatch({type:'updateAsset', asset: res.data})
                editAssetDispatch({type:'increment'})
                message.success(t(`${title || field}-updated`, `${title || field.toProperCase()} updated.`))

                cb && cb()
            })
        }
    }

    const rate = (n)=> {
        onChange('rating')(n)
    }

    const [fullScreen, setFullScreen] = useStorageState(sessionStorage, `fullScreen`, false);
    const toggleFullScreen = ()=> {
        setFullScreen(!fullScreen)
    }

    const [infoDisplay, setInfoDisplay] = useState(sessionStorage.getItem('infoDisplay') || 'rating');
    const cycleInfo = ()=> {
        const modes = ['rating', 'filename', 'none']
        const i = modes.indexOf(infoDisplay) + 1
        const newMode = modes.length == i ? modes[0] : modes[i]
        setInfoDisplay(newMode)
        sessionStorage.setItem('infoDisplay', newMode)
    }

    const currentAssetCursor = useCurrentAssetCursor()

    const swipeHandlers = useSwipeable({
        onSwipedRight: ()=> {currentAssetCursor('prev')},
        onSwipedLeft: ()=> {currentAssetCursor('next')},
        // onSwipedUp: ()=> {handleCancel()},
    })

    const playerRef = useRef()
    const player = playerRef.current
    const [playerLoaded, setPlayerLoaded] = useState()

    const onPlayerReady = videoPlayer => {
        playerRef.current = videoPlayer
        setPlayerLoaded(true)
    };

    const textTrackRef = useRef()

    const [vttLoaded, setVttLoaded] = useState()

    const initPlayer = player => {
        try {
            player.poster(asset.preview_image_url)
        } catch(e) {
            console.log(e)
        }

        if(asset.vtt_url) {
            if(textTrackRef.current) {
                player.removeRemoteTextTrack(textTrackRef.current)
                textTrackRef.current = null
            }
            console.log('loading VTT', player.remoteTextTracks())
            setVttLoaded(false)
            axios(asset.vtt_url).then(res => {
                const blob = new Blob([res.data], { type: 'text/vtt' });
                const vtt = URL.createObjectURL(blob)
                try {
                    textTrackRef.current = player.addRemoteTextTrack({src: vtt, kind:'captions', label:'English', srclang:'en', 'default': true, mode: 'showing'}, true)
                    textTrackRef.current.addEventListener('load', ()=> setVttLoaded(true))
                } catch(e) {
                    console.log(e)
                }
            })
        }
    }

    useEffect(()=>{
        if(player && asset) initPlayer(player)
    }, [asset?.id, asset?.preview_image_url, asset?.vtt_url, playerLoaded])

    const [modalViewMode, setModalViewMode] = useState(sessionStorage.getItem('assetModalViewMode') || currentOrg?.default_asset_modal_layout)
    const isHorizontal = modalViewMode == 'horizontal'

    const updateModalViewMode = mode =>{
        setModalViewMode(mode)
        sessionStorage.setItem('assetModalViewMode', mode)
    }

    const toggleModalViewMode = ()=> updateModalViewMode(isHorizontal ? 'vertical' : 'horizontal')

    const splitWidth = useRef(parseInt(sessionStorage.getItem('horizontalAssetModalSplit'), 10) || '50%')

    const horizontalSplitChange = width => {
        if(width) {
            sessionStorage.setItem('horizontalAssetModalSplit', width)
            splitWidth.current = width
        }
    }

    const [modalMetaMode, setModalMetaMode] = useState(sessionStorage.getItem('assetModalMetaMode') || 'browse')
    const isMetaEdit = modalMetaMode == 'edit'

    const updateModalMetaMode = mode =>{
        setModalMetaMode(mode)
        sessionStorage.setItem('assetModalMetaMode', mode)
    }

    const toggleModalMetaMode = ()=> updateModalMetaMode(isMetaEdit ? 'browse' : 'edit')

    const {changeViewMode} = useViewMode()

    const size = 1200;

    const {approve, reject, unReject, pick} = useWorkflowStep(currentWorkflowStep, asset)

    const keyMap = {
        OPT_UP: 'option+up', OPT_DOWN: 'option+down',
        0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', X: 'x', f: 'f', i: 'i',
        g: 'g', t: 't', m: 'm', v: 'v', e: 'e', a: 'a', c: 'c', r: 'r', u: 'u',
        s: 's', LEFT: 'left', RIGHT: 'right'
    }

    const handlers = {
        0: (e)=> { !e.getModifierState("Meta") && rate(0) },
        1: (e)=> { !e.getModifierState("Meta") && rate(1) },
        2: (e)=> { !e.getModifierState("Meta") && rate(2) },
        3: (e)=> { !e.getModifierState("Meta") && rate(3) },
        4: (e)=> { !e.getModifierState("Meta") && rate(4) },
        5: (e)=> { !e.getModifierState("Meta") && rate(5) },
        X: (e)=> { !e.getModifierState("Meta") && rate(-1) },
        f: (e)=> { !e.getModifierState("Meta") && toggleFullScreen() },
        i: (e)=> { !e.getModifierState("Meta") && cycleInfo() },
        g: (e)=> { !e.getModifierState("Meta") && handleCancel() && changeViewMode('grid') },
        t: (e)=> { !e.getModifierState("Meta") && handleCancel() && changeViewMode('table') },
        m: (e)=> { !e.getModifierState("Meta") && handleCancel() && changeViewMode('map') },
        v: (e)=> { !e.getModifierState("Meta") && toggleModalViewMode() },
        e: (e)=> { !e.getModifierState("Meta") && toggleModalMetaMode() },
        a: (e)=> { !e.getModifierState("Meta") && approve(currentWorkflowStep, asset) },
        r: (e)=> { !e.getModifierState("Meta") && reject(currentWorkflowStep, asset) },
        OPT_UP: e => {
            e.preventDefault()
            pick(currentWorkflowStep, asset)
        },
        OPT_DOWN: e => {
            e.preventDefault()
            unReject(currentWorkflowStep, asset)
        },
        s: ()=> {currentAssetCursor('toggle')},
        LEFT: ()=> {currentAssetCursor('prev')},
        RIGHT: ()=> {currentAssetCursor('next')},
    }

    let modalStyle = {top:0, zIndex:9999, padding:0}
    if(isHorizontal) modalStyle = {...modalStyle, margin:0, paddingBottom:0, width: '100vw'}

    const currentUser = useCurrentUser()

    const [customMetaFieldsLoading, setCustomMetaFieldsLoading] = useState(true)
    const [customMetaFields, setCustomMetaFields] = useState([])
    useEffect(()=>{
        if(!currentUser) return;

        setCustomMetaFieldsLoading(true)
        api(`/api/custom_meta_fields`).then(res => {
            setCustomMetaFieldsLoading(false)
            setCustomMetaFields(res.data)
        })
    }, [asset?.id, asset?.taggable, asset?.updated_at, currentOrg?.id])

    if(!asset?.id) return <></>

    return (
        (<AssetModalContext.Provider
            value={{asset, isMetaEdit, isHorizontal, toggleModalMetaMode, toggleModalViewMode, fullScreen, toggleFullScreen, player, vttLoaded, onPlayerReady, setVisible,
                attrs, setAttrs, infoDisplay, handleCancel, afterClose, getAssetDetails, swipeHandlers, ref, onChange, assetLoading, currentAssetCursor, assetDetailsLoaded,
                customMetaFieldsLoading, customMetaFields}}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            paddingContentHorizontal: 0,
                            paddingContentHorizontalLG: 0,
                            paddingContentVertical: 0,
                            paddingContentVerticalLG: 0,
                            paddingMD: 0,
                            padding: 0,
                            marginMD: 0
                        }
                    }
                }}
            >
                <Modal
                    getPopupContainer={e => e.parentElement}
                    focusTriggerAfterClose={false}
                    open={visible}
                    width={isMobile() ? '100%' : (isHorizontal ? '100vw' : size)}
                    afterClose={afterClose}
                    onCancel={handleCancel}
                    footer={null}
                    style={modalStyle}
                    closeIcon={!fullScreen && <div style={{zIndex:9999}}><CloseOutlined style={isMobile() ? {color:'white', filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))', fontSize:'1.5em'} : {}}/></div>}
                >
                    {!isHorizontal && (
                        <>
                            <div style={{position: 'fixed', top: '50%', left: ((window.innerWidth - size) / 2) - 70, zIndex: 10000}}>
                                <a onClick={()=> currentAssetCursor('prev')} style={{color:'white'}} id={'previous-asset-button'}>
                                    <LeftIcon style={{fontSize:40, cursor:'pointer'}} />
                                </a>
                            </div>

                            <div style={{position: 'fixed', top: '50%', right: ((window.innerWidth - size) / 2) - 50, zIndex: 10000}}>
                                <a onClick={()=> currentAssetCursor('next')} style={{color:'white'}} id={'next-asset-button'}>
                                    <RightIcon style={{fontSize:40, cursor:'pointer'}} />
                                </a>
                            </div>
                        </>
                    )}

                    {assetDetailsLoaded && <div id={'asset-details-loaded'} style={{display:'none'}}/>}

                    <div id={'asset-modal'} className={'asset-modal'}>

                        <ConfigProvider
                            theme={{
                                components: {
                                    Modal: {
                                        paddingContentHorizontal: '1em',
                                        paddingContentHorizontalLG: '1em',
                                        paddingContentVertical: '1em',
                                        paddingContentVerticalLG: '1em',
                                        paddingMD: '1em',
                                        padding: '1em',
                                        marginMD: '1em'
                                    }
                                }
                            }}
                        >
                            <HotKeys keyMap={keyMap} handlers={handlers} innerRef={ref} allowChanges>
                                <FullScreenModal/>

                                {isHorizontal ? (
                                    <div style={{position:'relative', width:'100%', overflow: 'auto', height:`100vh`}}>
                                        <SplitPane
                                            split='vertical'
                                            size={splitWidth.current}
                                            onChange={horizontalSplitChange}
                                            pane1Style={{background:'black'}}
                                        >
                                            <div style={{marginRight:'1em'}}>
                                                <AssetViewer/>

                                                <div style={{margin:'1em'}}>
                                                    <AssetExtra/>
                                                </div>
                                            </div>

                                            <AssetInfo>
                                                {currentWorkflowStep && (
                                                    <AssetGroupWorkflows
                                                        assetGroup={currentAssetGroup}
                                                        step={currentWorkflowStep}
                                                        asset={asset}
                                                        onUpdate={getAssetDetails}
                                                        useHotkeys
                                                    />
                                                )}
                                            </AssetInfo>
                                        </SplitPane>
                                    </div>
                                ) : (
                                    <>
                                        {!!currentWorkflowStep ? (
                                            <Row style={{marginBottom:'1em', marginRight:'3em'}} gutter={16}>
                                                <Col span={16}>
                                                    <AssetViewer/>
                                                </Col>
                                                <Col span={8}>
                                                    <AssetGroupWorkflows
                                                        assetGroup={currentAssetGroup}
                                                        step={currentWorkflowStep}
                                                        asset={asset}
                                                        onUpdate={getAssetDetails}
                                                        useHotkeys
                                                    />
                                                </Col>
                                            </Row>
                                        ) : (
                                            <AssetViewer/>
                                        )}

                                        {isMobile() && (
                                            <div style={{margin:'.5em'}}><AssetExtra/></div>
                                        )}

                                        <AssetInfo/>
                                    </>
                                )}

                            </HotKeys>
                        </ConfigProvider>
                    </div>
                </Modal>
            </ConfigProvider>
        </AssetModalContext.Provider>)
    );
}

const MapCard = ()=> {
    const {t} = useTranslation();
    const {asset, handleCancel} = useContext(AssetModalContext)

    const {changeViewMode} = useViewMode()
    const currentAssetDispatch = useCurrentAssetsDispatch()

    const viewOnMap = ()=>{
        currentAssetDispatch({type:'setZoomAsset', asset})
        changeViewMode('map')
        handleCancel()
    }

    const ref = useRef()
    const [width, setWidth] = useState()
    useEffect(() => {
        setWidth(ref.current?.offsetWidth)
    }, [asset?.id]);

    return (asset?.latitude || asset?.editable) &&
        (
            <Card
                title={
                    <>
                        <PushpinOutlined /> {t('gps-location','GPS Location')}
                        {asset.editable && (
                            <div style={{float:'right'}}>
                                <EditGpsButton asset={asset}/>
                            </div>
                        )}
                    </>
                }
                size={'small'}
            >
                <div ref={ref} style={{width:'100%'}}>
                    {asset.latitude ? (
                        <a onClick={viewOnMap}>
                            {width && (
                                <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=${width}x150&maptype=roadmap&markers=color:red%7C${asset.latitude},${asset.longitude}&key=${window.Config.googleApiKey}`}/>
                            )}
                            <br/>
                            {t('view-on-map', 'View On Map')}
                        </a>
                    ) : (
                        <Typography.Text type={'secondary'}><em>{t('none-set', 'None set.')}</em></Typography.Text>
                    )}
                </div>
            </Card>
        )
}

const FullScreenModal = ()=> {
    const {asset, fullScreen, toggleFullScreen, infoDisplay, attrs, swipeHandlers, ref, onChange} = useContext(AssetModalContext)
    const {currentWorkflowStep, currentAssetGroup} = useAssetGroupState()
    const ability = useAbility(AbilityContext);

    const openViewer = useAssetViewer({asset, ref});

    const dropShadow = {filter: 'drop-shadow( 3px 3px 3px rgba(0, 0, 0, .7))'}

    const fullScreenImgStyle = {objectFit: 'contain', minWidth: '100vw', minHeight: '100vh', maxWidth:'100vw', maxHeight:'100vh',
        marginLeft: 'auto', marginRight: 'auto', cursor: 'zoom-in', zIndex: 9999, webkitTransform: 'translate3d(0,0,0)'}

    if(asset.width > asset.height) fullScreenImgStyle.height = '100%'
    else fullScreenImgStyle.width = '100%'

    let display;

    const [fullScreenUrl, setFullScreenUrl] = useState()

    // Don't load full_url until modal is opened:
    useEffect(()=>{
      if(fullScreen) setFullScreenUrl(asset.full_url || asset.permalink_url || asset.small_url);
    }, [fullScreen]);

    if(attrs.pdf_url) {
        display = (
            <div style={{height:'100vh', paddingTop: 30}}>
                <PdfViewer url={attrs.pdf_url} style={{height:'100%'}}/>
            </div>
        )
    } else if(asset.three_d_url) {
        display = (
            <model-viewer
                src={asset.three_d_url}
                ar
                poster="shared-assets/models/NeilArmstrong.webp"
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
                generate-schema
                style={{width:'100%', height:'100%', background: 'white'}}
            />
        )
    } else {
        display = (
            <>
                {(asset.type === 'Image' || asset.type === 'Font') ? (
                    <div {...swipeHandlers}>
                        <img src={fullScreenUrl} style={fullScreenImgStyle} onClick={openViewer}/>
                    </div>
                ) : (
                    <>
                        {attrs.content_preview?.length ? (
                            <div style={{padding:'1em', width: '50%', marginLeft:'auto', marginRight:'auto', height:'100%', backgroundColor:'white', textAlign:'left', overflow:'scroll'}}>
                                <AssetTextContent asset={asset}/>
                            </div>
                        ) : (
                            <AssetPlayer asset={asset}/>
                        )}
                    </>
                )}
            </>
        )
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        paddingContentHorizontal: 0,
                        paddingContentHorizontalLG: 0,
                        paddingContentVertical: 0,
                        paddingContentVerticalLG: 0,
                        paddingMD: 0,
                        padding: 0,
                        marginMD: 0
                    }
                }
            }}
        >
            <Modal
                open={fullScreen}
                width={()=> '100%'}
                onCancel={toggleFullScreen}
                style={{top:0, left:0, margin:0, padding:0, width: '100vw', maxWidth:'100%', zIndex: 10_000}}
                bodyStyle={{padding:0, height:'100vh', width:'100vw', backgroundColor:'#000', textAlign: 'center', maxWidth:'100%'}}
                footer={null}
                closeIcon={<></>}
            >
                <div style={{position:'absolute', bottom: 0, left: '1em', zIndex: 10000, display:'flex', alignItems: 'center'}} id={'fullscreen-modal'}>
                    {infoDisplay === 'rating' && ability.can('view_details', 'Asset') && (
                        <Rate
                            value={attrs.rating}
                            onChange={onChange('rating')}
                            disabled={!asset.editable}
                            style={{fontSize:30, color: '#fff'}}
                            character={({index, value}) => {
                                return value >= index + 1 ? <StarFilled style={dropShadow}/> : <StarOutlined style={dropShadow}/>
                            }}
                        />
                    )}

                    {infoDisplay === 'filename' && (
                        <div style={{fontSize:20, color: '#fff', ...dropShadow}}>
                            {asset.filename}
                        </div>
                    )}

                    {infoDisplay !== 'none' && currentWorkflowStep && (
                        <div style={{marginLeft: '1em', paddingTop: 5}} onClick={e => e.stopPropagation()}>
                            <AssetGroupWorkflows
                                iconOnly
                                largeIcon
                                step={currentWorkflowStep}
                                asset={asset}
                                assetGroup={currentAssetGroup}
                            />
                        </div>
                    )}
                </div>
                <div style={{position:'absolute', top: 0, right: 0, zIndex:10000}}>
                    <Button icon={<FullscreenExitOutlined/>} onClick={toggleFullScreen} type={'text'} style={{color:'#fff'}}/>
                </div>
                {display}
            </Modal>
        </ConfigProvider>
    );
}

import { Worker, Viewer, LocalizationMap } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// https://react-pdf-viewer.dev/localizations/
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

const PdfViewer = ({url, style={}})=>{
    const {i18n} = useTranslation();

    const transform = slot => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        RotateBackwardMenuItem: () => <></>,
        RotateForwardMenuItem: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    })
    const renderToolbar = Toolbar => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const [height, setHeight] = useStorageState(sessionStorage, `pdfViewerHeight`, 750);

    const onDrag = (e)=>{
        setHeight(e.clientY)
    }

    const onDragStop = (e)=>{
        setHeight(e.clientY)
    }

    let locale;
    switch(i18n.language) {
        case 'fr':
            locale = fr_FR
    }

    return (
        <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height,
                        ...style
                    }}
                >
                    <Viewer
                        fileUrl={url}
                        plugins={[defaultLayoutPluginInstance]}
                        localization={locale}
                        isEvalSupported={false}
                    />
                </div>
            </Worker>

            <Draggable axis="y" onDrag={onDrag} onStop={onDragStop} defaultPosition={{y:0}}>
                <div style={{ position: 'fixed', width: '100%', height:'3px', cursor:'row-resize', zIndex: 9999 }}/>
            </Draggable>
        </>
    )
}

const AssetViewer = ({disableContextMenu})=> {
    const {asset, attrs, onPlayerReady, swipeHandlers, ref, isHorizontal, assetDetailsLoaded} = useContext(AssetModalContext)
    const openViewer = useAssetViewer({asset, ref});
    console.log('AssetViewer',asset)

    const [loadError, setLoadError] = useState()
    const onImgLoadError = ()=> {
        setLoadError(true)
    }

    if(asset.aasm_state === 'uploading' || asset.aasm_state === 'processing') {
        return (
            <div style={{display:'block', margin:'auto', marginRight:'auto', width: 150, padding:'1em'}}>
                <UploadProgress asset={asset} width={150}/>
            </div>
        )
    }

    if(attrs.has_pdf_url) {
        return (
            <Skeleton loading={!attrs.pdf_url} active>
                <PdfViewer url={attrs.pdf_url} style={{paddingTop: isHorizontal ? 0 : 50}}/>
            </Skeleton>
        )
    }

    if(asset.three_d_url) {
        return (
            <model-viewer
                src={asset.three_d_url}
                ar
                poster="shared-assets/models/NeilArmstrong.webp"
                shadow-intensity="1"
                camera-controls
                touch-action="pan-y"
                generate-schema
                style={{width:'100%', minHeight:'75vh', background: 'white'}}
            />
        )
    }

    return (
        <>
            {!loadError && (asset.permalink_url || asset.small_url) ? (
                <>
                    {assetDetailsLoaded && <AssetPlayer asset={asset} cb={onPlayerReady}/>}

                    <>
                        {(asset.preview_type === 'Image' || asset.preview_type === 'Font' || (!['Audio', 'Video'].includes(asset.preview_type) && asset.preview_image_url)) && (
                            <div {...swipeHandlers}>
                                <AssetContextMenu asset={asset} enabled={!disableContextMenu}>
                                    <img
                                        data-full-url={asset.full_url}
                                        id='asset-modal-img'
                                        src={asset.permalink_url || asset.small_url}
                                        style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', cursor: 'zoom-in', maxWidth: '100%', maxHeight: '85vh', backgroundImage: asset.ext == 'png' && `url(${ImageUrls.checkerImage})`, backgroundSize: 15}}
                                        onError={onImgLoadError}
                                        onClick={openViewer}
                                    />
                                </AssetContextMenu>
                            </div>
                        )}
                    </>
                </>
            ) : (
                <>
                    {!!attrs.content_preview?.length ? (
                        <pre style={{whiteSpace: 'pre-wrap', textOverflow: 'ellipsis', padding: '1em', width: '50%', marginLeft: 'auto', marginRight: 'auto', background: 'white'}}>
                            {attrs.content_preview}
                        </pre>
                    ) : (
                        <div style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:'1em'}}>
                            <div style={{width:150}}>
                                <FileIcon extension={asset.ext} {...defaultStyles[asset.ext]} radius={2}/>
                                <br/>
                                {asset.filename}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export {AssetViewer}

const AssetUnsubmittedNote = ()=> {
    const {t} = useTranslation();
    const showUnsubmittedNote = useShowUnsubmittedNote()
    const {asset, handleCancel} = useContext(AssetModalContext)

    if(showUnsubmittedNote()) {
        return (
            <Alert
                message={
                    <>
                        {t('not-submitted','Not Submitted')}:&nbsp;&nbsp;
                        {asset.upload && (
                            <OrgNavLink
                                to={`/upload/${asset.upload.guid}`}
                                onClick={() => {
                                    handleCancel();
                                }}
                                style={{color:'black'}}
                            >
                                <CloudUploadOutlined/> {asset.upload.name || t('view-upload','View Upload')}
                            </OrgNavLink>
                        )}
                    </>
                }
                type="error" showIcon
                icon={<WarningTwoTone twoToneColor={'red'}/>}
            />
        )
    } else {
        return <></>
    }
}

const useShowUnsubmittedNote = ()=> {
    const {asset} = useContext(AssetModalContext)
    const ability = useAbility(AbilityContext);

    return ()=> {
        return !asset.submitted && ability.can('view_details', 'Asset')
    }
}

const AssetExtra = ()=> {
    const {t} = useTranslation();
    const {asset, toggleFullScreen, player, isHorizontal, currentAssetCursor, assetLoading} = useContext(AssetModalContext)

    const currentOrg = useCurrentOrg()

    return (
        <Space>
            {assetLoading && <span id={'asset-loading'}><LoadingOutlined/></span>}

            {asset.max_block_level == 1 && (
                <Tooltip title={t('tooltip-download-blocked', 'Download Blocked')}>
                    <BlockDownload style={{filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))', color: Colors.red}}/>
                </Tooltip>
            )}

            {asset.max_block_level == 2 && (
                <Tooltip title={t('tooltip-view-and-download-blocked', 'View and Download Blocked')}>
                    <BlockView style={{filter: 'drop-shadow(1px 1px 1px rgb(255 255 255))', color: Colors.red}}/>
                    &nbsp; {/* Unclear why this is needed to trigger Tooltip */}
                </Tooltip>
            )}

            <AssetUnsubmittedNote/>

            <Tooltip title={`${t('tooltip-fullscreen-rating-view','Fullscreen Rating View')} (F)`}>
                <Button icon={<FullscreenOutlined/>} onClick={toggleFullScreen}>{isBrowser() && t('button-fullscreen','Fullscreen')}</Button>
            </Tooltip>

            {(!asset.downloadable && currentOrg?.enable_access_requests) && (
                <AccessRequestButton asset={asset} size={'medium'}/>
            )}

            {asset.downloadable && (
                <>
                    {asset.type === 'Image' && <PinturaButton asset={asset}/>}
                    {asset.type === 'Video' && <CutVideoButton asset={asset} player={player}/>}

                    <DownloadButton asset={asset} size={'medium'}/>
                </>
            )}

            {asset.editable && <AssetSettingsMenu/>}

            {isHorizontal && (
                <>
                    <div style={{position: 'absolute', left: 10, paddingTop: 30}}>
                        <a onClick={()=> currentAssetCursor('prev')} style={{color:'white'}}>
                            <LeftIcon style={{fontSize:40, cursor:'pointer'}} />
                        </a>
                    </div>

                    <div style={{position: 'absolute', right: 10, paddingTop: 30}}>
                        <a onClick={()=> currentAssetCursor('next')} style={{color:'white'}}>
                            <RightIcon style={{fontSize:40, cursor:'pointer'}} />
                        </a>
                    </div>
                </>
            )}
        </Space>
    )
}

const AssetInfo = ({children})=>{
    const {t} = useTranslation();
    const {asset, attrs, setAttrs, isMetaEdit, isHorizontal, toggleModalMetaMode, toggleModalViewMode,
        player, vttLoaded, setVisible, handleCancel, getAssetDetails, assetLoading, onChange, ref} = useContext(AssetModalContext)

    const ability = useAbility(AbilityContext);
    const currentUser = useCurrentUser()
    const {currentUpload} = useAssetGroupState()
    const currentOrg = useCurrentOrg()

    const defaultTab = sessionStorage.getItem('defaultAssetTab')
    const onTabChange = (key)=> {
        sessionStorage.setItem('defaultAssetTab', key)
    }

    const [activeKeys, setActiveKeys] = useStorageState(sessionStorage, `activeAssetModalCollapseKeys`, ['quick-info']);

    const setCurrentAsset = useSetCurrentAsset()

    // ----------------------------------------------------------------------------
    // Contract Handling
    // ----------------------------------------------------------------------------
    const onChangeLegal = type => {
        return e => {
            const checked = e.target.checked

            const data = {}
            data[type == 'contract' ? 'contract' : 'release'] = checked

            api.put(`/api/assets/${asset.id}`, {asset: data}).then(res => {
                assetsDispatch({type:'updateAsset', asset: res.data})
                editAssetDispatch({type:'increment'})
                message.success(`${t('asset','Asset')} ${t(type,type)} ${checked ? t('enabled','Enabled') : t('disabled','Disabled')}.`)
            })
        }
    }

    const navigate = useNavigate()

    const getPath = useOrgPath()

    const viewContractAssets = ()=> {
        navigate(getPath(`/explore?contract_asset_id[]=${asset.id}`))
        setVisible(false)
    }

    const viewReleasedAssets = ()=> {
        navigate(getPath(`/explore?release_ids[]=${asset.id}`))
        setVisible(false)
    }

    const assetsDispatch = useAssetsDispatch()
    const editAssetDispatch = useEditAssetsDispatch()
    // ------------------
    // Selection Controls
    // ------------------
    const {selectedAssetIds} = useSelectedAssetsState()
    const selectedAssetDispatch = useSelectedAssetsDispatch();

    const selected = selectedAssetIds?.indexOf(asset?.id) != -1

    const onClickSelect = (e)=>{
        ref.current.focus()
        selectedAssetDispatch({type: 'toggleAsset', id: asset.id})
    }

    const onRemoveAssetGroup = (c) => {
        api.post('/api/assets/remove_group', {ids: [asset.id], asset_group_id: c.id}).then(res => {
            message.success(t('message-removed-from-container',`Removed from {{name}}`, {name:c.name}))
            getAssetDetails()
        })
    }

    const loadAssetFromGuid = useLoadAssetFromGuid()

    const showUnsubmittedNote = useShowUnsubmittedNote()
    const showMetaField = useShowMetaField()

    // --------------------
    // Editable Meta Fields
    // --------------------

    const renderMetaItem = (f, props={}) => {
        if(f == null) return <React.Fragment key={f}/>

        const {extra, actions} = props

        const meta = MetaFields[f]

        if(!meta || !showMetaField(f)) return <React.Fragment key={f}/>

        return (
            <Descriptions.Item label={t(meta.name, meta.name)} key={f}>
                <Flex justify={'space-between'}>
                    {<EditableMetaField f={f} extra={extra}/>}
                    {actions}
                </Flex>
            </Descriptions.Item>
        )
    }


    const [savingEvent, setSavingEvent] = useState()
    const saveEvent = ()=>{
        setSavingEvent(true)
        api.post(`/api/assets/${asset.id}/save_event`).then(res => {
            message.success(t('message-event-info-saved','Event info saved to Event Tag!'))
            assetsDispatch({type:'updateAsset', asset: res.data})
            editAssetDispatch({type:'increment'})
            setSavingEvent(false)
        })
    }

    const renderUnsavedEvent = ()=> {
        return (
            <>
                <Popover
                    trigger={['click','hover']}
                    overlayStyle={{width: isMobile() ? '100%' : 500}}
                    placement={'right'}
                    title={
                        <>
                            <CalendarOutlined/> {t('add-event-tag','Add Event Tag')}
                        </>
                    }
                    content={
                        <VerticalSpace>
                            {isMetaEdit && (
                                <EditableEventTag placeholder={t('placeholder-select-event','Select Event...')}/>
                            )}

                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('name','Name')}>
                                    <EditableMetaField f={'event'}/>
                                </Descriptions.Item>
                            </Descriptions>

                            {isMetaEdit && (
                                <Popconfirm title={t('confirm-save-event','Save Event?')} onConfirm={saveEvent}>
                                    <Button icon={<SaveOutlined/>} loading={savingEvent} type={'primary'}>
                                        {t('button-save-event','Save Event')}
                                    </Button>
                                    <HelpPopover code={'save-to-event-tag'}></HelpPopover>
                                </Popconfirm>
                            )}
                        </VerticalSpace>
                    }
                >
                    <Tag id={'unsaved-event-tag'}>{asset.event || t('none','None')}</Tag>
                </Popover>
            </>
        )
    }

    const [clearingNsfw, setClearingNsfw] = useState()

    const clearNsfw = ()=>{
        setClearingNsfw(true)
        api.put(`/api/assets/${asset.id}/clear_nsfw`).then(res => {
            setClearingNsfw(false)
            assetsDispatch({type:'updateAsset', asset: {...asset, nsfw_detected: false}})
            message.success(t('message-nsfw-cleared','NSFW Cleared!'))
        })
    }

    const locationField = (field, assetField=null) => {
        if(!assetField) assetField = field

        if(asset.event_tag && asset.event_tag[`location_${field}`]?.length) {
            return (
                <Descriptions.Item label={t(field.replace('_','-'),field.replace('_',' ').toProperCase())} key={field}>
                    <Space>
                        <Tooltip title={t('from-event-tag','From Event Tag')}><TagOutlined/></Tooltip>
                        {asset.event_tag[`location_${field}`]}
                    </Space>
                </Descriptions.Item>
            )
        } else {
            return renderMetaItem(assetField)
        }
    }

    return (
        <Tabs
            style={{padding: '0 1em 1em'}}
            defaultActiveKey={defaultTab}
            onChange={onTabChange}
            tabBarExtraContent={
                <>
                    {isBrowser() && !isHorizontal && <AssetExtra/>}
                    {isBrowser() && (
                        <Space>
                            <Tooltip title={`${isHorizontal ? t('tooltip-set-layout-vertical', 'Set Layout Vertical') : t('tooltip-set-layout-horizontal', 'Set Layout Horizontal')} (v)`}>
                                <Button
                                    onClick={()=> toggleModalViewMode()}
                                    icon={isHorizontal ? <PicCenterOutlined /> : <PicLeftOutlined />}
                                    style={{marginRight: isHorizontal && 35, marginLeft:'.5em'}}
                                    type={'text'}
                                    aria-label={t('toggle-view-mode','Toggle View Mode')}
                                />
                            </Tooltip>
                        </Space>
                    )}
                </>
            }
        >
            <Tabs.TabPane tab={<><InfoOutlined/> {t('details','Details')}</>} key={'details'}>
                {children}

                <div style={{margin:'1em 0 1.5em 0'}}>
                    <Row gutter={[16,16]}>
                        <Col lg={isHorizontal ? 24 : 12} xs={24} style={{display:'flex'}}>
                            <div style={{flex:'none'}}>
                                <Tooltip title={selected ? t('tooltip-remove-from-selected-assets', 'Remove From Selected Assets') : t('tooltip-add-to-selected-assets', 'Add To Selected Assets')}>
                                    <Checkbox checked={selected} onClick={onClickSelect} style={{marginRight:'1em'}} name={'asset-selected-checkbox'}/>
                                </Tooltip>
                            </div>
                            <div style={{flex:'auto'}}>
                                <Typography.Title level={5} copyable={{tooltips:t('tooltip-copy-filename','Copy Filename')}} style={{display:'inline'}} editable={asset.editable && {onChange: onChange('filename'), tooltip:t('tooltip-edit-filename','Edit Filename')}}>
                                    {attrs.filename}
                                </Typography.Title>
                            </div>
                        </Col>

                        <Col lg={isHorizontal ? 24 : 12} xs={24}>
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col flex={1}>
                                            <Rate value={attrs.rating || 0} onChange={onChange('rating')} disabled={!asset.editable} style={{fontSize:16}}/>
                                            <AssetVoteButtons asset={asset} style={{marginLeft:'1em'}}/>
                                        </Col>
                                        <Col flex={1} style={{textAlign:'right'}}>
                                            <Space>
                                                {(asset.editable || asset.description_editable || asset.taggable) && (
                                                    <Tooltip title={`${t('tooltip-set-meta-mode','Set Meta Mode')}: ${isMetaEdit ? t('browse','Browse') : t('edit','Edit')} (e)`}>
                                                        <Switch
                                                            id={'toggle-meta-mode'}
                                                            checkedChildren={<><EditOutlined/> {t('edit-mode','Edit Mode')}</>}
                                                            unCheckedChildren={<>{t('browse-mode','Browse Mode')} <EyeOutlined/></>}
                                                            onClick={toggleModalMetaMode}
                                                            checked={isMetaEdit}
                                                            aria-label={t('toggle-edit-mode','Toggle Edit Mode')}
                                                        />
                                                    </Tooltip>
                                                )}

                                                <Typography.Text code copyable={{text: asset.guid, tooltips:t('tooltip-copy-file-id','Copy File ID')}}>{asset.short_guid}</Typography.Text>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {asset.nsfw_detected && ability.can('manage', 'Asset') && (
                    <Alert
                        type={'warning'}
                        message={t('nsfw-detected','NSFW Detected')}
                        showIcon
                        style={{margin:'1em 0'}}
                        description={
                            <>
                                {asset.moderation_labels?.map((label,i) => (
                                    <Tag key={i} color={'red'}>{label.name} <GreyBadge count={`${Math.round(label.confidence)}%`}/></Tag>
                                ))}
                            </>
                        }
                        action={
                            <>
                                <Popconfirm title={t('tooltip-clear-nsfw','Clear NSFW?')} onConfirm={clearNsfw} >
                                    <Button loading={clearingNsfw} icon={<CheckOutlined/>}>{t('button-clear','Clear')}</Button>
                                </Popconfirm>
                            </>
                        }
                    />
                )}

                {asset.trashed_at && (
                    <Alert
                        type={'error'}
                        style={{margin:'1em 0'}}
                        message={
                            <>
                                <DeleteOutlined/> {t('trashed', 'Trashed')} <TimeAgo date={asset.trashed_at}/> {t('by', 'by')} <User user={asset.trashed_by}/>
                            </>
                        }/>
                )}

                {asset.checked_out_at && (
                    <Alert
                        style={{margin:'1em 0'}}
                        message={
                            <Skeleton loading={!asset.checked_out_by} active paragraph={false}>
                                <LockOutlined/> Checked out <TimeAgo date={asset.checked_out_at}/> by <User user={asset.checked_out_by}/> via {asset.checkout_integration}
                            </Skeleton>
                        }
                    />
                )}

                <Collapse
                    accordion
                    activeKey={activeKeys}
                    onChange={keys => setActiveKeys(keys)}
                >
                    <Collapse.Panel
                        key={'quick-info'}
                        accordion
                        header={
                            <>
                                <InfoCircleOutlined/> {t('quick-info','Quick Info')}
                            </>
                        }
                    >
                        <QuickInfo />
                    </Collapse.Panel>

                    {(asset.type == 'Text' || asset.content_preview) && (
                        <Collapse.Panel key={'content'} header={<><FileTextOutlined/> {t('text-content','Text Content')}</>}>
                            <AssetTextContent asset={asset} field={'content'} onChange={data => setAttrs({...attrs, ...data})}/>
                        </Collapse.Panel>
                    )}

                    {asset.has_ocr_content && (
                        <Collapse.Panel key={'ocr_content'} header={<><RobotOutlined/> {t('ocr-text-content','OCR Content')}</>}>
                            <AssetTextContent asset={asset} field={'ocr_content'} onChange={data => setAttrs({...attrs, ...data})}/>
                        </Collapse.Panel>
                    )}

                    {(asset.type === 'Video' || asset.type === 'Audio') && (
                        <Collapse.Panel key={'transcript'} header={<><CommentOutlined /> <span>{t('transcript','Transcript')}</span></>}>
                            {asset.transcription_job_status === 'PENDING' ? (
                                <>
                                    <LoadingOutlined/> {t('transcribing','Transcribing')}...
                                </>
                            ) : (
                                <>
                                    {player && (
                                        <Transcript asset={asset} player={player} vttLoaded={vttLoaded}/>
                                    )}
                                </>
                            )}

                        </Collapse.Panel>
                    )}

                    <Collapse.Panel key={'describe'} header={<><ProfileOutlined/> {t('describe', 'Describe')}</>}>
                        <Row gutter={[16, 16]}>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>
                                <Space direction={'vertical'} style={{width: '100%'}} size={'middle'}>
                                    {asset.editable && (
                                        <>
                                            <OptimizationRequestsCard/>

                                            <RestrictedAccessCard/>
                                        </>
                                    )}

                                    <Descriptions
                                        bordered
                                        size='small'
                                        column={1}
                                        labelStyle={{width:200}}
                                    >
                                        {asset.expired && (
                                            <Descriptions.Item label={t('rights-status', 'Rights Status')}>
                                                <Alert
                                                    message={
                                                        <>{t('rights-expired', 'Rights expired')} <TimeAgo date={asset.expires_at}/></>
                                                    }
                                                    type="error" showIcon
                                                    icon={<ClockCircleTwoTone twoToneColor={'red'}/>}
                                                />
                                            </Descriptions.Item>
                                        )}

                                        {showUnsubmittedNote() && (
                                            <Descriptions.Item label={t('status', 'Status')}>
                                                <AssetUnsubmittedNote/>
                                            </Descriptions.Item>
                                        )}

                                        {asset.editable && asset.processing_error && (
                                            <Descriptions.Item label={t('previews-processing-error', 'Previews Processing Error')}>
                                                {JSON.stringify(asset.processing_error)}
                                            </Descriptions.Item>
                                        )}

                                        {(isMetaEdit || asset.description?.length) && (
                                            <Descriptions.Item label={t('description', 'Description')}>
                                                <EditableTextArea
                                                    id={asset.id}
                                                    name={'Description'}
                                                    value={attrs.description}
                                                    onChange={onChange('description')}
                                                    editable={asset.editable || asset.description_editable}
                                                    style={{marginBottom: 0}}
                                                    empty={<Typography.Text type={'secondary'}><em>No Description.</em></Typography.Text>}
                                                />
                                            </Descriptions.Item>
                                        )}

                                        {['description_writer', 'title', 'headline', 'captured_at'].map(renderMetaItem)}

                                        <Descriptions.Item label={t('alt-text', 'Alternative Text')}>
                                            <AltText/>
                                        </Descriptions.Item>

                                    </Descriptions>

                                    <MapCard showLocation/>

                                    <Descriptions bordered size='small' column={1} labelStyle={{width:200}}>
                                        {locationField('country_code')}
                                        {locationField('country')}
                                        {locationField('state')}
                                        {locationField('city')}
                                        {locationField('name', 'sublocation')}
                                    </Descriptions>

                                    <Descriptions bordered size='small' column={1} labelStyle={{width:200}}>
                                        {(isMetaEdit || (asset.event_tag || !!asset.event?.length)) && (
                                            <Descriptions.Item label={<><CalendarOutlined/> {t('event-shown', 'Event Shown')}</>}>
                                                <VerticalSpace>
                                                    {asset.event_tag?.id ? (
                                                        <EditableEventTag/>
                                                    ) : renderUnsavedEvent()}
                                                </VerticalSpace>
                                            </Descriptions.Item>
                                        )}

                                        {['iptc_event_id'].map(renderMetaItem)}
                                    </Descriptions>
                                </Space>
                            </Col>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>
                                <Skeleton active loading={assetLoading} style={{width: '100%'}}>
                                    <Space direction={'vertical'} style={{width: '100%'}} size={'middle'}>
                                        <KeywordsCard autoTags/>
                                        <HasPeople/>
                                        <AssetCustomMetaFields/>
                                        <StatsCard/>

                                        <Descriptions bordered size='small' column={1} labelStyle={{width:200}}>
                                            <Descriptions.Item label={t('person-in-image', 'Person In Image')} key={'person_in_image'}>
                                                <TagSelect asset={asset} subType={'person'}/>
                                            </Descriptions.Item>

                                            {['organization_in_image_name', null, 'extended_description', null,
                                                'iptc_job_id', 'intellectual_genre', 'scene', 'subject_code'
                                            ].map(renderMetaItem)}
                                        </Descriptions>

                                        <LinksCard/>
                                    </Space>
                                </Skeleton>
                            </Col>
                        </Row>
                    </Collapse.Panel>

                    {asset.face_indexing_state !== 'na' && currentOrg.enable_face_tagging && (
                        <Collapse.Panel key={'face-tags'} header={<><UserOutlined/> {t('faces', 'Faces')}</>}>
                            <AssetFaceTaggings/>
                        </Collapse.Panel>
                    )}

                    {currentOrg.enable_artwork_or_object && (
                        <Collapse.Panel key={'art-shown'} header={<><ArtworkIcon/> {t('artwork-or-object-shown', 'Artwork or Object Shown')}</>}>
                            <EditableMetaStruct field={'artwork_or_object'}/>
                        </Collapse.Panel>
                    )}

                    {currentOrg.enable_product_info && (
                        <Collapse.Panel key={'product-info'} header={<><ShoppingCartOutlined/> {t('product-info', 'Product Info')}</>}>
                            <Descriptions bordered size='small' column={1}>
                                {['product_in_image', 'sku_name'].map(renderMetaItem)}
                            </Descriptions>
                        </Collapse.Panel>
                    )}

                    <Collapse.Panel key='ownership-and-rights' header={<><RightsIcon/> {t('ownership-and-rights', 'Ownership and Rights')}</>}>
                        <Can I={'view_details'} a={'Asset'}>
                            <Skeleton active loading={assetLoading}>
                                <Space direction={'vertical'} style={{width: '100%'}}>
                                    <Card title={<strong><RightsIcon/> {t('rights-package', 'Rights Package')} {currentUpload && !asset.rights_package_met && <RequiredTag/>}</strong>} size={'small'}>
                                        {asset.rights_editable && (
                                            <>
                                                <RightsPackageSelect asset={{...asset, ...attrs}} onChange={onChange('rights_package_id', 'Rights Package')}/>

                                                {(asset.rights_package?.long_form_type == 'allow_contract_upload' || asset.rights_manageable) && (
                                                    <div>
                                                        <Checkbox
                                                            checked={asset.contract}
                                                            onChange={onChangeLegal('contract')}
                                                            style={{marginTop: '1em'}}
                                                        >
                                                            <small>
                                                                {t('this-is-a-contract', 'This is a Contract.')} <HelpPopover code={'contract-asset'}/>
                                                            </small>
                                                        </Checkbox>

                                                        {asset.contract && (
                                                            <Button onClick={viewContractAssets} size={'small'}><EyeOutlined/> {t('button-view-assets','View Assets')} <Tag>{asset.contract_assets_count}</Tag></Button>
                                                        )}
                                                    </div>
                                                )}

                                                {(asset.rights_package?.allow_model_release_upload || asset.rights_manageable) && (
                                                    <div>
                                                        <Checkbox
                                                            checked={asset.release}
                                                            onChange={onChangeLegal('release')}
                                                            style={{marginTop:'1em'}}
                                                        >
                                                            <small>
                                                                {t('this-is-a-release','This is a Release.')} <HelpPopover code={'release-asset'}/>
                                                            </small>
                                                        </Checkbox>

                                                        {asset.release && (
                                                            <Button onClick={viewReleasedAssets} size={'small'}><EyeOutlined/> {t('button-view-assets','View Assets')} <Tag>{asset.released_assets_count}</Tag></Button>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        ) || (
                                            <>
                                                {asset.rights_package_summary || t('none','None')}
                                                <br/>
                                                <small>{asset.rights_package_description}</small>
                                            </>
                                        )}
                                    </Card>

                                    <Descriptions bordered size='small' column={1}>
                                        <Descriptions.Item label={null} span={2} key={'null-1'}>{null}</Descriptions.Item>

                                        {(isMetaEdit || (!!attrs.usage_terms?.length || asset.rights_package?.meta_field_text?.length)) && (
                                            <Descriptions.Item label={t('usage-terms','Usage Terms')}>
                                                {asset.rights_package?.meta_field_text?.length ? (
                                                    <>{asset.rights_package?.meta_field_text}</>
                                                ) : (
                                                    <Typography.Paragraph editable={asset.editable && {onChange: onChange('usage_terms', t('usage-terms','Usage Terms'))}} style={{marginBottom:0}}>
                                                        {!!attrs.usage_terms?.length ? attrs.usage_terms : <Typography.Text type={'secondary'}><em>{t('rights-not-specified','Rights Not Specified.')}</em></Typography.Text>}
                                                    </Typography.Paragraph>
                                                )}
                                            </Descriptions.Item>
                                        )}

                                        {(isMetaEdit || asset.rights_status_name) && (
                                            <Descriptions.Item label={t('rights-status','Rights Status')}>
                                                {asset.rights_status_name && (!isMetaEdit || asset.rights_package) && (
                                                    <>
                                                        <RightsIcon/> {asset.rights_status_name}
                                                        <br/>
                                                        <small>{asset.rights_status_description}</small>
                                                    </>
                                                )}

                                                {asset.editable && isMetaEdit && !asset.rights_package && (
                                                    <Select
                                                        allowClear
                                                        value={asset.rights_status}
                                                        placeholder={t('placeholder-select-one','Select One...')}
                                                        onChange={onChange('rights_status', t('rights-status','Rights Status'))}
                                                        style={{width:'100%'}}
                                                    >
                                                        {Object.keys(RightsStatusOptions).map((key) => {
                                                            const opt = RightsStatusOptions[key]
                                                            return (
                                                                <Select.Option value={key} key={key}>
                                                                    <Tooltip title={opt.description} placement={'left'}>
                                                                        {opt.name}
                                                                        <br/>
                                                                        <small>{opt.description}</small>
                                                                    </Tooltip>
                                                                </Select.Option>
                                                            )
                                                        })}
                                                    </Select>
                                                )}
                                            </Descriptions.Item>
                                        )}

                                        {['credit_line', 'instructions', 'iptc_rights', 'web_statement', 'model_release_status', 'property_release_status'].map(renderMetaItem)}

                                        {!asset.contract && !!asset.rights_package?.contracts?.length && (
                                            <Descriptions.Item label={t('contract','Contract')} key={'contract'}>
                                                {asset.rights_package?.contracts?.map(contract => (
                                                    <p key={contract.guid}>
                                                        {/*<Tooltip title={'Download Contract'}>*/}
                                                        {/*    <a onClick={() => downloadContract(contract)}>*/}
                                                        {/*        <FileTextOutlined/> {contract.filename}*/}
                                                        {/*    </a>*/}
                                                        {/*</Tooltip>*/}

                                                        <Tooltip title={t('tooltip-view-contract-asset','View Contract Asset')}>
                                                            <Button type='text' icon={<EyeFilled/>} onClick={()=> {
                                                                loadAssetFromGuid(contract.guid)
                                                            }}/>
                                                        </Tooltip>
                                                    </p>
                                                ))}
                                            </Descriptions.Item>
                                        )}

                                        {asset.contract_asset && (
                                            <Descriptions.Item label={t('contract','Contract')}>
                                                <Tooltip title={t('tooltip-view-contract-asset','View Contract Asset')}>
                                                    <AssetLink asset={asset.contract_asset}/>
                                                </Tooltip>
                                            </Descriptions.Item>
                                        )}

                                        {asset.rights_package?.contract_asset && (
                                            <Descriptions.Item label={t('rights-package-contract','Rights Package Contract')}>
                                                <Tooltip title={t('tooltip-view-contract','View Contract')}>
                                                    <AssetLink asset={asset.rights_package.contract_asset}/>
                                                </Tooltip>
                                            </Descriptions.Item>
                                        )}

                                        {asset.released && (
                                            <Descriptions.Item label={t('released-status','Released Status')}>
                                                {asset.released ? (
                                                    <Typography.Text type={'success'}><CheckCircleOutlined/> {t('released','Released')}</Typography.Text>
                                                ) : (
                                                    <><CloseCircleOutlined/> {t('unreleased','Unreleased')}</>
                                                )}
                                            </Descriptions.Item>
                                        )}

                                        {!!asset.releases?.length && (
                                            <Descriptions.Item label={t('releases','Releases')}>
                                                {asset.releases?.map(release => (
                                                    <div key={release.guid}>
                                                        <Tooltip title={t('tooltip-view-release-asset','View Release Asset')}>
                                                            <AssetLink asset={release}/>
                                                        </Tooltip>
                                                        <br/>
                                                    </div>
                                                ))}
                                            </Descriptions.Item>
                                        )}
                                    </Descriptions>

                                    <Collapse>
                                        <Collapse.Panel key={'long-form'} header={<>{t('long-form','Long Form')}</>}>
                                            <Descriptions bordered size='small' column={1}>
                                                {['authors_position', null,
                                                    'iptc_source', 'copyright_owner', 'iptc_dig_image_guid', 'image_supplier', 'iptc_image_supplier_image_id', 'licensor', null,
                                                    'addl_model_info', 'iptc_model_release_id', 'model_age', 'minor_model_age_disclosure', 'iptc_property_release_id'
                                                ].map(renderMetaItem)}
                                            </Descriptions>
                                        </Collapse.Panel>
                                    </Collapse>
                                </Space>
                            </Skeleton>
                        </Can>
                    </Collapse.Panel>
                    <Collapse.Panel key={'file-info'} header={<><FileOutlined /> {t('file-info','File Info')}</>}>
                        <Row gutter={[16,16]}>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>

                                <Descriptions bordered size='small' column={1}>
                                    <Descriptions.Item label={t('file-size','File Size')}>{asset.file_size && filesize(asset.file_size)}</Descriptions.Item>

                                    {asset.width && (
                                        <Descriptions.Item label={t('resolution','Resolution')}>{asset.width}x{asset.height}</Descriptions.Item>
                                    )}

                                    {asset.duration && (
                                        <Descriptions.Item label={t('duration','Duration')}>{moment(asset.duration).format( 'mm:ss')}</Descriptions.Item>
                                    )}

                                    {asset.frame_rate && (
                                        <Descriptions.Item label={t('frame-rate','Frame Rate')}>{asset.frame_rate}</Descriptions.Item>
                                    )}
                                </Descriptions>
                            </Col>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>
                                <Descriptions bordered size='small' column={1}>
                                    {ability.can('view_details', 'Asset') && (
                                        <>
                                            <Descriptions.Item label={t('uploaded','Uploaded')}>
                                                <TimeAgo date={asset.created_at}/>
                                                <Skeleton active loading={assetLoading} paragraph={false}> by <User user={asset.user}/> </Skeleton>
                                            </Descriptions.Item>

                                            {asset.created_at != asset.updated_at && (
                                                <Descriptions.Item label={t('last-updated','Last Updated')}>
                                                    <TimeAgo date={asset.updated_at}/>
                                                </Descriptions.Item>
                                            )}

                                            {asset.upload && (
                                                <Descriptions.Item label={t('upload-session','Upload Session')}>
                                                    <Tooltip title={t('tooltip-view-all-assets-in-upload','View All Assets in Upload')}>
                                                        <OrgLink to={`/upload/${asset.upload.guid}`} onClick={handleCancel}>
                                                            {asset.upload.name || asset.upload.contribution?.name || <TimeAgo date={asset.upload.created_at}/>}
                                                        </OrgLink>
                                                    </Tooltip>
                                                    &nbsp;
                                                    {!!asset.upload.note?.length && (
                                                        <Popover title={t('tooltip-note','Note')} content={asset.upload.note}>
                                                            <CommentOutlined/>
                                                        </Popover>
                                                    )}
                                                </Descriptions.Item>
                                            )}

                                            {asset.created_via && (
                                                <Descriptions.Item label={t('created-from','Created From')}>
                                                    <Space>
                                                        {asset.created_via.toProperCase()}

                                                        {asset.created_via == 'box.com' && (
                                                            <a href={`https://app.box.com/file/${asset.created_via_id}`} target={'_blank'}>{t('view-original-file','View Original File')}</a>
                                                        )}
                                                        {asset.created_via == 'frame.io' && (
                                                            <a href={`https://app.frame.io/player/${asset.created_via_id}`} target={'_blank'}>{t('view-original-file','View Original File')}</a>
                                                        )}
                                                    </Space>
                                                    <br/>
                                                    <Typography.Text code copyable>{asset.created_via_id}</Typography.Text>
                                                </Descriptions.Item>
                                            )}

                                            {asset.frame_asset_id && (
                                                <Descriptions.Item label={t('linked-external-assets','Linked External Assets')}>
                                                    <a href={`https://app.frame.io/player/${asset.frame_asset_id}`} target={'_blank'}>{t('view-on-frame','View on Frame.io')}</a>
                                                    <br/>
                                                    <Typography.Text code copyable>{asset.frame_asset_id}</Typography.Text>
                                                </Descriptions.Item>
                                            )}
                                        </>
                                    )}
                                </Descriptions>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                    {currentUser && (
                        <Collapse.Panel key={'exif'} header={<><CameraOutlined /> {t('raw-exif','Raw Exif')}</>}>
                            <RawExif asset={asset}/>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel key={'found-in'} header={<><GraphIcon/> {t('related','Related')}</>}>
                        <Row gutter={[16,16]}>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>
                                {asset.duplicated_asset && (
                                    <Card title={t('duplicated-from-asset','Duplicated From Asset')} size={'small'} style={{marginBottom:'1em'}}>
                                        <Tooltip title={asset.duplicated_asset.guid}>
                                            <Button type={'text'} onClick={()=> setCurrentAsset(asset.duplicated_asset)} icon={<FileOutlined/>}>
                                                {asset.duplicated_asset.filename}

                                                {!!asset.duplicated_asset_end_time && (
                                                    <>
                                                        {asset.duplicated_asset_end_time}
                                                        &nbsp;({moment(asset.duplicated_asset_start_time).format( 'mm:ss')} - {moment(asset.duplicated_asset_end_time).format( 'mm:ss')})
                                                    </>
                                                )}
                                            </Button>
                                        </Tooltip>
                                    </Card>
                                )}
                                {asset.storage_folder && (
                                    <Card title={<strong><FolderOutlined/> {t('file-vault','File Vault')}</strong>} size={'small'} style={{marginBottom:'1em'}}>
                                        <OrgNavLink
                                            to={`/explore/folders/${asset.storage_folder?.slug}`}
                                            onClick={() => {
                                                handleCancel();
                                            }}
                                            style={{color:'black'}}
                                        >
                                            <StorageFolderIcon/> {asset.storage_folder?.path_names?.join(' / ')}
                                        </OrgNavLink>

                                        <AssetGroupWorkflows
                                            assetGroup={asset.storage_folder}
                                            asset={asset}
                                            onUpdate={getAssetDetails}
                                        />
                                    </Card>
                                )}

                                <Card title={<strong><AppstoreAddOutlined/> {t('collections','Collections')}</strong>} size={'small'}>
                                    <List
                                        size={'small'}
                                        dataSource={asset.collections}
                                        locale={{emptyText:t('none-yet','None yet.')}}
                                        renderItem={c => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <OrgNavLink
                                                                to={`/explore/collections/${c.slug}`}
                                                                onClick={() => {
                                                                    handleCancel();
                                                                }}
                                                                style={{color:'black'}}
                                                            >
                                                                <CollectionIcon/> {c.path_names.join(' / ')}
                                                            </OrgNavLink>

                                                            <AssetGroupAssetInfo aga={c}/>

                                                            <div style={{float:'right'}}>
                                                                <Can I={'manage'} a={'Collection'}>
                                                                    <Popconfirm
                                                                        title={t('confirm-remove','Remove?')}
                                                                        onConfirm={() => onRemoveAssetGroup(c)}
                                                                    >
                                                                        <Tooltip title={'Remove'} placement={'right'}>
                                                                            <Button danger size={'small'} icon={<DeleteOutlined/>}/>
                                                                        </Tooltip>
                                                                    </Popconfirm>
                                                                </Can>
                                                            </div>
                                                        </>
                                                    }
                                                    description={
                                                        <AssetGroupWorkflows
                                                            assetGroup={c}
                                                            asset={asset}
                                                            onUpdate={getAssetDetails}
                                                        />
                                                    }
                                                />

                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Col>
                            <Col lg={isHorizontal ? 24 : 12} xs={24}>
                                {currentUser && (
                                    <Card title={<strong><ProjectIcon/> {t('projects','Projects')}</strong>} size={'small'}>
                                        <List
                                            size={'small'}
                                            dataSource={asset.lightboxes}
                                            locale={{emptyText:t('none-yet','None yet.')}}
                                            renderItem={lb => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={
                                                            <>
                                                                <OrgNavLink
                                                                    to={`/explore/projects/${lb.slug}`}
                                                                    onClick={() => {
                                                                        handleCancel();
                                                                    }}
                                                                    style={{color:'black'}}
                                                                >
                                                                    <ProjectIcon/> {lb.path_names.join(' / ')}
                                                                </OrgNavLink>

                                                                <AssetGroupAssetInfo aga={lb}/>

                                                                <div style={{float:'right'}}>
                                                                    {lb.user.id != currentUser.id && <User user={lb.user}/>}
                                                                    {lb.editable && (
                                                                        <Popconfirm
                                                                            title={t('confirm-remove','Remove?')}
                                                                            onConfirm={() => onRemoveAssetGroup(lb)}
                                                                        >
                                                                            <Tooltip title={'Remove'} placement={'right'}>
                                                                                <Button danger size={'small'} icon={<DeleteOutlined/>}/>
                                                                            </Tooltip>
                                                                        </Popconfirm>
                                                                    )}
                                                                </div>
                                                            </>
                                                        }
                                                        description={
                                                            <AssetGroupWorkflows
                                                                assetGroup={lb}
                                                                asset={asset}
                                                                onUpdate={getAssetDetails}
                                                            />
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                )}
                            </Col>
                        </Row>
                    </Collapse.Panel>

                    {asset.editable && (
                        <Collapse.Panel header={<><ShareIcon/> {t('shares','Shares')}</>}>
                            <SharesTable asset={asset}/>
                        </Collapse.Panel>
                    )}

                    {asset.editable && (
                        <Collapse.Panel header={<><ClockCircleOutlined/> {t('log','Log')}</>} key={'log'}>
                            <AssetLog asset={asset} close={handleCancel}/>
                        </Collapse.Panel>
                    )}

                    {(asset.s3_download_url) && (
                        <Collapse.Panel header={<><ThunderboltOutlined /> Super Admin Utilities</>}>
                            <a href={asset.s3_download_url} target={'_blank'} style={{textDecoration: 'underline'}}>S3 Download Object</a>
                            <br/>
                            <a href={asset.s3_upload_url} target={'_blank'} style={{textDecoration: 'underline'}}>S3 Upload Object</a>
                        </Collapse.Panel>
                    )}
                </Collapse>


            </Tabs.TabPane>

            {asset.manageable && (
                <Tabs.TabPane tab={<><ClockCircleOutlined/> {t('versions','Versions')} <Badge count={asset.data_versions_count} style={{ backgroundColor: '#eee', color: '#333' }}/></>} key={'history'}>
                    <AssetHistory asset={asset}/>
                </Tabs.TabPane>
            )}

            {/*<Tabs.TabPane tab={<><LineChartOutlined/> Usage</>} key={'usage'}>*/}
            {/*    Usage Coming Soon...*/}
            {/*</Tabs.TabPane>*/}
        </Tabs>
    )
}

const KeywordsCard = ({autoTags})=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)
    const currentOrg = useCurrentOrg()
    const {currentUpload} = useAssetGroupState()

    return (
        <Card title={<strong><TagOutlined/> {t('keywords','Keywords')} {currentUpload && !asset.tag_suggesters_met && <RequiredTag/>}</strong>} size={'small'}>
            <VerticalSpace>
                <TagSelect asset={asset} upload={currentUpload}/>

                {autoTags && currentOrg?.enable_ai && (
                    <Can I={'view_auto_tags'} a={'Asset'}>
                        <Collapse>
                            <Collapse.Panel key={'auto_tags'} header={<><CloudServerOutlined/> {t('auto-tags','Auto Tags')} <GreyBadge count={asset.auto_tags_count}/> </>}>
                                <AutoTags/>
                            </Collapse.Panel>
                        </Collapse>
                    </Can>
                )}
            </VerticalSpace>
        </Card>

    )
}

const StatsCard = ()=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    return (
        <>
            {asset.views_count && (
                <Card size={'small'} title={<><LineChartOutlined /> {t('stats','Stats')}</>}>
                    <Flex gap={4}>
                        <Card size={'small'} style={{flex:'auto'}}>
                            <Statistic
                                title={
                                    <>
                                        <EyeOutlined/> {t('views','Views')}
                                    </>
                                }
                                value={asset.views_count}
                            />
                        </Card>
                        <Card size={'small'} style={{flex:'auto'}}>
                            <Statistic
                                title={
                                    <>
                                        <CloudDownloadOutlined/> {t('downloads','Downloads')}
                                    </>
                                }
                                value={asset.downloads_count}
                            />
                        </Card>
                        <Card size={'small'} style={{flex:'auto'}}>
                            <Statistic
                                title={
                                    <>
                                        <ThumbsUpIcon/> {t('likes','Likes')}
                                    </>
                                }
                                value={asset.up_votes_count}
                            />
                        </Card>
                        {/*<Card size={'small'} style={{flex:'auto'}}>*/}
                        {/*    <Statistic*/}
                        {/*        title={*/}
                        {/*            <>*/}
                        {/*                <ThumbsDownIcon/> {t('dislikes','Dislikes')}*/}
                        {/*            </>*/}
                        {/*        }*/}
                        {/*        value={asset.down_votes_count}*/}
                        {/*    />*/}
                        {/*</Card>*/}
                    </Flex>
                </Card>
            )}
        </>
    )
}

const Creator = ()=> {
    const {t} = useTranslation();
    const {asset, isMetaEdit} = useContext(AssetModalContext)

    const assetsDispatch = useAssetsDispatch()
    const editAssetDispatch = useEditAssetsDispatch()

    const [savingCreator, setSavingCreator] = useState()
    const saveCreator = ()=>{
        setSavingCreator(true)
        api.post(`/api/assets/${asset.id}/save_creator`).then(res => {
            message.success(t('message-creator-info-saved','Creator info saved to Creator Tag!'))
            assetsDispatch({type:'updateAsset', asset: res.data})
            editAssetDispatch({type:'increment'})
            setSavingCreator(false)
        })
    }

    const renderUnsavedCreator = ()=> {
        return (
            <>
                <Popover
                    trigger={['click','hover']}
                    overlayStyle={{width: isMobile() ? '100%' : 500}}
                    placement={'right'}
                    title={ <> {t('creator-info','Creator Info')} </> }
                    content={
                        <VerticalSpace>
                            {isMetaEdit && (
                                <CreatorTag cTag={asset.creator_tag} asset={asset} editable={asset.editable} placeholder={t('placeholder-change-creator','Change Creator...')}/>
                            )}

                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('name','Name')}>
                                    <EditableMetaField f={'creator'}/>
                                </Descriptions.Item>
                            </Descriptions>

                            <EditableMetaStruct field={'creator_contact_info'} defaultTitle={t('contact-info','Contact Info')} defaultOpen/>

                            {isMetaEdit && (
                                <Popconfirm title={t('confirm-save-creator','Save Creator?')} onConfirm={saveCreator}>
                                    <Button icon={<SaveOutlined/>} loading={savingCreator} type={'primary'}>
                                        {t('button-save-creator','Save Creator')}
                                    </Button>
                                    <HelpPopover code={'save-to-creator-tag'}></HelpPopover>
                                </Popconfirm>
                            )}
                        </VerticalSpace>
                    }
                >
                    <Tag id={'unsaved-creator-tag'}>{asset.creator?.join(', ') || t('none','None')}</Tag>
                </Popover>
            </>
        )
    }

    return (
        <InfoItem f={'creator'}>
            <AssetMetaField name={'creator'} showIf={asset.creator_tag}>
                <VerticalSpace>
                    {(asset.creator_tag) && (
                        <CreatorTag cTag={asset.creator_tag} asset={asset} editable={asset.editable}/>
                    )}

                    {!asset.creator_tag && renderUnsavedCreator()}
                </VerticalSpace>
            </AssetMetaField>
        </InfoItem>
    )
}

const InfoItem = ({f, children, id})=>{
    const {customMetaFields} = useContext(AssetModalContext)

    const customMetaFieldId = (f.match(/^custom-meta-(.+?)$/) || [])[1]
    const cmf = customMetaFieldId && _.find(customMetaFields || [], {id: parseInt(customMetaFieldId)})

    let label, component;

    if(cmf) {
        label = cmf.name;
        component = <CustomMetaField cmf={cmf}/>;

    } else {
        label = MetaFields[f]?.name || f.toProperCase();
    }

    return (
        <Descriptions
            id={id}
            size='small'
            column={1}
            bordered
            labelStyle={{width: 200}}
            items={[
                {
                    key: f,
                    label: <strong>{label}</strong>,
                    children: children || component || <EditableMetaField f={f}/>,
                }
            ]}
        />
    )
}

const HasPeople = ()=>{
    const {t} = useTranslation();
    const {asset, isMetaEdit} = useContext(AssetModalContext)

    const yes = <><CheckOutlined/> {t('yes','Yes')}</>
    const no = <><CloseOutlined/> {t('no','No')}</>
    const untagged = <><QuestionOutlined/> {t('untagged','Untagged')}</>

    const editAssetDispatch = useEditAssetsDispatch()
    const assetsDispatch = useAssetsDispatch()

    const hasPeopleChanged = (e, opt) => {
        api.put(`/api/assets/${asset.id}`, {asset: {has_people: e.target.value}}).then(res => {
            assetsDispatch({type:'updateAsset', asset: res.data})
            editAssetDispatch({type:'increment'})
            message.success(t('message-asset-updated',`Asset updated.`))
        })
    }

    return (
        <InfoItem f={'has_people'}>
            {asset.editable && (
                <>
                    <Radio.Group onChange={hasPeopleChanged} value={asset.has_people} style={{width:'100%'}}>
                        <Radio value={true}>{yes}</Radio>
                        <Radio value={false}>{no}</Radio>
                        <Radio value={null}>{untagged}</Radio>
                    </Radio.Group>
                </>
            ) || (
                <>
                    {asset.has_people && yes}
                    {!asset.has_people && no}
                    {asset.has_people == null && untagged}
                </>
            )}
        </InfoItem>
    )
}

const LinksCard = ()=>{
    const {t} = useTranslation();
    const {asset, isMetaEdit} = useContext(AssetModalContext)

    return (
        <AssetMetaField name={'links'} visible={asset.links?.length}>
            <Card size={'small'} title={<><LinkOutlined/> {t('links','Links')}</>}>
                <Links what={asset} type={'Asset'}/>
            </Card>
        </AssetMetaField>
    )
}

const UsageRightsCard = ()=>{
    const {t} = useTranslation();
    const {asset, isMetaEdit, attrs, onChange} = useContext(AssetModalContext)

    return (
        <AssetMetaField name={'usage_terms'} showIf={!!attrs.usage_terms?.length || asset.rights_package?.meta_field_text?.length}>
            <Card size={'small'} title={<><RightsIcon/> {t('usage-rights','Usage Rights')}</>}>
                {asset.rights_package?.meta_field_text?.length ? (
                    <>{asset.rights_package?.meta_field_text}</>
                ) : (
                    <Typography.Paragraph editable={asset.editable && {onChange: onChange('usage_terms', 'Usage Terms')}} style={{marginBottom:0}}>
                        {!!attrs.usage_terms?.length ? attrs.usage_terms : <Typography.Text type={'secondary'}><em>{t('rights-not-specified','Rights Not Specified.')}</em></Typography.Text>}
                    </Typography.Paragraph>
                )}
            </Card>
        </AssetMetaField>
    )
}

const RestrictedAccessCard = ()=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    return (
        <Card size={'small'} title={<><FlagOutlined/> {t('restricted-access', 'Restricted Access')} <HelpPopover code={'block-flag'}/></>}>
            <VerticalSpace>
                <BlockFlagSelect asset={asset}/>

                {!!(asset.rights_package_block_level && asset.rights_package_block_level > 0) && (
                    <Alert
                        type={'info'}
                        showIcon
                        message={
                            <>
                                {asset.rights_package_block_level == 1 && <>{t('download-blocked', 'Download Blocked')}</>}
                                {asset.rights_package_block_level == 2 && <>{t('view-and-download-blocked', 'View and Download Blocked')}</>}
                            </>
                        }
                        description={t('access-restriction-inherited-from-rights-package', 'Access Restriction Inherited from Rights Package.')}
                    />
                )}
            </VerticalSpace>
        </Card>
    )
}

const OptimizationRequestsCard = ()=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    return (
        <Card size={'small'} title={<><ReconciliationOutlined/> {t('optimization-requests', 'Optimization Requests')} <HelpPopover code={'optimization-request'}/></>}>
            <OptimizationRequestButton asset={asset}/>
        </Card>
    )
}

const Description = ()=>{
    const {t} = useTranslation();
    const {asset, attrs, onChange} = useContext(AssetModalContext)

    return (
        <InfoItem f={'description'} id={'editable-description'}>
            <EditableTextArea
                id={asset.id}
                name={t('description','Description')}
                value={attrs.description}
                displayValue={<AutoLinker text={attrs.description}/>}
                onChange={onChange('description')}
                editable={asset.editable || asset.description_editable}
                style={{marginBottom:0}}
                bottomButtons
            />
        </InfoItem>
    )
}

const AltTextItem = ()=>{
    return (
        <InfoItem f={'alt_text'}><AltText/></InfoItem>
    )
}

const AltText = ()=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const {asset, attrs, setAttrs} = useContext(AssetModalContext)
    const assetsDispatch = useAssetsDispatch()

    const [generatingAltText, setGeneratingAltText] = useState()
    const generateAltText = ()=> {
        setGeneratingAltText(true)

        api.post(`/api/assets/${asset.id}/generate_alt_text`).then(res => {
            setGeneratingAltText(false)
            assetsDispatch({type:'updateAsset', asset: {...asset, ...res.data}})
            message.success(t('alt-text-generated','Alt-Text Generated!'))
        })
    }

    // Always keep alt_text in sync:
    useEffect(()=>{
        setAttrs({...attrs, alt_text: asset?.alt_text})
    }, [asset?.alt_text]);

    return (
        <Flex justify={'space-between'}>
            <EditableMetaField f={'alt_text'}/>

            {currentOrg?.enable_ai && currentOrg?.alt_text_generation_mode !== 'off' && asset?.editable && ['Image', 'Document'].indexOf(asset.type) !== -1 && (
                <Space>
                    {asset.bedrock_retrieved_at && (
                        <Popover
                            trigger={['hover', 'focus']}
                            title={t('alt-text-generation', 'Alt-Text Generation')}
                            content={
                                <>
                                    <Descriptions
                                        bordered
                                        size='small'
                                        column={1}
                                        items={[
                                            {
                                                key: 'prompt',
                                                label: t('prompt', 'Prompt'),
                                                children: asset.bedrock_prompt
                                            },
                                            {
                                                key: 'retrieved',
                                                label: t('retrieved', 'Retrieved'),
                                                children: <TimeAgo date={asset.bedrock_retrieved_at}/>
                                            },
                                            // {
                                            //     key: 'raw',
                                            //     label: t('raw', 'Raw Response'),
                                            //     children: <pre style={{width:100}}>{JSON.stringify(asset.bedrock_data)}</pre>
                                            // },
                                        ]}
                                    />
                                </>
                            }
                        >
                            <InfoCircleOutlined tabIndex={0}/>
                        </Popover>
                    )}

                    {!asset.alt_text?.length && (
                        <Button
                            onClick={generateAltText}
                            loading={generatingAltText}
                            icon={<CloudServerOutlined/>}
                            size={'small'}
                            type={'primary'}
                            ghost
                        >
                            {t('generate', 'Generate')}
                        </Button>
                    )}
                </Space>
            )}
        </Flex>
    )
}

const QuickInfo = ()=>{
    const {t} = useTranslation();

    const {isHorizontal} = useContext(AssetModalContext)
    const org = useCurrentOrg()

    const length = org?.quick_info_settings?.length
    const perColumn = Math.floor(length / 2)

    const renderItem = f => {
        const Component = {
            stats: StatsCard,
            keywords: KeywordsCard,
            creator: Creator,
            description: Description,
            gps: MapCard,
            has_people: HasPeople,
            links: LinksCard,
            usage_rights: UsageRightsCard,
            restricted_access: RestrictedAccessCard,
            optimization_requests: OptimizationRequestsCard,
            alt_text: AltTextItem
        }[f];

        return Component ? <Component key={f}/> : <InfoItem f={f} key={f}/>;
    }

    if(!org?.quick_info_settings) return <LoadingOutlined/>

    return (
        <div id={'quick-info'}>
            <Row gap={4} gutter={[16,16]}>
                <Col lg={isHorizontal ? 24 : 12} xs={24} style={{display:'flex'}}>
                    <VerticalSpace size={16}>
                        {org.quick_info_settings.slice(0, perColumn).map(renderItem)}
                    </VerticalSpace>
                </Col>

                <Col lg={isHorizontal ? 24 : 12} xs={24} style={{display:'flex'}}>
                    <VerticalSpace size={16}>
                        {org.quick_info_settings.slice(perColumn, length).map(renderItem)}
                    </VerticalSpace>
                </Col>

            </Row>
        </div>
    )

}

const EditableMetaArray = ({obj, f, onChange})=>{
    const {asset} = useContext(AssetModalContext)

    const [editing, setEditing] = useState()

    const [values, setValues] = useState(obj && obj[f] && obj[f].filter(v => v?.length))

    const onChangeSelect = newVals => {
        setValues(newVals)
        onChange(newVals)
    }

    // console.log('EditableMetaArray', f, obj)

    return (
        <div style={{display:'flex'}} id={`edit-meta-${f}`}>
            {editing ? (
                <Select mode={'tags'} value={values} onChange={onChangeSelect} placeholder={'Add Values...'} style={{width:'100%', flex:'auto'}} open={false}/>
            ) : (
                <div style={{flex:'none'}}>
                    {((obj && obj[f]) || []).map((v,i) => <Tag key={i}>{v}</Tag>)}
                </div>
            )}

            {asset.editable && (
                <Button type={'text'} size='small' icon={<EditOutlined/>} onClick={()=> setEditing(!editing)} style={{flex:'none', color: Colors.blue}}/>
            )}
        </div>
    )
}

const EditableMetaStruct = ({field, extra, defaultTitle, defaultOpen})=> {
    const {t} = useTranslation();
    const {asset, isMetaEdit, onChange} = useContext(AssetModalContext)

    const {name: title, struct, array, title_field: titleField} = MetaFields[field];

    const [val, setVal] = useState(asset[field] || (array && []))

    try {
        array && val.map((el, i) => el.index = i)
    } catch(e) {
        console.log(field, val, e)
        return <></>
    }

    // console.log(field, val, titleField)

    const updateVal = ({prop, subProp, index, subIndex, newVal})=> {
        console.log('updateVal', prop, index, newVal)

        if(typeof index == 'number') {
            if(subProp) {
                if(subIndex) {
                    val[index][prop][subIndex][subProp] = newVal;
                } else {
                    val[index][prop][subProp] = newVal;
                }
            } else {
                val[index][prop] = newVal;
            }
        } else if(prop) {
            val[prop] = newVal

        } else if(array) {
            val.push(newVal)

        } else {
            // new value for non-array:
            setVal(newVal)
            onChange(field, MetaFields[field].name)(newVal)
            return
        }

        const updatedVal = array ? [...val] : {...val};
        setVal(updatedVal)
        onChange(field, MetaFields[field].name)(updatedVal)
    }

    const remove = i => {
        return ()=> {
            console.log('remove', i, val[i])
            const newVal = array ? [...val] : {}
            if(array) newVal.splice(i, 1);
            setVal(newVal)
            onChange(field, MetaFields[field].name)(newVal)
        }
    }

    const assetsDispatch = useAssetsDispatch()

    const saveCanonical = obj => {
        return ()=> {
            api.post(`/api/assets/${asset.id}/save_meta_struct`, {field, index: obj.index}).then(res => {
                assetsDispatch({type:'updateAsset', asset: res.data})
                message.success(t('message-saved','Saved!'))
            })
        }
    }

    const createMetaStruct = (values, cb) =>{
        api.post(`/api/assets/${asset.id}/add_meta_struct`, {field, values}).then(res => {
            assetsDispatch({type:'updateAsset', asset: res.data})
            message.success(t('message-added','Added!'))
            cb && cb()
        })
    }

    const metaStructs = asset.meta_structs?.filter(ms => ms.field == field) || []

    const stopPropagation = e => e.stopPropagation();

    const removeButton = (title, onConfirm)=> {
        return (
            <div onClick={stopPropagation}>
                <Tooltip title={t(`confirm-remove-tag-from-asset`,`Remove tag "{{name}}" from Asset`, {name: title})} placement={'bottom'}>
                    <Popconfirm onConfirm={onConfirm} title={t('confirm-remove','Remove?')}>
                        <Button ghost danger size={'small'}><CloseOutlined style={{margin:0, padding:0}}/></Button>
                    </Popconfirm>
                </Tooltip>
            </div>
        )
    }

    const buildElement = (obj) => {
        if(!obj) return <></>

        return (
            (<Collapse.Panel
                key={`i_${obj.index}`}
                header={(obj && t(obj[titleField],obj[titleField])) || t(defaultTitle,defaultTitle)}
                extra={
                    array && removeButton(obj && t(obj[titleField],obj[titleField]), remove(obj))
                }
            >
                <Space direction={'vertical'} style={{width:'100%'}}>

                    <Descriptions bordered size='small' column={1}>
                        {Object.keys(struct).map((f,i) => (
                            <Descriptions.Item
                                key={i}
                                style={{width:'33%'}}
                                label={
                                    <>
                                        {t(struct[f].name,struct[f].name)}
                                        <br/>
                                        <em><small>{t(struct[f].desc,struct[f].desc)}</small></em>
                                    </>
                                }>
                                {struct[f].array ? (
                                    <EditableMetaArray f={f} obj={obj} onChange={v => updateVal({prop: f, index: obj.index, newVal: v})}/>
                                ) : (
                                    <Typography.Paragraph
                                        editable={
                                            asset.editable &&
                                            {onChange: (v) => updateVal({prop: f, index: obj.index, newVal: v})}
                                        }
                                    >
                                        {obj && obj[f]}
                                    </Typography.Paragraph>
                                )}
                            </Descriptions.Item>
                        ))}

                        <Descriptions.Item>
                            <Popconfirm onConfirm={saveCanonical(obj)} title={t(`confirm-save-field-to-list`,`Save {{name}} to List?`, {name: field.replace(/_/g, ' ').toProperCase()})} onClick={e => e.stopPropagation()}>
                                <Button icon={<SaveOutlined/>} block type={'primary'} onClick={e => e.stopPropagation()}>{t('button-save','Save')}</Button>
                            </Popconfirm>
                        </Descriptions.Item>

                    </Descriptions>

                    {extra}
                </Space>
            </Collapse.Panel>)
        );
    }

    const removeCanonicalMetaStruct = meta_struct_id => {
        return ()=>{
            api.post(`/api/assets/${asset.id}/remove_meta_struct`, {meta_struct_id}).then(res => {
                assetsDispatch({type:'updateAsset', asset: res.data})
                message.success(t('message-removed','Removed!'))
            })
        }
    }

    const onUpdateCanonicalMetaStruct = metaStruct => {
        _.find(asset.meta_structs, {id: metaStruct.id}).title = metaStruct.title;

        assetsDispatch({type:'updateAsset', asset: {...asset, meta_structs: asset.meta_structs}})
    }

    const nonCanonicals = array ? val.filter(el => !_.find(metaStructs, {title: el[titleField]})) : [val]

    return (
        <Space direction={'vertical'} style={{width:'100%'}}>
            {array && (
                <>
                    <Collapse>
                        {metaStructs.map(metaStruct => (
                            <Collapse.Panel
                                key={metaStruct.id}
                                header={
                                    <Space>
                                        <Tooltip title={t('tooltip-saved','Saved')}>
                                            <CheckCircleOutlined style={{color:Colors.blue}}/>
                                        </Tooltip>
                                        {t(metaStruct.title,metaStruct.title)}
                                    </Space>
                                }
                                extra={ removeButton(t(metaStruct.title,metaStruct.title), removeCanonicalMetaStruct(metaStruct.id)) }
                            >
                                <EditableCanonicalMetaStruct metaStruct={metaStruct} onUpdate={onUpdateCanonicalMetaStruct}/>
                            </Collapse.Panel>
                        ))}

                        {nonCanonicals.map(buildElement)}
                    </Collapse>
                </>
            )}

            {!array && !!nonCanonicals.length && (
                <Collapse defaultActiveKey={defaultOpen && 'i_0'}>
                    {nonCanonicals.map(buildElement)}
                </Collapse>
            )}

            {(array || !val) && asset.editable && (
                <Collapse>
                    <Collapse.Panel key={'new'} header={<strong><PlusOutlined/> {t('add-new','Add New')} {t(title,title)}</strong>}>
                        <VerticalSpace>
                            <CanonicalMetaStructSelect field={field}/>

                            <Formik
                                initialValues={{}}
                                enableReinitialize
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(true)
                                    createMetaStruct(values, ()=> {
                                        actions.setSubmitting(false);
                                        actions.resetForm()
                                    })
                                }}
                            >
                                {({submitForm, isSubmitting}) => {
                                    return (
                                        <Descriptions bordered size='small' column={1}>
                                            {Object.keys(struct).map(f => (
                                                <Descriptions.Item
                                                    key={f}
                                                    style={{width:'33%'}}
                                                    label={
                                                        <>
                                                            {t(struct[f].name,struct[f].name)}
                                                            <br/>
                                                            <em><small>{t(struct[f].desc,struct[f].desc)}</small></em>
                                                        </>
                                                    }>
                                                    <>
                                                        {struct[f].array ? (
                                                            <FormikSelect mode={'tags'} name={f} style={{width:'100%'}}/>
                                                        ) : (
                                                            <Input name={f}/>
                                                        )}
                                                    </>
                                                </Descriptions.Item>
                                            ))}
                                            <Descriptions.Item key={'_submit'}>
                                                <Button icon={<CheckOutlined/>} onClick={submitForm} loading={isSubmitting} block type={'primary'}>{t('button-save-and-add','Save and Add')}</Button>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    )
                                }}
                            </Formik>
                        </VerticalSpace>
                    </Collapse.Panel>
                </Collapse>
            ) }
        </Space>
    )
}

const CanonicalMetaStructSelect = ({field}) => {
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    const [loading, setLoading] = useState()
    const [value, setValue] = useState()

    const [metaStructs, setMetaStructs] = useState([])

    const options = metaStructs.map(c => <Select.Option key={c.id}>{c.title}</Select.Option>)

    const onSearch = (val)=> {
        setLoading(true)
        setValue(val)

        api(`/api/meta_structs?field=${field}&q=${val}`).then(res => {
            setLoading(false)
            setMetaStructs(res.data)
        })
    }

    const assetsDispatch = useAssetsDispatch();

    const select = (id, opt) => {
        update(id)
    }

    const [updating, setUpdating] = useState(false)

    const update = (meta_struct_id)=> {
        setUpdating(true)
        api.post(`/api/assets/${asset.id}/add_meta_struct`, {meta_struct_id}).then(res => {
            setUpdating(false)
            if(res.errors) {
                message.error(JSON.stringify(res.errors))
            } else {
                message.success(t('message-added','Added!'))
                assetsDispatch({type:'updateAsset', asset: res.data});
            }
            setValue(null)
            setMetaStructs([])
        })
    }

    return (
        <Select
            style={{width: '100%'}}
            showSearch
            loading={loading || updating}
            defaultActiveFirstOption={false}
            placeholder={t('placeholder-add-new-from-existing','Add New From Existing...')}
            filterOption={false}
            onSearch={onSearch}
            notFoundContent={null}
            onSelect={select}
            value={value}
        >
            {options}
        </Select>
    )
}

const EditableCanonicalMetaStruct = ({metaStruct, onUpdate})=> {
    const {t} = useTranslation();
    const [obj, setObj] = useState(metaStruct.values)

    const {struct} = MetaFields[metaStruct.field];

    const update = key => {
        return value => {
            api.put(`/api/meta_structs/${metaStruct.id}`, {key, value}).then(res => {
                console.log(res.data)
                message.success(t('message-updated','Updated.'))
                setObj(res.data.values)

                onUpdate && onUpdate(res.data)
            })
        }
    }

    return (
        <>
            <Descriptions bordered size='small' column={1}>
                {Object.keys(struct).map(f => (
                    <Descriptions.Item
                        key={f}
                        style={{width:'33%'}}
                        label={
                            <>
                                {t(struct[f].name,struct[f].name)}
                                <br/>
                                <em><small>{t(struct[f].desc,struct[f].desc)}</small></em>
                            </>
                        }>
                        {struct[f].array ? (
                            <EditableMetaArray f={f} obj={obj} onChange={update(f)}/>
                        ) : (
                            <Typography.Paragraph
                                editable={{ onChange: update(f) }}
                            >
                                {obj && obj[f]}
                            </Typography.Paragraph>
                        )}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </>
    )
}

const AssetMetaField = ({name, visible, children, showIf})=> {
    const showMetaField = useShowMetaField()

    return showIf || showMetaField(name, visible) ? children : <></>
}

const useShowMetaField = () => {
    const {asset, isMetaEdit} = useContext(AssetModalContext)

    return (name, visible) => {
        if(typeof visible != 'undefined' && !visible && !isMetaEdit) return false

        // TODO check meta structs
        const meta = MetaFields[name]

        let present = false

        if(meta?.struct) {
            present = asset.meta_structs?.filter(ms => ms.field == name)?.length
        } else {
            present = asset[name]
        }

        return present || isMetaEdit
    }
}

const EditableMetaField = ({f, extra})=>{
    const {i18n,t} = useTranslation();
    moment.locale(i18n.language)

    const {asset, attrs, onChange} = useContext(AssetModalContext)

    const meta = MetaFields[f]

    const [editingTime, setEditingTime] = useState()

    const [changing, setChanging] = useState()
    const onChangeWrapper = val => {
        setChanging(true)
        onChange(f, meta.name)(val, ()=>{
            setChanging(false)
        })
    }

    const onUpdateTime = val =>{
        onChangeWrapper(val)

        setEditingTime(false)
    }

    const [truncation, setTruncation ] = useState(asset[`${f}_truncation`])
    const onChangeTruncation = val => {
        setTruncation(val)
        onChange(`${f}_truncation`, 'Date Created Truncation' )(val)
    }

    if(!meta) return <></>

    if(changing) return <LoadingOutlined/>

    if(f === 'captured_at') {
        const time = asset.captured_at ? moment.tz(asset.captured_at, 'UTC').utcOffset(asset.captured_at_offset || '+00:00') : null
        let format;
        switch(asset.captured_at_truncation) {
            case 'year':
                format = 'YYYY';
                break;
            case 'month':
                format = 'MMM YYYY';
                break;
            case 'day':
                format = 'LL';
                break;
            default:
                format = 'LLLL';
        }

        const formatted = time?.format(format);

        if(asset.editable) {
            return (
                <>
                    {editingTime ? (
                        <Space>
                            <Tooltip title={t('truncation-level','Truncation Level')}>
                                <Select onChange={onChangeTruncation} value={truncation} style={{width:100}}>
                                    <Select.Option value={null}>{t('none', 'None')}</Select.Option>
                                    <Select.Option value={'year'}>{t('year','Year')}</Select.Option>
                                    <Select.Option value={'month'}>{t('month','Month')}</Select.Option>
                                    <Select.Option value={'day'}>{t('day','Day')}</Select.Option>
                                </Select>
                            </Tooltip>
                            <DatePicker showTime={!truncation} defaultValue={day(formatted)} onChange={truncation ? onUpdateTime : ()=>{}} onOk={onUpdateTime} picker={truncation || 'date'}/>
                        </Space>
                    ) : formatted}
                    <Button type='text' icon={<EditOutlined/>} onClick={()=> setEditingTime(!editingTime)} style={{color:Colors.blue}}/>
                </>
            )
        } else {
            return <>{formatted}</>
        }
    }

    if(meta.options) {
        return (
            <Select value={asset[f]} onChange={onChangeWrapper} style={{width:'50%'}} disabled={!asset.editable}>
                <Select.Option></Select.Option>
                {meta.options.map(o => <Select.Option value={o} key={o}>{o}</Select.Option>)}
            </Select>
        )


    } else if(meta.struct) {
        return <EditableMetaStruct field={f} extra={extra}/>

    } else if(meta.array) {
        return (
            <EditableMetaArray f={f} obj={asset} onChange={onChangeWrapper}/>
        )

    } else {
        return (
            <Typography.Paragraph editable={asset.editable && {onChange: onChangeWrapper}} style={{margin:0}}>
                {attrs[f] || ''}
            </Typography.Paragraph>
        )
    }
}


import videojs from 'video.js';
window.videojs = videojs

import SplitPane from "react-split-pane";
import useConsumer from "../../channels/consumer";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import AutoSizer from "react-virtualized-auto-sizer";
import OptimizationRequestButton from "./OptimizationRequestButton";
import {AssetContextMenu} from "../explore/Asset";
import AutoLinker from "../helpers/AutoLinker";
import VerticalSpace from "../helpers/VerticalSpace";
import AssetVoteButtons from "./AssetVoteButtons";
import EventTagSelect from "./EventTagSelect";
import Draggable from "react-draggable";
import SharesTable from "../manage/SharesTable";
import AccessRequestButton from "../explore/AccessRequestButton";
import {useTranslation} from "react-i18next";

const VideoPlayer = ({cb, className, asset, ...props}) => {
    const {height} = props;

    // Ref of the VideoJS object:
    const playerRef = useRef();

    // Ref of the DOM parent element the video player node is appended to:
    const videoRef = useRef();

    const maxWidth = document.getElementById('asset-modal')?.clientWidth || '1200px'
    const style = {maxHeight:'100vh', maxWidth, height};

    useEffect(() => {
        // Make sure Video.js player is only initialized once:
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");

            videoElement.classList = className;
            videoElement.classList.add('video-js');

            videoElement.style.maxHeight = '100vh';
            videoElement.style.maxWidth = maxWidth;

            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, props, () => {
                videojs.log('player is ready');
                console.log('videojs player ready')
                cb && cb(player);
            });
            console.log('init useEffect', player)

        } else {
            const player = playerRef.current;

            player.autoplay(props.autoplay);
            player.src(props.sources);

            console.log('videojs player already initialized, calling callback')
            cb && cb(player)
        }
    }, [asset?.id, videoRef]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                console.log('disposing player:', asset.id)
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef])

    return (
        <div data-vjs-player style={style}>
            <div ref={videoRef}/>
        </div>
    )
}

export {VideoPlayer}

const AssetPlayer = ({asset, cb})=> {
    if(asset.type !== 'Video' && asset.type !== 'Audio') return <></>

    return (
        <div>
            <VideoPlayer
                inactivityTimeout={0}
                asset={asset}
                controls
                fluid
                width={'auto'}
                height={'auto'}
                loop={asset.ext === 'gif'}

                poster={asset.preview_image_url}
                sources={
                    asset.type == 'Video' ?
                        [ {type:'video/mp4', src: asset.full_url || asset.permalink_url || asset.small_url} ] :
                        [ {type:'audio/mp3', src: asset.permalink_url || asset.small_url} ]
                }
                cb={cb}
            />
        </div>
    )
}

const RawExif = ({asset})=>{
    const [meta, setMeta] = useState()

    useEffect(()=>{
        if(!asset) return

        api(`/api/assets/${asset.id}/meta`).then(res =>{
            setMeta(res.data)
        })

    }, [asset?.id])

    const renderString = str => <AutoLinker text={str}/>

    const renderStruct = obj => (
        <Descriptions bordered size='small' column={1}>
            {obj && Object.keys(obj).sort().filter(key => key.length).map(key => (
                <Descriptions.Item label={key} key={key}>
                    <Space direction={'vertical'} style={{width:'100%'}}>
                        {Array.isArray(obj[key]) && (
                            <List
                                size="small"
                                dataSource={obj[key]}
                                renderItem={item => <List.Item>{typeof item == 'object' ? renderStruct(item) : renderString(item)}</List.Item>}
                            />
                        )}

                        {obj[key].constructor === Object && renderStruct(obj[key])}

                        {(typeof obj[key] === 'string' || typeof obj[key] === 'number') && renderString(obj[key])}
                    </Space>
                </Descriptions.Item>
            ))}
        </Descriptions>
    )

    return (
        <Skeleton active loading={!meta}>
            {renderStruct(meta)}
        </Skeleton>
    )
}

const AssetTextContent = ({asset, field, onChange})=>{
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch()

    const [visible, setVisible] = useState()
    const [loading, setLoading] = useState()
    const [content, setContent] = useState()

    const autoFocusInput = useRef(null);

    useEffect(()=>{
        setLoading(true)
        api(`/api/assets/${asset.id}/${field}`).then(res => {
            setLoading(false)
            setContent(res.data)
            onChange && onChange({content: res.data})
        })
    }, [asset?.id])

    const edit = ()=>{
        setVisible(true)
        setTimeout(()=>{
            autoFocusInput.current.focus()
        }, 100)
    }

    const {editable} = asset;

    const initialValues = {}
    initialValues[field] = content;

    return (
        (<Space style={{width:'100%'}} direction={'vertical'}>
            <Card>
                <Skeleton active loading={loading}>
                    {asset.filename.match(/md$|markdown$/) ? (
                        <>{content && (new Parser).parse(markdown.toHTML(content))}</>
                    ) : (
                        <pre style={{whiteSpace: 'pre-wrap', marginBottom:0}}>{content}</pre>
                    )}
                </Skeleton>
            </Card>
            {editable && <Button size={'small'} icon={<EditOutlined/>} onClick={edit}>{t('button-edit','Edit')}</Button>}
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    values[field] = values[field] || ''

                    api.put(`/api/assets/${asset.id}`, {asset: values}).then(res => {
                        actions.setSubmitting(false)
                        assetsDispatch({type:'updateAsset', asset: res.data})
                        message.success(t('message-content-updated','Content Updated!'))
                        setContent(values[field])
                        setVisible(false)
                        onChange && onChange(res.data)
                    })
                }}
            >
                {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                    return (
                        (<Modal
                            open={visible}
                            title={<><EditOutlined/> {t('editing','Editing')} {asset.filename}</>}
                            onCancel={()=> setVisible(false)}
                            centered
                            footer={
                                <Button type={'primary'} onClick={submitForm} loading={isSubmitting}>
                                    <SaveOutlined/>
                                    {t('button-save','Save')}
                                </Button>
                            }
                        >
                            <form onSubmit={handleSubmit}>
                                <FormItem name={field}>
                                    <Input.TextArea style={{height:'50vh'}} name={field} ref={autoFocusInput}/>
                                </FormItem>
                            </form>
                        </Modal>)
                    );
                }}
            </Formik>
        </Space>)
    );
}

const CustomMetaField = ({cmf})=>{
    const {t} = useTranslation();
    const org = useCurrentOrg()
    const {asset} = useContext(AssetModalContext)

    const cmvs = asset.custom_meta_values?.filter(cmv => cmv.custom_meta_field_id === cmf.id) || []

    const assetsDispatch = useAssetsDispatch();
    const [loading, setLoading] = useState()

    const onChange = () =>{
        return value => {
            const data = {custom_meta_field_id: cmf.id}

            // Either text value for freeform, or id(s) of value:
            if(cmf.free) data.value = value
            else data.custom_meta_value_id = value

            setLoading(true)
            api.put(`/api/assets/${asset.id}/update_custom_meta`, data).then(res => {
                setLoading(false)
                assetsDispatch({type:'updateAsset', asset: {id: asset.id, ...asset, custom_meta_values: res.data.custom_meta_values}});
                message.success(`${cmf.name} ${t('updated','Updated!')}`)
            })
        }
    }

    return (
        <>
            {cmf.free ? (
                <>
                    {(cmf.value_type === 'text' || cmf.value_type === 'number') && (
                        <Typography.Text editable={{onChange: onChange(cmf)}}>{cmvs[0]?.text}</Typography.Text>
                    )}

                    {cmf.value_type === 'price' && (
                        <Typography.Text editable={{onChange: onChange(cmf), value: cmvs[0]?.number}}>${cmvs[0]?.number}</Typography.Text>
                    )}

                    {(cmf.value_type === 'date' || cmf.value_type === 'datetime') && (
                        <DatePicker showTime={cmf.value_type === 'datetime'} defaultValue={!!cmvs[0]?.date?.length && day(cmvs[0]?.date).utc().local()} onChange={onChange(cmf)}/>
                    )}
                </>
            ) : (
                <AntSelect
                    name={cmf.name}
                    mode={cmf.multi && 'multiple'}
                    placeholder={t(`choose-${cmf.multi ? 'multiple' : 'one'}`, `Choose ${cmf.multi ? 'Multiple...' : 'One...'}`)}
                    style={{width: '100%'}}
                    allowClear
                    onChange={onChange(cmf)}
                    value={loading ? t('loading','Loading...') : (cmf.multi ? cmvs.map(cmv => cmv.id) : cmvs[0]?.id)}
                    disabled={loading}
                    loading={loading}
                >
                    {cmf.custom_meta_values_attributes.map(cmv => (
                        <AntSelect.Option key={cmv.id} value={cmv.id}>
                            <Flex gap={6}>
                                {cmv.color_label && <Badge color={cmv.color_label}/>}
                                {cmv.text}
                            </Flex>
                        </AntSelect.Option>
                    ))}
                </AntSelect>
            )}
        </>
    )

}

const AssetCustomMetaFields = ()=>{
    const {t} = useTranslation();
    const {asset, isMetaEdit, customMetaFields, customMetaFieldsLoading} = useContext(AssetModalContext)
    const currentOrg = useCurrentOrg()
    const currentUser = useCurrentUser()

    const [valuesLoading, setValuesLoading] = useState(true)
    const [customMetaValues, setCustomMetaValues] = useState([])

    useEffect(()=>{
        if(asset.custom_meta_values) {
            setCustomMetaValues(asset.custom_meta_values)
            setValuesLoading(false)
        }
        else setValuesLoading(true)
    }, [!!asset.custom_meta_values, asset.id])

    const customMetaValuesGrouped = {}
    if(!asset.taggable) {
        customMetaValues.map(cmv => {
            if(!customMetaValuesGrouped[cmv.custom_meta_field_id]) customMetaValuesGrouped[cmv.custom_meta_field_id] = []
            customMetaValuesGrouped[cmv.custom_meta_field_id].push(cmv);
        })
    }

    if(!currentUser) return <></>
    if(customMetaFields && !customMetaFields.length && !customMetaFieldsLoading) return <></>

    return (
        <Skeleton active loading={valuesLoading || customMetaFieldsLoading}>
            <Card
                title={<strong><CustomMetaIcon/> {t('custom-meta','Custom Meta')}</strong>}
                size={'small'}
                style={{padding:0}}
            >
                {asset.taggable ? (
                    <Descriptions bordered size='small' column={1} labelStyle={{width:200}}>
                        {customMetaFields.map(cmf => {
                            const cmvs = customMetaValues.filter(cmv => cmv.custom_meta_field_id === cmf.id)

                            if(!isMetaEdit && !cmvs[0]) return <></>

                            return (
                                <Descriptions.Item
                                    label={<>{cmf.name}<br/>{cmf.description && <small><em>{cmf.description}</em></small>}</>}
                                    key={cmf.name}
                                >
                                    <CustomMetaField cmf={cmf}/>
                                </Descriptions.Item>
                            );
                        })}
                    </Descriptions>
                ): (
                    <Descriptions bordered size='small' column={1} labelStyle={{width:200}}>
                        {Object.keys(customMetaValuesGrouped).map(id => {
                            const values = customMetaValuesGrouped[id]
                            const {name, description, value_type} = values[0]
                            return (
                                <Descriptions.Item
                                    label={
                                        <>{name}<br/>{description && <small><em>{description}</em></small>}</>
                                    }
                                    key={name}
                                    style={{width: '33%'}}
                                >
                                    {values.map(v => {
                                        switch(value_type) {
                                            case 'date':
                                                return moment.tz(v.text, 'UTC').format('L');
                                            case 'datetime':
                                                return moment.tz(v.text, 'UTC').format('LLL');
                                            case 'price':
                                                return `$${v.text}`
                                            default:
                                                return v.text
                                        }
                                    }).join(', ')}
                                </Descriptions.Item>
                            );
                        })}
                    </Descriptions>
                )}
            </Card>
        </Skeleton>
    )
}

const AutoTags = ()=>{
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const editAssetDispatch = useEditAssetsDispatch()
    const assetsDispatch = useAssetsDispatch();

    const {asset} = useContext(AssetModalContext)
    const tagNames = asset.tags.map(tag => tag.name)

    const [loading, setLoading] = useState()
    const [tags, setTags] = useState()

    useEffect(()=>{
        setLoading(true)
        api(`/api/assets/${asset.id}/auto_tags`).then(res => {
            setLoading(false)
            setTags(res.data)
        })
    },[asset?.id])

    const [applyingTag, setApplyingTag] = useState()
    const applyTag = tag => {
        setApplyingTag(true)
        api.put(`/api/assets/${asset.id}/tag`, {asset: {add_tag_names: [tag.name]}}).then(res => {
            setApplyingTag(false)
            assetsDispatch({type:'updateAsset', asset: {...asset, ...res.data}});
            editAssetDispatch({type:'increment'})
            message.success(t('message-tag-added','Tag added.'))
        })
    }

    const [running, setRunning] = useState()
    const autoTag = ()=>{
        setRunning(true)
        api.post(`/api/assets/${asset.id}/auto_tag`).then(res => {
            setRunning(false)
            assetsDispatch({type:'updateAsset', asset: {...asset, rekognition_labels_retrieved_at: true}});
            setTags(res.data)
        })
    }

    const removeTag = tag => {
        setTags(_.without(tags, tag))
        api.delete(`/api/assets/${asset.id}/remove_auto_tag`, {params: {auto_tagging_id: tag.id}}).then(res =>{
            editAssetDispatch({type:'increment'})
            message.success(t('message-auto-tag-removed','Auto Tag removed.'))
        })
    }

    if(!asset.rekognition_labels_retrieved_at) {
        return asset.editable ? (
            <>
                <Button icon={<CloudServerOutlined/>} onClick={autoTag} loading={running}>{t('button-run-auto-tagging','Run Auto-Tagging')}</Button>
            </>
        ) : <em>Not Analyzed.</em>
    }

    const relevantTags = tags?.filter(t => parseFloat(t.confidence) >= currentOrg?.auto_tag_confidence_threshold)

    return (
        <Skeleton loading={loading}>
            {relevantTags?.map(tag => (
                <Popover
                    key={tag.id}
                    title={tag.name}
                    trigger={['click','hover']}
                    content={
                        <VerticalSpace>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label={t('confidence','Confidence')}>
                                    {Math.round(tag.confidence)}%
                                </Descriptions.Item>
                                <Descriptions.Item label={t('source','Source')}>
                                    {tag.source?.toProperCase()}
                                </Descriptions.Item>
                            </Descriptions>

                            {tagNames.indexOf(tag.name) == -1 && (
                                <Button onClick={() => applyTag(tag)} loading={applyingTag} icon={<CheckOutlined/>} block>{t('button-apply-tag','Apply Tag')}</Button>
                            )}
                        </VerticalSpace>
                    }
                >
                    <Tag id={`auto-tag-${tag.id}`} style={{margin:'0 .5em .5em 0'}}>
                        {tag.name} <GreyBadge count={`${Math.round(tag.confidence)}%`}/>

                        <a id={`remove-auto-tag-${tag.id}`} onClick={()=> removeTag(tag)}><CloseOutlined/></a>
                    </Tag>
                </Popover>
            ))}

            {asset.editable && tags && relevantTags.length != tags.length && (
                <div style={{marginTop:'1em'}}>
                    <Popover
                        content={
                            <>
                                {tags.filter(t => parseFloat(t.confidence) < currentOrg?.auto_tag_confidence_threshold).map(tag => (
                                    <Tag key={tag.id} style={{margin:'0 .5em .5em 0'}}>
                                        {tag.name} <GreyBadge count={`${Math.round(tag.confidence)}%`}/>
                                    </Tag>
                                ))}
                            </>
                        }
                    >
                        <em>{t('tags-below-threshold','{{count}} tags below threshold', {count: tags.length - relevantTags.length})} ({currentOrg.auto_tag_confidence_threshold}%).</em>
                    </Popover>
                </div>
            )}
        </Skeleton>
    )
}

import '../../plugins/videojs-transcript';

// FIXME: needs to load only after text track loads:
// Transcript Notes:
// - <Transcript/> is mounted when `player` is set (`playerRef.current`), in the `onPlayerReady` callback for videojs (called in <VideoPlayer/> useEffect)
const Transcript = ({asset, player, vttLoaded})=> {
    const {t} = useTranslation();
    const ref = useRef()

    useEffect(()=>{
        if(!vttLoaded) return;

        // This renders the line-by-line transcript HTML into the ref:
        try {
            const transcript = player.transcript({showTitle:false, showTrackSelector: false, clickArea: 'line'});
            ref.current.innerHTML = null
            ref.current.appendChild(transcript.el());
            _.map(document.querySelectorAll('.transcript-line'), el => {
                el.dataset.text = el.getElementsByClassName('transcript-text')[0].innerText
            })
        } catch(e) {
            console.log(e)
        }

    }, [asset.id, asset.vtt_url, vttLoaded])

    const onSearch = e =>{
        const val = e.currentTarget?.value?.trim()
        _.map(document.querySelectorAll('.transcript-line'), el => {
            try {
                const re = new RegExp(val,"gi");
                const match = val?.length && el.dataset.text.match(re)

                const textEl = el.getElementsByClassName('transcript-text')[0]

                if(!val?.length || match) {
                    el.style.display = 'block'

                    if(match) {
                        const newText = el.dataset.text.replace(re, `<mark>${val}</mark>`);
                        textEl.innerHTML = newText;
                    } else {
                        textEl.innerHTML = el.dataset.text;
                    }
                } else {
                    el.style.display = 'none'
                    textEl.innerHTML = el.dataset.text;
                }
            } catch (e) {
                return;
            }
        })
    }

    const [downloading, setDownloading] = useState()

    const downloadVtt = ()=>{
        setDownloading(true)
        const a = document.createElement("a")
        const url = asset.vtt_url
        a.setAttribute('href', url)
        a.setAttribute('_target', 'blank')
        a.setAttribute('download', `${asset.filename}.vtt`)

        a.style.display = 'none';
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a);
        setTimeout(()=> setDownloading(false), 1000)
    }

    const currentOrg = useCurrentOrg();
    const {state} = useContext(AppContext);

    const [uploading, setUploading] = useState()

    function beforeUpload(file) {
        console.log(file)
        const isValidFiletype = ['text/vtt', 'text/plain', 'text/srt', ''].indexOf(file.type) !== -1 || file.name.match(/(srt|vtt)$/i);
        if (!isValidFiletype) {
            message.error(t('error-only-upload-vtt-srt-not','You can only upload VTT or SRT files. Not: ' + file.type));
        }
        return isValidFiletype;
    }

    const assetsDispatch = useAssetsDispatch();

    const handleUpload = info => {
        if (info.file.status === 'uploading') {
            assetsDispatch({type:'updateAsset', asset: {...asset, has_transcript: false, vtt_url: null}})
            setUploading(true)
            return;
        }
        if (info.file.status === 'done') {
            setUploading(false)
            api(`/api/assets/${asset.id}`).then(res => {
                assetsDispatch({type:'updateAsset', asset: res.data})
                message.success(t('transcript-updated','Transcript Updated!'))
            })
        }
    };

    return (
        <VerticalSpace>
            <div style={{display:'flex', alignItems:'center'}}>
                {asset.vtt_url && (
                    <div style={{flex:1}}>
                        <AntInput.Search placeholder={t('placeholder-search-transcript',"Search Transcript...")} onChange={onSearch} allowClear onSearch={onSearch}/>
                    </div>
                )}

                {asset.editable && (
                    <div style={{flex:0, marginLeft:'auto', paddingLeft:'1em'}}>
                        <Space>
                            {asset.vtt_url && (
                                <Button icon={<DownloadOutlined/>} type={'primary'} ghost onClick={downloadVtt} loading={downloading}>{t('download-transcript','Download Transcript')}</Button>
                            )}

                            <Upload
                                name="transcript"
                                className="transcript-uploader"
                                id={'transcript-uploader'}
                                showUploadList={false}
                                action={`/api/assets/${asset.id}/upload_transcript`}
                                method='put'
                                beforeUpload={beforeUpload}
                                onChange={handleUpload}
                                headers={{'Authorization': `Bearer ${state.jwt}`, 'OrganizationId': currentOrg?.id}}
                            >
                                <Tooltip title={t('vtt-or-srt','.vtt or .srt files only')}>
                                    <Button icon={<UploadOutlined/>} type={'primary'} ghost loading={uploading}>{t('upload-transcript','Upload Transcript')}</Button>
                                </Tooltip>
                            </Upload>
                        </Space>
                    </div>
                )}
            </div>

            <div ref={ref} id={'transcript'}/>
        </VerticalSpace>
    )
}

const useSetPreview = (asset, player)=> {
    const {t} = useTranslation();

    const assetsDispatch = useAssetsDispatch();

    const [loading, setLoading] = useState()

    const clickSetPreview = ()=>{
        const seconds = player.currentTime()

        setLoading(true)
        api.put(`/api/assets/${asset.id}/set_preview_image_from_time`, {seconds}).then(res => {
            setLoading(false)
            message.success(t('message-preview-set','Preview Set!'))
            assetsDispatch({type:'updateAsset', asset: res.data})
        }).catch(e =>{
            setLoading(false)
            message.error(`Error! ${e}`)
        })
    }

    const confirmTitle = ()=>{
        player.pause()
        return t('confirm-set-video-preview', 'Set Video preview at current frame? ({{seconds}} seconds)', {seconds: player.currentTime().toFixed(2)})
    }

    return {
        confirmTitle,
        loading,
        clickSetPreview
    }
}

const useRemovePreview = (asset) => {
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();

    const [loading, setLoading] = useState()

    const clickRemovePreviewImage = ()=> {
        setLoading(true)
        const data = {asset:{remove_preview_image: true}}
        api.put(`/api/assets/${asset?.id}`, data).then(res => {
            setLoading(false)
            assetsDispatch({type:'updateAsset', asset: res.data})
            message.success(t('message-preview-image-removed','Preview Image removed.'))
        })
    }

    return {
        clickRemovePreviewImage,
        loading,
    }
}

const useUploadPreview = (asset) => {
    const {t} = useTranslation();
    const currentOrg = useCurrentOrg()
    const {state} = useContext(AppContext);
    const assetsDispatch = useAssetsDispatch();

    const [loading, setLoading] = useState()
    const [visible, setVisible] = useState()

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error(t('error-only-upload-jpg-png','You can only upload JPG/PNG file!'));
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error(t('error-image-must-be-smaller-than-10MB','Image must smaller than 10MB!'));
        }
        return isJpgOrPng && isLt2M;
    }

    const handlePreviewImageChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            message.success(t('message-preview-set','Preview Set!'))
            console.log(info)
            assetsDispatch({type:'updateAsset', asset: info.file.response})
            setVisible(false)
        }
    };

    const clickRemovePreviewImage = ()=> {
        const data = {asset:{remove_preview_image: true}}
        api.put(`/api/assets/${asset?.id}`, data).then(res => {
            assetsDispatch({type:'updateAsset', asset: res.data})
            message.success(t('message-preview-image-removed','Preview Image removed.'))
        })
    }

    const modal = (<>
        <Modal
            open={visible}
            onCancel={()=> setVisible(false)}
            footer={null}
            zIndex={2000}
            title={<>{t('update-preview-image','Update Preview Image')}</>}
        >
            <VerticalSpace>
                <Upload.Dragger
                    name="asset[preview_image]"
                    showUploadList={false}
                    action={`/api/assets/${asset.id}`}
                    method='put'
                    beforeUpload={beforeUpload}
                    onChange={handlePreviewImageChange}
                    headers={{'Authorization': `Bearer ${state.jwt}`, OrganizationId: currentOrg.id}}
                    style={{margin: '0 auto'}}
                >
                    {asset.preview_image_url ?
                        <Tooltip title={t('tooltip-click-to-change','Click to change')} zIndex={2001}>
                            <img src={asset.preview_image_url} alt="avatar"
                                 style={{
                                     width: '100%',
                                     backgroundColor: '#000'
                                 }}/>
                        </Tooltip> :
                        <div>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div className="ant-upload-text">{t('upload','Upload')}</div>
                        </div>
                    }
                </Upload.Dragger>

                {asset.preview_image_url &&
                    <Popconfirm
                        title={t('confirm-remove-preview-image', 'Remove preview image?')}
                        onConfirm={clickRemovePreviewImage}
                        zIndex={2001}
                    >
                        <Button icon={<DeleteOutlined/>} danger ghost>{t('button-remove','Remove')}</Button>
                    </Popconfirm>
                }
            </VerticalSpace>
        </Modal>
    </>);

    return {
        modal, setVisible, loading
    }
}

import 'videojs-trimmer';
import DownloadButton from "@/components/explore/DownloadButton";
import {useHistory} from "react-router-dom/cjs/react-router-dom";

const CutVideoButton = ({asset, player})=>{
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();
    const loadedAssetsDispatch = useLoadedAssetsDispatch()

    const [times, setTimes] = useState({start: null, end: null})
    const [enabled, setEnabled] = useState()
    const trimmerRef = useRef()

    function getValues() {
        const values = trimmerRef.current.getValues();
        const start = values.start.toFixed(2);
        const end = values.end.toFixed(2);

        // setTimes({start, end})

        return [start, end];
    }


    const onClick = ()=>{
        if(enabled) {
            return cancel()
        } else if(trimmerRef.current) {
            trimmerRef.current.show()
            return setEnabled(true)
        }

        setEnabled(true)

        player.play()
        player.pause()

        const trimmer = player.trimmer({hidden: false}) //
        trimmerRef.current = trimmer
        trimmer.showcontrolTime()
        // player.controlBar.progressControl.disable()

        function playBetween(side) {
            const [start, end] = getValues();

            const timeAt = side === "right" ? end - 1 : start;

            trimmer.playBetween(start, end, timeAt);
        }

        player.on("sliderchange", data => {
            const [start, end] = getValues();
            setTimes({start, end})
            playBetween(data.side);
        });
    }

    const cancel = ()=>{
        setEnabled(false)
        trimmerRef.current.hide()
    }

    const onChange = (which) =>{
        return (e)=> {
            console.log(e)
            const value = e.target.value
            const newTimes = which == 'start' ?  {...times, start: value} : {...times, end: value}

            trimmerRef.current.setValues(newTimes.start, newTimes.end)
            setTimes(newTimes)
        }
    }

    const setStart = ()=>{
        const [start, end] = getValues();
        const time = player.currentTime()
        setTimes({start: time, end})
        trimmerRef.current.setValues(time, end)
    }

    const setEnd = ()=> {
        const [start, end] = getValues();
        const time = player.currentTime()
        setTimes({start, end: time})
        trimmerRef.current.setValues(start, time)
    }

    const loop = ()=>{
        const [start, end] = getValues();
        trimmerRef.current.loop(start, end, start)
    }

    const {currentAssetGroup} = useAssetGroupState()

    const inStorageFolder = currentAssetGroup?.type == 'StorageFolder'

    const sliceStarted = ()=>{
        messageRef.current = message.open({
            key: `video-slicing-${asset.id}`,
            type: 'loading',
            content: <> Starting... </>,
            duration: 0
        })
    }

    const [creatingNew, setCreatingNew] = useState()
    const createNew = ()=>{
        sliceStarted()
        setCreatingNew(true)
        const [start, end] = getValues();
        api.post(`/api/assets/${asset.id}/slice_new_asset`, {start, end}).then(res => {
            setCreatingNew(false)
            message.success(t('message-new-asset-being-created','New Asset is being created now.'))
        })
    }

    const inLightbox = currentAssetGroup?.type == 'Lightbox' && currentAssetGroup?.contribution_id

    const [creatingNewInLightbox, setCreatingNewInLightbox] = useState()
    const createNewInLightbox = ()=>{
        sliceStarted()
        setCreatingNewInLightbox(true)

        const [start, end] = getValues();
        api.post(`/api/assets/${asset.id}/slice_new_asset`, {start, end, lightbox_id: currentAssetGroup?.asset_group_id}).then(res => {
            setCreatingNewInLightbox(false)
            message.success(t('message-new-asset-being-created-in-lightbox','New Asset is being created in Lightbox.'))
        })
    }

    const [savingVersion, setSavingVersion] = useState()
    const saveVersion = ()=>{
        sliceStarted()
        setSavingVersion(true)
        const [start, end] = getValues();
        api.post(`/api/assets/${asset.id}/slice_new_version`, {start, end}).then(res => {
            setSavingVersion(false)
            message.success(t('message-new-version-being-created','New Version is being created now.'))
        })
    }

    const [downloading, setDownloading] = useState()
    const download = () => {
        setDownloading(true)
        const [start, end] = getValues();

        api.post('/api/downloads',{download: {size: 'original', via: 'web', asset_ids: [asset.id], start_time: start, end_time: end}}).then(res => {
            if(res.data.token) {
                const url = `/api/downloads/${res.data.token}`
                const a = document.createElement("a")
                a.setAttribute('href', url)
                a.setAttribute('_target', 'blank')
                a.setAttribute('download', res.data.filename)
                a.style.display = 'none';
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a);
                message.success(t('message-your-download-will-start-shortly','Your download will start shortly.'))
            } else {
                message.error('There an error preparing your download.')
            }
            setDownloading(false)

        }).catch((err)=>{
            setDownloading(false)
            console.log(err)
        })
    }

    const consumer = useConsumer();
    const userSub = useRef();
    const messageRef = useRef()

    const sliceProgress = useRef()
    const thumbProgress = useRef()

    useEffect(()=> {
        if(!enabled) return;

        if(!userSub.current) {
            userSub.current = consumer.subscriptions.create({channel: "UserChannel"}, {
                received: (data) => {
                    console.log('UserChannel received', data)

                    switch(data.type) {
                        case 'videoSliceComplete':
                            messageRef.current && messageRef.current()

                            if(data.new_asset) {
                                return loadedAssetsDispatch({type:'reload'});
                            } else {
                                return assetsDispatch({type:'updateAsset', asset: {id: data.asset_id, new_data_version: true, ...data.attrs}});
                            }

                        case 'slicingProgress':
                        case 'videoThumbProgress':
                            if(data.thumb_progress) thumbProgress.current = data.thumb_progress
                            if(data.slice_progress) sliceProgress.current = data.slice_progress

                            messageRef.current = message.open({
                                key: `video-slicing-${asset.id}`,
                                type: 'loading',
                                content: (
                                        <>
                                            Slicing: <Progress percent={(sliceProgress.current || 0).toFixed()} status={'active'}/>
                                            Previews: <Progress percent={(thumbProgress.current || 0).toFixed()} status={'active'}/>
                                        </>
                                ),
                                duration: 0
                            })

                            return
                    }
                }
            });
        }

        return ()=> {
            userSub.current.unsubscribe()
        }

    }, [enabled])

    return (
        <>
            {enabled ? (
                <Popover
                    placement={'bottom'}
                    visible
                    getPopupContainer={trigger => trigger.parentElement}
                    content={
                        <Space direction={'vertical'} style={{width:'100%'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <AntInput type={'number'} id={'trimmer-start'} placeholder={'Start'} style={{flex:'auto'}} onChange={onChange('start')} value={times.start}/>
                                <ArrowRightOutlined style={{flex:0, margin:'0 .5em'}}/>
                                <AntInput type={'number'} id={'trimmer-end'} placeholder={'End'} style={{flex:'auto'}}  onChange={onChange('end')}  value={times.end}/>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <Tooltip title={t('tooltip-set-the-current-playhead-as-start','Set the current playhead position as the start.')}>
                                    <Button onClick={setStart} style={{flex:'auto'}} icon={<StepBackwardOutlined />}>{t('button-set-start','Set Start')}</Button>
                                </Tooltip>
                                &nbsp;

                                <Tooltip title={t('tooltip-set-current-playhead-as-end','Set the current playhead position as the end.')}>
                                    <Button onClick={setEnd} style={{flex:'auto'}} icon={<StepForwardOutlined />}>{t('button-set-end','Set End')}</Button>
                                </Tooltip>
                            </div>

                            <Button icon={<PlayCircleOutlined/>} onClick={loop} block>{t('button-play-selection','Play Selection')}</Button>

                            <Divider style={{margin:'.5em 0'}}/>
                            <Row>
                                <Space direction={'vertical'} style={{width:'100%'}}>
                                    <Button icon={<DownloadOutlined/>} block type={'primary'} ghost onClick={download} loading={downloading}>
                                        {t('button-download','Download')}&nbsp;{times.start && <small>({(times.end - times.start).toFixed(1)}s)</small>}
                                    </Button>

                                    {asset.manageable && (
                                        <Popconfirm title={t('confirm-save-new-version','Save New Version?')} onConfirm={saveVersion}>
                                            <Button icon={<ClockCircleOutlined/>} block type={'primary'} ghost loading={savingVersion}>{t('button-save-as-current-version','Save As Current Version')}</Button>
                                        </Popconfirm>
                                    )}

                                    {inStorageFolder && (
                                        <Popconfirm title={t('confirm-create-new-asset-in-storage-folder','Create New Asset in Storage Folder?')} onConfirm={createNew}>
                                            <Button icon={<PlusOutlined/>} block loading={creatingNew} type={'primary'} ghost>{t('button-create-new-asset-in-storage-folder','Create New Asset in Storage Folder')}</Button>
                                        </Popconfirm>
                                    )}

                                    {inLightbox && (
                                        <Popconfirm title={t('confirm-create-new-asset-in-lightbox','Create New Asset in Lightbox?')} onConfirm={createNewInLightbox}>
                                            <Button icon={<PlusOutlined/>} block loading={creatingNewInLightbox} type={'primary'} ghost>{t('button-create-new-asset-in-lightbox','Create New Asset in Lightbox')}</Button>
                                        </Popconfirm>
                                    )}

                                    <Button icon={<CloseOutlined/>} onClick={cancel} block danger ghost>{t('button-cancel','Cancel')}</Button>
                                </Space>
                            </Row>
                            <Row>
                            </Row>
                        </Space>
                    }
                >
                    <Button icon={<ScissorOutlined/>} disabled>{t('button-cutting-video','Cutting Video')}</Button>
                </Popover>
            ) : (
                <Button icon={<ScissorOutlined/>} onClick={onClick} loading={savingVersion || creatingNew}>{t('button-cut-video','Cut Video')}</Button>
            )}
        </>
    )
}

const libraries = ['places'];

const EditGpsButton = ({asset, text, onChange, style})=>{
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();

    const [modalVisible, setModalVisible] = useState()

    const [center, setCenter] = useState({ lat: parseFloat(asset?.latitude || 34), lng: parseFloat(asset?.longitude || -118) });

    const [map, setMap] = useState(null)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.googleApiKey,
        libraries: libraries
    })

    // document.getElementById("pac-input-gps").style.display = 'none';

    const onLoad = useCallback(function callback(map) {
        map.setCenter(center)

        setMap(map)

        const input = document.getElementById("pac-input-gps");
        input.style.display = 'auto'
        const searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        searchBox.addListener('places_changed', function() {
            const places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            const markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach(function(place) {
                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            map.fitBounds(bounds);
        });
    }, [])

    const searchRef = useRef()

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (<>
        <Button
            icon={<EditOutlined/>}
            ghost
            type={'primary'}
            size={'small'}
            onClick={()=> setModalVisible(true)}
            style={{...style}}
        >
            {text || t('button-edit','Edit')}
        </Button>
        <Formik
            initialValues={{latitude: asset?.latitude, longitude: asset?.longitude}}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                api.put(`/api/assets/${asset.id}`, {asset: values}).then(res => {
                    actions.setSubmitting(false)
                    assetsDispatch({type:'updateAsset', asset: res.data})
                    message.success(t('message-gps-updated','GPS Updated!'))
                    setModalVisible(false)
                })
            }}
        >
            {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {
                const handleBoundsChanged = () => {
                    const mapCenter = map.getCenter();
                    const lat = mapCenter.lat()
                    const lng = mapCenter.lng()
                    setCenter({lat, lng});
                    setFieldValue('latitude', lat)
                    setFieldValue('longitude', lng)

                    onChange && onChange({lat, lng})
                };

                const clear = ()=>{
                    setFieldValue('latitude', null)
                    setFieldValue('longitude', null)
                    submitForm()
                }

                const onGpsChange = coord => {
                    return e => {
                        center[coord] = parseFloat(e.target.value)
                        setCenter({...center})
                        map.setCenter({...center})
                    }
                }

                return (<>
                    <Modal
                        zIndex={1032}
                        open={modalVisible}
                        onCancel={()=> {
                            setModalVisible(false)
                            onChange && onChange()
                        }}
                        onOk={()=> setModalVisible(false)}
                        title={<><PushpinOutlined/> {t('editing-gps','Editing GPS')}</>}
                        footer={asset &&
                            <>
                                <Form layout={'horizontal'}>
                                    <FormItem name={'latitude'} label={t('latitude','Latitude')}>
                                        <Input name={'latitude'} onChange={onGpsChange('lat')}/>
                                    </FormItem>

                                    <FormItem name={'longitude'} label={t('longitude','Longitude')}>
                                        <Input name={'longitude'} onChange={onGpsChange('lng')}/>
                                    </FormItem>

                                    <Space>
                                        <Button
                                            onClick={submitForm}
                                            icon={<CheckOutlined/>}
                                            disabled={asset.latitude == center.lat && asset.longitude == center.lng}
                                            loading={isSubmitting}
                                            type={'primary'}
                                        >
                                            {t('button-save','Save')}
                                        </Button>

                                        <Button
                                            onClick={clear}
                                            icon={<CloseOutlined/>}
                                            loading={isSubmitting}
                                            danger
                                        >
                                            {t('button-remove-gps','Remove GPS')}
                                        </Button>
                                    </Space>
                                </Form>
                            </>
                        }
                        width={isMobile() ? '100%' : '50%'}
                        bodyStyle={{height:600}}
                    >
                        <AutoSizer>
                            {({height, width}) => (
                                <div style={{width, height, position:'relative'}}>
                                    {isLoaded && (
                                        <>
                                            <input
                                                id="pac-input-gps"
                                                className="controls"
                                                type="text"
                                                placeholder={t('search-map', 'Search Map...')}
                                                ref={searchRef}
                                                style={{bottom:-100, right:-100, position: 'fixed'}}
                                            />

                                            <GoogleMap
                                                onLoad={onLoad}
                                                zoom={asset?.latitude ? 13 : 5}
                                                defaultCenter={center}
                                                mapContainerStyle={{width, height}}
                                                onBoundsChanged={handleBoundsChanged}
                                                onUnmount={onUnmount}
                                            >
                                                <Marker position={center} />
                                            </GoogleMap>
                                        </>
                                    )}
                                </div>
                            )}
                        </AutoSizer>
                    </Modal>
                </>);
            }}

        </Formik>
    </>);
}

export {EditGpsButton}

const EditableEventTag = ({placeholder})=>{
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const {asset} = useContext(AssetModalContext)
    const eventTag = asset.event_tag

    const [visible, setVisible] = useState()
    const [saving, setSaving] = useState()

    return (
        <>
            {eventTag && !visible && (
                <EditableTag tag={eventTag} editable={asset.editable} bulk disableTypeSelect disableRemove/>
            )}

            {asset.editable && (
                <>
                    {!visible ? (
                        <Tooltip title={t('tooltip-change-event','Change Event')}>
                            <Button type={'link'} size={'small'} icon={<EditOutlined/>} onClick={()=> setVisible(true)}>{placeholder || ''}</Button>
                        </Tooltip>
                    ) : (
                        <Formik
                            initialValues={{}}
                            onSubmit={(values)=>{
                                setSaving(true)
                                api.put(`/api/assets/${asset.id}`, {asset: values}).then(res => {
                                    setSaving(false)
                                    setVisible(false)
                                    // Ensure `event_tag` is unset if not included in response:
                                    assetsDispatch({type:'updateAsset', asset: {...res.data, event_tag: res.data.event_tag}})
                                    editAssetDispatch({type:'increment'})
                                    message.success(t('message-event-updated','Event updated!'))
                                })
                            }}
                        >
                            {({submitForm})=> (
                                <VerticalSpace>
                                    <EventTagSelect style={{width:'100%'}} includeRemove={!!eventTag} placeholder={t('search-existing-events','Search existing events...')}/>

                                    <Space>
                                        <Button type={'primary'} onClick={submitForm} icon={<CheckOutlined/>} loading={saving}>{t('button-save','Save')}</Button>
                                        <Button icon={<CloseOutlined/>} onClick={()=> setVisible(false)} disabled={saving}>{t('button-cancel','Cancel')}</Button>
                                    </Space>
                                </VerticalSpace>
                            )}
                        </Formik>
                    )}

                </>
            )}
        </>
    )
}

const EditableTextArea = ({id, name, value, displayValue, onChange, editable, style, empty, tooltip, bottomButtons, Component='div'})=>{
    const {t} = useTranslation();
    const [editing, setEditing] = useState()

    useEffect(()=>{
        setEditing(false)
    }, [id])

    const onBlur = e =>{
        // HACK: safari does not send the ID of the buttons:
        if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return;

        console.log(e)
        if(['save-btn', 'cancel-btn'].indexOf(e.relatedTarget?.id) == -1) {
            alert(t('alert-please-save-changes', `Please save or cancel your changes to the {{name}}.`, {name}))
        }
    }

    const ref = useRef()

    const clickEdit = ()=> {
        setEditing(true)
        setTimeout(()=>{
            ref.current.focus()
        }, 100)
    }

    const trapEvent = e => {
        if(!e.keyCode || e.keyCode === 13 || e.keyCode === 8) {
            // e.preventDefault();
            e.stopPropagation();
        }
    }

    if(editing) {
        const rows = value?.split(/\n|\r/)?.length || 2;

        const Wrapper = bottomButtons ? VerticalSpace : Flex;

        return (
            <Formik
                initialValues={{field: value}}
                onSubmit={(values)=> {
                    onChange(values.field)
                    setEditing(false)
                }}
                onKeyDownCapture={trapEvent}
            >
                {({submitForm})=>(
                    <Wrapper gap={4}>
                        <Input.TextArea name={'field'} rows={rows * 2} style={{marginBottom:'1em'}} onBlur={onBlur} ref={ref} onClick={trapEvent} onKeyDown={trapEvent} onKeyUp={trapEvent}/>
                        <Space size={'small'}>
                            <Button icon={<SaveOutlined/>} onClick={submitForm} type={'primary'} id={'save-btn'}>{t('button-save','Save')}</Button>
                            <Button icon={<CloseOutlined/>} onClick={()=> setEditing(false)} id={'cancel-btn'}>{t('button-cancel','Cancel')}</Button>
                        </Space>
                    </Wrapper>
                )}
            </Formik>
        )
    } else {
        return (<>
            <Typography.Paragraph style={{...(style || {}), display:'inline-block'}}>
                {!value?.length ? (
                    empty || <></>
                ) : (
                    displayValue ? <Component style={{whiteSpace:'pre-wrap'}}>{displayValue}</Component> : value.split(/\n|\r/).map((s,i) => <div key={i}>{s}<br/></div>)
                )}
            </Typography.Paragraph>
            {editable && (
                <Tooltip title={tooltip || t('edit', 'Edit')}>
                    <Button type='text' size='small' style={{color: Colors.blue, margin: 0}} onClick={clickEdit} icon={<EditOutlined/>}/>
                </Tooltip>
            )}
        </>);
    }
}

export {EditableTextArea}

const BlockFlagSelect = ({asset}) => {
    const {t} = useTranslation();
    const assetsDispatch = useAssetsDispatch();
    const editAssetDispatch = useEditAssetsDispatch()

    const [value, setValue] = useState(asset.block_level)

    useEffect(()=>{
        setValue(asset.block_level)
    }, [asset?.id])

    const onChange = block_level =>{
        setValue(block_level)

        api.put(`/api/assets/${asset.id}`, {asset: {block_level}}).then(res => {
            assetsDispatch({type:'updateAsset', asset: res.data})
            editAssetDispatch({type:'increment'})
            message.success(t('message-restricted-access-updated',`Restricted Access updated.`))
        })
    }

    return (
        <AntSelect value={value} onChange={onChange} style={{width:'100%'}}>
            <AntSelect.Option value={0} disabled={asset.rights_package_block_level && asset.rights_package_block_level > 0}>{t('none','None')}</AntSelect.Option>
            <AntSelect.Option value={1} disabled={asset.rights_package_block_level && asset.rights_package_block_level > 1}><BlockDownload/> {t('block-download','Block Download')}</AntSelect.Option>
            <AntSelect.Option value={2} disabled={asset.rights_package_block_level && asset.rights_package_block_level > 1}><BlockView/> {t('block-view-and-download','Block View And Download')}</AntSelect.Option>
        </AntSelect>
    )
}

const AssetSettingsMenu = ()=>{
    const {t} = useTranslation();
    const {asset, handleCancel, player} = useContext(AssetModalContext)

    const {onDestroy} = useDeleteAsset(asset, handleCancel)

    const items = [{
        key: 'delete',
        id: 'send-file-to-trash',
        icon: <DeleteOutlined/>,
        danger: true,
        label: t('button-send-file-to-trash','Send File To Trash'),
        onClick: ()=> {
            Modal.confirm({
                onOk: onDestroy,
                content: t('confirm-send-file-to-trash', 'Send File To Trash?')
            });
        }
    }]

    const {
        confirmTitle,
        loading: setPreviewLoading,
        clickSetPreview
    } = useSetPreview(asset, player)

    const {
        modal: UploadPreviewModal,
        setVisible: openUploadPreview,
    } = useUploadPreview(asset)

    const {
        clickRemovePreviewImage,
    } = useRemovePreview(asset)

    if(asset.type !== 'Image') {
        const children = []

        if(asset.type === 'Video') {
            children.push({
                key: 'set-preview',
                icon: setPreviewLoading ? <LoadingOutlined/> : <CameraOutlined/>,
                disabled: setPreviewLoading,
                label: (
                    <Tooltip placement={'left'} title={t('tooltip-set-to-current-playhead','Set to current playhead.')}>
                        {t('button-choose-current-frame','Choose Current Frame')}
                    </Tooltip>
                ),
                onClick: ()=> {
                    Modal.confirm({
                        onOk: clickSetPreview,
                        content: confirmTitle()
                    });
                }
            })
        }

        children.push({
            key: 'upload-preview',
            icon: <UploadOutlined/>,
            onClick: openUploadPreview,
            label: t('button-upload-an-image','Upload an Image')
        })

        if(asset.preview_image_url) {
            children.push({
                key: 'remove-preview',
                icon: <CloseCircleOutlined/>,
                label: t('button-remove-custom-thumbnail','Remove Custom Thumbnail'),
                onClick: ()=>{
                    Modal.confirm({
                        onOk: clickRemovePreviewImage,
                        content: t('confirm-remove-preview-image', 'Remove preview image?')
                    });
                }
            })
        }

        items.push({
            type: 'group',
            label: <><FileImageOutlined/> {t('custom-thumbnail','Custom Thumbnail')}</>,
            children
        })
    }

    return (
        <>
            <Dropdown
                trigger={['click', 'hover']}
                menu={{items}}
                getPopupContainer={e => e.parentElement}
                placement={'bottomRight'}
            >
                <Button icon={<SettingOutlined/>} id={'asset-setting-menu-btn'}>
                    {isBrowser() && <>{t('settings','Settings')} <DownOutlined/> </>}
                </Button>
            </Dropdown>

            {UploadPreviewModal}
        </>
    )
}

const AssetFaceTaggings = ()=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    const [loading, setLoading] = useState(true)

    const [faceTaggings, setFaceTaggings] = useState([])

    useEffect(() => {
        if(!asset) return

        api(`/api/assets/${asset.id}/face_taggings`).then(res => {
            setLoading(false)
            setFaceTaggings(res.data)
        })
    }, [asset?.id, asset?.rekognition_faces_retrieved_at, asset?.faces_searched_at]);

    const faces = asset.rekognition_faces?.face_records

    const assetsDispatch = useAssetsDispatch();

    const [searchingFaces, setSearchingFaces] = useState()
    const searchFaces = ()=>{
        setSearchingFaces(true)
        api.post(`/api/assets/${asset.id}/search_faces`).then(res => {
            message.success('Done!')
            setSearchingFaces(false)
            assetsDispatch({type:'updateAsset', asset: res.data})
        })
    }

    const [ignoringUnidentifiedFaces, setIgnoringUnidentifiedFaces] = useState()
    const ignoreUnidentifiedFaces = ()=>{
        setIgnoringUnidentifiedFaces(true)
        api.post(`/api/assets/${asset.id}/ignore_unidentified_faces`).then(res => {
            message.success('Done!')
            setIgnoringUnidentifiedFaces(false)
            assetsDispatch({type:'updateAsset', asset: res.data})
        })
    }

    if(['pending', 'pending_request', 'requested'].indexOf(asset.face_indexing_state) !== -1) {
        return (
            <><LoadingOutlined/> {t('processing', 'Processing')}...</>
        )
    }

    return (
        <VerticalSpace size={'large'}>
            {asset.taggable && (
                <Space>
                    <Button onClick={searchFaces} loading={searchingFaces} icon={<SearchOutlined/>} ghost type={'primary'}>{t('search-faces', 'Search Faces')}</Button>

                    {asset.rekognition_faces_retrieved_at && (
                        <Popconfirm title={t('ignore-all-unidentified-faces-confirm', 'Ignore all unidentified faces?')} onConfirm={ignoreUnidentifiedFaces} >
                            <Button loading={ignoringUnidentifiedFaces} icon={<CloseCircleOutlined/>}>{t('ignore-all-unidentified-faces', 'Ignore All Unidentified Faces')}</Button>
                        </Popconfirm>
                    )}
                </Space>
            )}

            {asset.face_indexing_state === 'completed' && (
                <List
                    loading={loading || !faces}
                    bordered
                    dataSource={faces}
                    renderItem={faceItem => <FaceItem faceRecord={faceItem} faceTaggings={faceTaggings} setFaceTaggings={setFaceTaggings}/>}
                />
            )}
        </VerticalSpace>
    )
}

const FaceItem = ({faceRecord, faceTaggings, setFaceTaggings})=>{
    const {t} = useTranslation();
    const {asset} = useContext(AssetModalContext)

    const face = faceRecord.face

    const [value, setValue] = useState()
    useEffect(() => {
        setValue()
    }, [face?.face_id]);

    const [data, setData] = useState()
    const [fetching, setFetching] = useState()

    const handleSearch = _.debounce(val => {
        if (val) {
            setValue(val)
            setFetching(true)

            api(`/api/tags?q=${val}&sub_type=person&list_not=blocked`).then(res => {
                setFetching(false)
                setData(res.data);
            })
        } else {
            setData()
        }
    }, 250);

    const handleChange = tag_id => {
        if(!tag_id) return;

        post({tag_id})
    };

    const getOption = tag => <Select.Option key={tag.tag_id}>{tag.name}</Select.Option>

    let options;
    if(data) options = data.map(getOption)
    else {
        const existingFaceTagIds = faceTaggings?.map(ft => ft.tag?.id) || []
        options = _.filter(asset.tags || [], t => t.sub_type === 'person' && existingFaceTagIds.indexOf(t.tag_id) === -1).map(getOption)
    }

    const [submitting, setSubmitting] = useState()

    const assetsDispatch = useAssetsDispatch();

    const post = ({name, tag_id})=> {
        setSubmitting(true)
        api.post(`/api/assets/${asset.id}/tag_face`, {name, tag_id, face_id: face.face_id}).then(res => {
            setSubmitting(false)
            setFaceTaggings([...faceTaggings, ...res.data])
            message.success(t('face-tagged', 'Face Tagged'))
        })
    }

    const onKeyUp = e => {
        if(e.keyCode === 13) submit()
    }

    const submit = ()=> post({name: value})

    const faceTagging = _.find(faceTaggings || [], {rekognition_face_id: face.face_id})

    const [removing, setRemoving] = useState()
    const remove = ()=> {
        setRemoving(true)

        api.delete(`/api/taggings/${faceTagging.id}`).then(res => {
            setRemoving(false)
            setFaceTaggings(_.without(faceTaggings, faceTagging))
            // notification.success('Face Tagging Removed')
        })
    }

    const [associating, setAssociating] = useState()
    const associate = () => {
        setAssociating(true)

        api.post(`/api/taggings/${faceTagging.id}/associate_with_face`).then(res => {
            setAssociating(false)
            setFaceTaggings([..._.without(faceTaggings, faceTagging), ...res.data])
        })
    }

    const disassociate = () => {
        setAssociating(true)

        api.post(`/api/taggings/${faceTagging.id}/disassociate_with_face`).then(res => {
            setAssociating(false)
            setFaceTaggings([..._.without(faceTaggings, faceTagging), ...res.data])
        })
    }

    const [settingMainFace, setSettingMainFace] = useState()
    const setMainFace = ()=>{
        setSettingMainFace(true)
        api.post(`/api/taggings/${faceTagging.id}/set_main_face`).then(res => {
            setSettingMainFace(false)
            setFaceTaggings([..._.without(faceTaggings, faceTagging), ...res.data])
        })
    }

    const [ignoring, setIgnoring] = useState()

    const ignored = asset.ignored_face_ids && asset.ignored_face_ids.indexOf(face.face_id) !== -1;

    const ignore = () => {
        setIgnoring(true)

        api.post(`/api/assets/${asset.id}/ignore_face_toggle`, {face_id: face.face_id}).then(res => {
            setIgnoring(false)
            assetsDispatch({type:'updateAsset', asset: res.data})
            message.success(ignored ? t('face-unignored', 'Face Un-Ignored') : t('face-ignored', 'Face Ignored'))
        })
    }

    const [blocking, setBlocking] = useState()
    const block = () => {
        setBlocking(true)
        api.post(`/api/assets/${asset.id}/block_face`, {face_id: face.face_id}).then(res => {
            setBlocking(false)
            setFaceTaggings([...faceTaggings, ...res.data])
            message.success(t('face-blocked', 'Face Blocked'))
        })
    }

    const imgWidth = 1200;
    const imgHeight = imgWidth / asset.aspect;

    const thumbnailSize = 100;

    return (
        <List.Item
            key={face.face_id}
        >
            <List.Item.Meta
                description={
                    <>
                        {faceTagging ? (
                            <VerticalSpace size={'large'}>
                                <Space>
                                    <EditableTag tag={faceTagging.tag} bulk editable={asset.taggable} large disableRemove disableTypeSelect/>

                                    {asset.taggable &&(
                                        <Popconfirm
                                            title={t('remove-face-tagging', 'Remove Face Tagging?')}
                                            onConfirm={remove/**/}
                                        >
                                            <Button icon={<DeleteOutlined/>} loading={removing} size={'small'}/>
                                        </Popconfirm>
                                    )}
                                </Space>

                                {asset.taggable && (
                                    <Space size={'large'}>
                                        <div>
                                            {!faceTagging.face_associated ? (
                                                <Button type={'primary'} icon={<CheckOutlined/>} ghost loading={associating} onClick={associate}>{t('train-face', 'Train Face')}</Button>
                                            ) : (
                                                <Button icon={<CloseCircleOutlined/>} loading={associating} onClick={disassociate}>{t('remove-face-training', 'Remove Face Training')}</Button>
                                            )}
                                            <HelpPopover code={'train-face-button'}/>
                                        </div>

                                        <div>
                                            {faceTagging.is_main_face ? (
                                                <Tag color={'green'}><CheckOutlined/> {t('main-face', 'Main Face')}</Tag>
                                            ) : (
                                                <Popconfirm
                                                    title={t('confirm-set-main-face', 'Set as main face?')}
                                                    onConfirm={setMainFace}
                                                >
                                                    <Button type={'primary'} icon={<CheckOutlined/>} loading={settingMainFace}>{t('set-tag-main-face', 'Set Main Face')}</Button>
                                                </Popconfirm>
                                            )}
                                            <HelpPopover code={'set-main-face-button'}/>
                                        </div>

                                    </Space>
                                )}
                            </VerticalSpace>
                        ) : (
                            <>
                                {asset.taggable ? (
                                    <>
                                        {ignored ? (
                                            <VerticalSpace size={'large'}>
                                                <Typography.Text type={'secondary'}><em>{t('ignored', 'Ignored')}</em></Typography.Text>

                                                <div>
                                                    <Button icon={<CheckOutlined/>} onClick={ignore} loading={ignoring}>{t('unignore-face', 'Un-Ignore Face')}</Button>

                                                    <HelpPopover code={'ignore-face-btn'}/>
                                                </div>
                                            </VerticalSpace>

                                        ) : (
                                            <VerticalSpace size={'large'}>
                                                <Space>
                                                    <Select
                                                        disabled={submitting}
                                                        loading={submitting}
                                                        showSearch
                                                        allowClear
                                                        value={value}
                                                        style={{width:250}}
                                                        notFoundContent={fetching ? <LoadingOutlined /> : null}
                                                        placeholder={t('add-person', 'Add Person...')}
                                                        defaultActiveFirstOption={false}
                                                        filterOption={false}
                                                        onSearch={handleSearch}
                                                        onChange={handleChange}
                                                        onKeyUp={onKeyUp}
                                                        className={'add-person-select'}
                                                    >
                                                        {options}
                                                    </Select>

                                                    <Button icon={<CheckOutlined/>} disabled={!value?.length} onClick={submit} type={'primary'} loading={submitting}/>
                                                </Space>

                                                <Space size={'large'}>
                                                    <div>
                                                        <Button icon={<CloseCircleOutlined/>} onClick={ignore} loading={ignoring}>{t('ignore-face', 'Ignore Face')}</Button>

                                                        <HelpPopover code={'ignore-face-btn'}/>
                                                    </div>

                                                    <div>
                                                        <Popconfirm onConfirm={block} title={t('confirm-block-face', 'Block Face?')}>
                                                            <Button icon={<CloseCircleOutlined/>} danger ghost loading={blocking}>{t('block-face', 'Block Face')}</Button>
                                                        </Popconfirm>

                                                        <HelpPopover code={'block-face-btn'}/>
                                                    </div>
                                                </Space>
                                            </VerticalSpace>
                                        )}
                                    </>
                                ) : (
                                    <em>{t('unidentified','Unidentified')}</em>
                                )}
                            </>
                        )}
                    </>
                }
                avatar={
                    <FaceAvatar
                        ignored={ignored || faceTagging?.tag?.list === 'blocked'}
                        src={asset.permalink_url || asset.small_url}
                        faceId={face.face_id}
                        imgHeight={imgHeight}
                        imgWidth={imgWidth}
                        thumbnailSize={thumbnailSize}
                        boundingBox={face.bounding_box}
                    />
                }
            />
        </List.Item>
    )
}

const FaceAvatar = ({boundingBox, faceId, src, imgWidth, imgHeight, thumbnailSize, onClick, ignored})=> {
    const getFacePositionStyle = (boundingBox, imgWidth, imgHeight) => {
        // Actual bounding box values on original image
        const leftActual = boundingBox.left * imgWidth;
        const topActual = boundingBox.top * imgHeight;
        const widthActual = boundingBox.width * imgWidth;
        const heightActual = boundingBox.height * imgHeight;

        // Calculate the scale at which the image should be shown to make the face fit in 50x50
        const scale = Math.max(widthActual, heightActual) / thumbnailSize;

        const adjustedImgWidth = imgWidth / scale;
        const adjustedImgHeight = imgHeight / scale;

        // Translate bounding box center to our thumbnail
        const centerX = leftActual / scale + (widthActual / scale / 2);
        const centerY = topActual / scale + (heightActual / scale / 2);

        // Adjust for 50x50 thumbnail size, ensuring the face center aligns with the thumbnail center
        const adjustedLeft = centerX - thumbnailSize / 2;
        const adjustedTop = centerY - thumbnailSize / 2;

        return {
            objectPosition: `-${adjustedLeft}px -${adjustedTop}px`,
            width: adjustedImgWidth,
            height: adjustedImgHeight,
            objectFit: 'cover'
        };
    };

    const style = {}
    if(onClick) style.cursor = 'pointer'
    if(ignored) style.opacity = '.5'

    return (
        <Avatar
            size={thumbnailSize}
            onClick={onClick}
            style={style}
            icon={
                <div style={{width: thumbnailSize, height: thumbnailSize, overflow: 'hidden'}}>
                    <img
                        src={src}
                        alt={`Detected Face ${faceId}`}
                        style={getFacePositionStyle(boundingBox, imgWidth, imgHeight)}
                    />
                </div>
            }
        />
    )
}

export {FaceAvatar}