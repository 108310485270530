import React, {useContext, useEffect} from "react";
import {BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import {CompatRouter, useNavigate } from "react-router-dom-v5-compat";

import NakedLayout from '../components/NakedLayout';

import HomeLayout from '../components/HomeLayout';
import ManageLayout from '../components/manage/ManageLayout';
import CollaborateLayout from '../components/collaborate/CollaborateLayout';
import ExploreLayout from '../components/explore/ExploreLayout';


import Login, {EmailLogin} from "../components/session/Login";
import SignUp from "../components/registration/SignUp";
import Settings from "../components/registration/Settings";

import NewOrganization from "../components/registration/NewOrganization";
import InviteSignUp from "../components/registration/InviteSignUp";

import Home, {NoMatch, PublicLibrary, PublicLibraryHome} from "../components/manage/Home";
import Status from "../components/manage/Status";
import Manage from "../components/manage/Manage";
import Memberships from "../components/manage/Memberships";
import Groups from "../components/manage/Groups";
import Workflows from "../components/manage/Workflows";

import CollaborateHome from "../components/collaborate/CollaborateHome"
import ExploreHome from "../components/explore/ExploreHome";

import Uploads from "../components/uploads/Uploads";
import Taxonomy from "../components/manage/Taxonomy";
import Rights from "../components/manage/Rights";
import TagSuggesters from "../components/manage/TagSuggesters";
import OrgSettings from "../components/manage/OrgSettings"
import Contributions from "../components/manage/Contributions"

import {AssetsProvider} from "../contexts/AssetsContext";
import {LoadedAssetsProvider} from "../contexts/LoadedAssetsContext";
import {UploadsProvider} from "../contexts/UploadsContext";
import {AssetGroupProvider} from "../contexts/AssetGroupContext";
import {EditAssetsProvider} from "../contexts/EditAssetsContext";
import Collections from "../components/manage/Collections";
import {AggsProvider} from "../contexts/AggsContext";
import {AssetsPageProvider} from "../contexts/AssetsPageContext";
import ForgotPassword from "../components/session/ForgotPassword";
import UpdatePassword from "../components/session/UpdatePassword";
import ConfirmAccount from "../components/session/ConfirmAccount";
import Account from "../components/registration/Account";
import Organizations from "../components/registration/Organizations";
import {BulkJobsProvider} from "../contexts/BulkJobsContext";
import {LinksProvider} from "../contexts/LinksContext";
import MembershipRequest from "../components/registration/MembershipRequest";
import {SelectedAssetsProvider} from "../contexts/SelectedAssetsContext";
import {SelectedAggsProvider} from "../contexts/SelectedAggsContext";
import {UploadLink, QuickUploadLink} from "../components/uploads/UploadLink";
import Reports from "../components/manage/Reports";
import {CurrentAssetsProvider} from "../contexts/CurrentAssetContext";
import MetaImports from "../components/manage/MetaImports";
import CustomMetaFields from "../components/manage/CustomMetaFields";
import Billing from "../components/manage/Billing";
import PersonalAccessTokens from "../components/registration/PersonalAccessTokens";
import Security from "../components/registration/Security";
import Integrations from "../components/manage/Integrations";
import {ViewSettingsProvider} from "../contexts/ViewSettingsContext";
import {AppContext} from "../contexts/AppContext";
import queryString from "query-string";
import {setAfterLogin} from "../components/helpers/useAfterLogin";
import {SessionContext} from "../contexts/SessionContext";
import AccessRequests from "../components/manage/AccessRequests";
import {useTranslation} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons";

import {message} from 'antd';
import Faces from "@/components/manage/Faces";
import NewTrialSignup, {CompleteTrialSignup, TrialSignupEmailVerified} from "@/components/registration/NewTrialSignup";
import AssetActionsProvider from "~/contexts/AssetActionsContext";
import QuickUpload from "~/components/explore/QuickUpload";

const PrefixedRoute = ({path, children, ...rest})=> {
    const prefixedPath = `:org/${path}`

    return (
        <Route path={prefixedPath} {...rest}>
           {children}
        </Route>
    )
}

const NakedRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <NakedLayout>
            <Component {...props} />
        </NakedLayout>
    )} />
);

const HomeRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <HomeLayout>
            <Component {...props} />
        </HomeLayout>
    )} />
);

const ManageRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <ManageLayout>
            <Component {...props} />
        </ManageLayout>
    )} />
);

const CollaborateRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <CollaborateLayout>
            <Component {...props} />
        </CollaborateLayout>
    )} />
);

const ExploreRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <PrefixedRoute {...rest} render={props => (
        <ExploreLayout>
            <Component {...props} />
        </ExploreLayout>
    )} />
);

const getPath = (path, optional=false) =>
    window.customDomainOrganizationSlug ? `/${path}` : `/:org${optional ? '?' : ''}/${path}`

const Root = () => (
    <AggsProvider>
        <AssetGroupProvider>
            <EditAssetsProvider>
                <UploadsProvider>
                    <BulkJobsProvider>
                        <LinksProvider>
                            <AssetsProvider>
                                <LoadedAssetsProvider>
                                    <CurrentAssetsProvider>
                                        <AssetsPageProvider>
                                            <SelectedAssetsProvider>
                                                <SelectedAggsProvider>
                                                    <AssetActionsProvider>
                                                        <Router>
                                                            <CompatRouter>
                                                                <ViewSettingsProvider>
                                                                    <Switch>
                                                                        {/*{!window.customDomainOrganizationSlug && (*/}
                                                                        {/*    <>*/}
                                                                        {/*        <Route exact path="/explore"><Redirect to="/" /></Route>*/}
                                                                        {/*        <Route exact path="/manage"><Redirect to="/" /></Route>*/}
                                                                        {/*        <Route exact path="/collaborate"><Redirect to="/" /></Route>*/}
                                                                        {/*    </>*/}
                                                                        {/*)}*/}

                                                                        <HomeRoute path={getPath('login')} exact component={Login} />
                                                                        <HomeRoute path={'/login'} exact component={Login} />
                                                                        <HomeRoute path={getPath('email-login', true)} exact component={EmailLogin} />

                                                                        <HomeRoute path={getPath('sign-up', true)} exact component={SignUp} />
                                                                        <HomeRoute path={getPath('trial-sign-up', true)} exact component={NewTrialSignup} />
                                                                        <NakedRoute path={'/trial-email-verified'} exact component={TrialSignupEmailVerified} />

                                                                        <HomeRoute path={getPath('request-access', true)} exact component={MembershipRequest} />
                                                                        <HomeRoute path={getPath('invites/:token')} exact component={InviteSignUp} />
                                                                        <NakedRoute path={getPath('trial/:token')} exact component={CompleteTrialSignup} />

                                                                        <HomeRoute path="/organizations/new" exact component={NewOrganization} />

                                                                        <NakedRoute path="/forgot-password" exact component={ForgotPassword} />
                                                                        <NakedRoute path="/update-password" exact component={UpdatePassword} />
                                                                        <NakedRoute path="/confirm-account" exact component={ConfirmAccount} />

                                                                        <HomeRoute path="/organizations" exact component={Organizations} />
                                                                        <HomeRoute path={getPath('settings')} exact component={Settings} />
                                                                        <HomeRoute path="/account" exact component={Account} />
                                                                        <HomeRoute path="/account/personal-access-tokens" exact component={PersonalAccessTokens} />
                                                                        <HomeRoute path="/account/security" exact component={Security} />

                                                                        {/* Main Asset Browsing Route: */}
                                                                        <ExploreRoute path={getPath('explore/contributions/:contributionId')} component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('explore/my-uploads/contributions/:myContributionId')} component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('explore/my-uploads/:uploadType?')} component={ExploreHome} />

                                                                        <ExploreRoute path={getPath('explore/tags/:tagId')} component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('explore/:type?/:slug?/:folder?')} component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('share-links/:shareCode/:folder?')} component={ExploreHome} />

                                                                        <ManageRoute path={getPath('manage')} exact component={Manage} />
                                                                        <ManageRoute path={getPath('manage/members/:tab?')} exact component={Memberships} />

                                                                        <ManageRoute path={getPath('manage/status')} exact component={Status} />
                                                                        <ManageRoute path={getPath('manage/groups')} exact component={Groups} />
                                                                        <ManageRoute path={getPath('manage/taxonomy')} exact component={Taxonomy} />
                                                                        <ManageRoute path={getPath('manage/faces')} exact component={Faces} />
                                                                        <ManageRoute path={getPath('manage/tag-suggesters')} exact component={TagSuggesters} />
                                                                        <ManageRoute path={getPath('manage/custom-meta-fields')} exact component={CustomMetaFields} />
                                                                        <ManageRoute path={getPath('manage/meta-imports')} exact component={MetaImports} />
                                                                        <ManageRoute path={getPath('manage/rights')} exact component={Rights} />
                                                                        <ManageRoute path={getPath('manage/uploads')} exact component={Contributions} />
                                                                        <ManageRoute path={getPath('manage/access-requests/:tab?')} exact component={AccessRequests} />
                                                                        <ManageRoute path={getPath('manage/site-settings')} exact component={OrgSettings} />
                                                                        <ManageRoute path={getPath('manage/integrations')} exact component={Integrations} />
                                                                        <ManageRoute path={getPath('manage/collections')} exact component={Collections} />
                                                                        <ManageRoute path={getPath('manage/reports/:report?')} exact component={Reports} />
                                                                        <ManageRoute path={getPath('manage/billing')} exact component={Billing} />

                                                                        <CollaborateRoute path={getPath('collaborate')} exact component={CollaborateHome} />
                                                                        <CollaborateRoute path={getPath('collaborate/workflows')} exact component={Workflows} />

                                                                        <ExploreRoute path={getPath('upload/:contributionSlug/:uploadGuid')} exact component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('upload/:uploadGuid')} exact component={ExploreHome} />
                                                                        <ExploreRoute path={getPath('contribute/:slug')} exact component={UploadLink} />
                                                                        <ExploreRoute path={getPath('uploads')} exact component={Uploads} />

                                                                        <NakedRoute path={getPath('quick-upload/:slug')} exact component={QuickUploadLink} />
                                                                        <NakedRoute path={getPath('quick-upload/:contributionSlug/:uploadGuid')} exact component={QuickUpload} />

                                                                        <ExploreRoute path={getPath('access-requests/:accessRequestId/:guestCode?')} exact component={ExploreHome} />

                                                                        <Route path={'/oauth/jwt'} exact component={OAuthLogin}/>
                                                                        <Route path={"/lightroom/refresh"} exact component={LightroomRefresh} />
                                                                        <Route path={'/troubleshoot'} exact component={TroubleShoot}/>

                                                                        <HomeRoute path="/:org?" exact component={Home} />
                                                                        <HomeRoute path={getPath('public')} component={PublicLibraryHome} />

                                                                        <Route path="*">
                                                                            <HomeLayout>
                                                                                <NoMatch/>
                                                                            </HomeLayout>
                                                                        </Route>
                                                                    </Switch>
                                                                </ViewSettingsProvider>
                                                            </CompatRouter>
                                                        </Router>
                                                    </AssetActionsProvider>
                                                </SelectedAggsProvider>
                                            </SelectedAssetsProvider>
                                        </AssetsPageProvider>
                                    </CurrentAssetsProvider>
                                </LoadedAssetsProvider>
                            </AssetsProvider>
                        </LinksProvider>
                    </BulkJobsProvider>
                </UploadsProvider>
            </EditAssetsProvider>
        </AssetGroupProvider>
    </AggsProvider>
);

export default Root

const OAuthLogin = ()=>{
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const {dispatch: sessionDispatch} = useContext(SessionContext);

    const {prompt} = queryString.parse(location.search)

    const navigate = useNavigate()

    useEffect(()=>{
        if(prompt === 'login') {
            sessionDispatch({type:'logout'});
            dispatch({type:'logout'});

            setAfterLogin('/oauth/jwt')
            setTimeout(()=>{
                navigate('/login');
            }, 2000)

        } else {
            if(state.jwt) {
                window.location = `/oauth/session?jwt=${state.jwt}`;
            } else {
                window.location = '/login';
            }
        }
    }, [prompt])

    return <>{t('redirecting','Redirecting')}...</>
}

const LightroomRefresh = ()=>{
    const {project_id} = queryString.parse(location.search)

    const navigate = useNavigate()

    useEffect(()=>{
        if(!project_id?.length) return;

        api(`/api/integrations/refresh_lightroom_project?project_id=${project_id}`).then(res => {
            if(res.data.error) {
                message.error(`Error: ${res.data.error}`)

                navigate('/');
            } else {
                message.success(`Syncing Lightroom Project Now...`)
                navigate(res.data.path + '?refreshing=true');
            }
        }).catch(()=>{
            message.error(`Error refreshing project...`)
            navigate('/');
        })
    }, [project_id])

    return (
        <p style={{margin:'2em'}}><LoadingOutlined/> Refreshing Lightroom Project: {project_id}</p>
    )
}

const TroubleShoot = ()=>{
    const {t} = useTranslation();

    useEffect(() => {
        sessionStorage.clear()
        localStorage.clear()
    }, []);

    setTimeout(()=>{
        window.location = '/'
    }, 500)

    return <>{t('site-data-cleared','Site Data cleared, reloading...')}</>
}

export {TroubleShoot}